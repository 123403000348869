import React from 'react';
import { useAboutUsDispatcher, useAboutUsContext, getAboutUs } from '../action/AboutUsAction';
import AboutUsComponent from './AboutUsComponent';

interface IAboutUsContainerProps{

}

interface IAboutUsContainerState{

}

const initialState = {
    
} as IAboutUsContainerState

const AboutUsContainer:React.FC<IAboutUsContainerProps>=(props) => {

    const aboutUsDispatcher = useAboutUsDispatcher();
    const aboutUsContext = useAboutUsContext();
    const {aboutUs }=aboutUsContext;
    console.log(3,aboutUs)

    const [AboutUsContainerState,
        setAboutUstContainerState] = React.useState<IAboutUsContainerState>(initialState);

    React.useEffect(() => {
        (async () => {
         await getAboutUs(aboutUsDispatcher)
        })();
    }, [])

    return (
        <div>
            <AboutUsComponent/>
        </div>
    );
};

export default AboutUsContainer;