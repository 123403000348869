import LocalStoreManager from './LocalStoreManager';
import { LoginResponse } from '../_models/LoginResponse';
import ConfigurationService from './ConfigurationService';
import { jwtHelper } from './JwtHelper';
import { AccessToken } from '../_models/AccessToken';
import { PermissionValues } from '../_models/PermissionValues';
import { User } from '../_models/User';
import { DBkeys } from './DBkeys';
import EndPointFactory from './EndPointFactory';
// let acessToken="";

console.log("Inside AuthService.ts");
class AuthService {
     private previousIsLoggedInCheck = false;
     async login(userName: string, password: string, rememberMe: boolean) 
     {
        console.log("AuthService.ts->AuthService class->login method");
        if (this.isLoggedIn) 
        {
            
            this.logout();
        }

        try 
        {
            var response = await EndPointFactory.getLoginEndpoint<LoginResponse>(userName, password);
            console.log(34,response)
            return this.processLoginResponse(response.data, rememberMe);
            
            // acessToken=response.data.access_token
        }
        catch (e) 
        {
            
            throw e;
            
        }
    }

    private processLoginResponse(response: LoginResponse, rememberMe: boolean) 
    {
        const accessToken = response.access_token;
        if (accessToken == null) 
        {
            throw new Error('Received accessToken was empty');

        }
        const refreshToken = response.refresh_token || this.refreshToken;
        const expiresIn = response.expires_in;
        const tokenExpiryDate = new Date();

        tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);

        const accessTokenExpiry = tokenExpiryDate;
        const decodedAccessToken = jwtHelper.decodeToken(response.access_token) as AccessToken;
        const permissions: PermissionValues[] = Array.isArray(decodedAccessToken.permission) ? decodedAccessToken.permission : [decodedAccessToken.permission];

        if (!this.isLoggedIn) 
        {
            ConfigurationService.import(decodedAccessToken.configuration);
        }

        const user = new User(
            decodedAccessToken.sub,
            decodedAccessToken.name,
            decodedAccessToken.fullname,
            decodedAccessToken.email,
            decodedAccessToken.jobtitle,
            decodedAccessToken.phone_number,
            Array.isArray(decodedAccessToken.role) ? decodedAccessToken.role : [decodedAccessToken.role]
            );
        user.isEnabled = true;
        this.saveUserDetails(user, permissions, accessToken, refreshToken, accessTokenExpiry, rememberMe);
        this.reevaluateLoginStatus(user);
        return user;
    }


    private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean) {
        if (rememberMe) {
            LocalStoreManager.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
            LocalStoreManager.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
            LocalStoreManager.savePermanentData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
            LocalStoreManager.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
            LocalStoreManager.savePermanentData(user, DBkeys.CURRENT_USER);
        } else {
            LocalStoreManager.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
            LocalStoreManager.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
            LocalStoreManager.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
            LocalStoreManager.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
            LocalStoreManager.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
        }

        LocalStoreManager.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    }

    get accessTokenExpiryDate(): Date | null 
    {
        this.reevaluateLoginStatus(null);
        return LocalStoreManager.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
    }

    get isSessionExpired(): boolean 
    {
        if (this.accessTokenExpiryDate == null) 
        {
            return true;
        }
        return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
    }

    get accessToken(): string | null
    {

        this.reevaluateLoginStatus(null);
        return localStorage.getItem(DBkeys.ACCESS_TOKEN);
        
    }

    get refreshToken(): string 
    {

        this.reevaluateLoginStatus(null);
        return localStorage.getData(DBkeys.REFRESH_TOKEN);
    }

    get isLoggedIn(): boolean 
    {
        return this.currentUser != null;
    }

    get rememberMe(): boolean 
    {
        const rememberMe = LocalStoreManager.getDataObject<boolean>(DBkeys.REMEMBER_ME);
        return rememberMe === true;
    }

    logout(): void 
    {
        LocalStoreManager.deleteData(DBkeys.ACCESS_TOKEN);
        LocalStoreManager.deleteData(DBkeys.REFRESH_TOKEN);
        LocalStoreManager.deleteData(DBkeys.TOKEN_EXPIRES_IN);
        LocalStoreManager.deleteData(DBkeys.USER_PERMISSIONS);
        LocalStoreManager.deleteData(DBkeys.CURRENT_USER);

        ConfigurationService.clearLocalChanges();

        this.reevaluateLoginStatus(null);
    }

    private reevaluateLoginStatus(currentUser: User | null) 
    {
        const user = currentUser || LocalStoreManager.getDataObject<User>(DBkeys.CURRENT_USER);
        const isLoggedIn = user !== null;

        if (this.previousIsLoggedInCheck !== isLoggedIn) 
        {
            setTimeout(() => {
                //login after
            });
        }

        this.previousIsLoggedInCheck = isLoggedIn;
    }

    get currentUser(): User | null 
    {
        const user = LocalStoreManager.getDataObject<User>(DBkeys.CURRENT_USER);
        this.reevaluateLoginStatus(user);
        return user;
    }
}

export default new AuthService();