import React from 'react';
import { UserInfoViewModel } from '../api';
import { Tabs, Upload, Button, Icon, Input } from 'antd';
import upload, { UploadProps } from 'antd/lib/upload';
import AWS from 'aws-sdk';
import { environment } from '../_environments/environment';
const { TabPane } = Tabs;

interface IAccountDetailsContainerProps{
    onHandleChangeFirstName:(e:any)=>void
    onHandleChangeMiddleName:(e:any)=>void
    onHandleChangeLastName:(e:any)=>void
    onHandleChangeProfileImage:(e:any)=>void  
    onHandleChangePhoneNumber:(e:any)=>void 
    users: UserInfoViewModel,
    visibleData:boolean,
    onHandleSaveButtonClick:()=>void
    onHandleCurrentPasswordChange:(e:any)=>void 
    onHandlePhotoUpload:(e:any)=>void
    onHandleNewPasswordChange:(e:any)=>void 
    onHandleConfirmPasswordChange:(e:any)=>void 
    onHandlePasswordSave:()=>void 
    cnfrmPwdValidate:boolean;
    cnfmPwdClrChecker:boolean;
  }

  interface IAccountDetailsComponentState{
    
    ProfileImageURL:string;
    PhoneNumber:string,
    imgKey:string,
    bucket: string,
    progress:string,
    displayValue:number,
    updater:number
  }

  const initialState={
   
    ProfileImageURL:'',
    PhoneNumber:'',
    imgKey: "",
    bucket: "",
    progress: "",
    displayValue: 0,
updater:0
  }


const AccountDetailsComponent:React.FC<IAccountDetailsContainerProps>=(props) => {

const {users,onHandleSaveButtonClick,onHandleChangeFirstName,onHandleChangeLastName,onHandleChangeMiddleName,
  onHandleChangeProfileImage,onHandleChangePhoneNumber,visibleData,onHandleConfirmPasswordChange,cnfmPwdClrChecker,
  onHandleCurrentPasswordChange,onHandleNewPasswordChange,onHandlePasswordSave,cnfrmPwdValidate,onHandlePhotoUpload}=props


const [accountDetailsComponentState,
  setAccountDetailsComponentState] = React.useState<IAccountDetailsComponentState>(initialState);

  const {ProfileImageURL,bucket,displayValue,imgKey,progress,updater}=accountDetailsComponentState

//   React.useEffect(() => {
//      (async () => {
//       setAccountDetailsComponentState((accountDetailsComponentState) => {
//         return {
//             ...accountDetailsComponentState,
//             updater:updater+1
//         }
//     });
//     })();
// }, [visibleData])

  React.useEffect(() => {
    if(imgKey!==""){
     (async () => {
      onHandleChangeProfileImage(`https://s3.ap-south-1.amazonaws.com/${bucket}/${imgKey}`)
       
    })();
  }
}, [imgKey])

const handlePhotoUpload=(e:any)=>
{
  
  onHandlePhotoUpload(e);
 
  
}

    const handleChangeFirstName=(e:any)=>{
      onHandleChangeFirstName(e.target.value)
             
    }
    
    const handleChangeLastName=(e:any)=>{
       onHandleChangeLastName(e.target.value) 
    }
    
    const handleChangePhoneNumber=(e:any)=>{
     onHandleChangePhoneNumber(e.target.value)
    }
       
    const handleSaveButtonClick=(e:any)=>{
      e.preventDefault();
      onHandleSaveButtonClick();
    }


const handleCurrentPasswordChange=(e:any)=>{
  onHandleCurrentPasswordChange(e.target.value);
}

const handleNewPasswordChange=(e:any)=>{
onHandleNewPasswordChange(e.target.value);
}

const handleConfirmPasswordChange=(e:any)=>{
onHandleConfirmPasswordChange(e.target.value);
}

const handlePasswordSave=(e:any)=>{
  e.preventDefault();
  onHandlePasswordSave();
} 

    const onStart = (file: any) => {
      console.log(21, "onStart", file, file.name);
      setAccountDetailsComponentState((accountDetailsComponentState) => {
          return {
              ...accountDetailsComponentState,
              progress: `${file.name} is started to upload `,
          }
      });
  
  }
  
  const onProgress = (d: any, file: any) => {
      console.log(22, "onProgress", `${d.percent}%`, file.name);
      setAccountDetailsComponentState((accountDetailsComponentState) => {
          return {
              ...accountDetailsComponentState,
              progress: `file upload ${d.percent}% "completed`,
          }
      });
  
  }
  
  const onSuccess = (ret: any, file: any) => {
      console.log(23, "onSuccess", ret, file);
      // const audioPicture = { id: "", audioId: audioId, displayOrder: displayValue, pictureInfo: pictureInfo, active: true } as AudioPictureInfo
   
      setAccountDetailsComponentState((accountDetailsComponentState) => {
          return {
              ...accountDetailsComponentState,
              isCreatedOrUpdated: false,
              displayValue: displayValue + 1,
          }
      });
  }


  
  
  const onError = (err?: any) => {
      console.log(24, "onError", err);
  }
  
  const uploadProps = {
  
      multiple: false,
      onStart: onStart,
      onSuccess: onSuccess,
      onError: onError,
      onProgress: onProgress,
      customRequest(f: any) {
          AWS.config.update({
              accessKeyId: "AKIAXVD5BCMCJQWMDSBI",
              region: "ap-south-1",
              secretAccessKey: "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"
          });
  
          const S3 = new AWS.S3();
          console.log(25, "DEBUG filename", f.file.name);
          console.log(26, "DEBUG file type", f.file.type);
  
          const objParams = {
              Bucket: "btc.audiotrial",
              Key: (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase() + f.file.name,
              Body: f.file,
              ACL: 'public-read'
          };
          S3.putObject(objParams)
              .on("httpUploadProgress", ({ loaded, total }) => {
                  onProgress(
                      {
                          percent: Math.round((loaded / total) * 100)
                      },
                      f.file
                  );
              })
              .send((err: any, data: any) => {
                  console.log(data, 20);
                  if (err) {
                      onError();
                      console.log("Something went wrong");
                      console.log(err.code);
                      console.log(err.message);
                  } else {
                      console.log(10, objParams)
                      onSuccess(data.response, f.file);
                      console.log("SEND FINISHED");
                      setAccountDetailsComponentState((accountDetailsComponentState) => {
                          return {
                              ...accountDetailsComponentState,
                              imgKey: objParams.Key,
                              bucket: objParams.Bucket,
                          }
                      });
                      
                  }
              });
      }
  } as UploadProps;
  
  console.log(777, imgKey);
  console.log(888, bucket);
  const imgUrl = `https://s3.ap-south-1.amazonaws.com/${bucket}/${imgKey}`;
  console.log(999, imgUrl);
  
 
    return (
        <>
 <div className="container">
        <div className="row flex-lg-nowrap">
        <div className="col">
        <div className="row">
          <div className="col mb-3">
            <div className="card">
              <div className="card-body">
                <div className="e-profile">

                <div className="row">
        <div className="col-12 col-sm-auto mb-3">
          <div className="mx-auto" style={{width: '140px'}}>
          <div className="d-flex justify-content-center align-items-center rounded"  style={{height: '140px', backgroundColor: 'rgb(233, 236, 239)'}}  >
          {/* style={{height: '140px', backgroundColor: 'rgb(233, 236, 239)'}} */}
           <span style={{color: 'rgb(166, 168, 170)', font: 'bold 8pt Arial'}}> 
      <img
                style={{width:'140px' ,height:'140px'}}
                  
                src={ 
                    environment.baseUrl 
                    +
                    `/Upload/ProfileImage/${users.profileImageURL}` 
                  }
                  alt=""
                />
                

 </span> 


          </div>
            {/* <div className="d-flex justify-content-center align-items-center rounded" style={{height: '140px', backgroundColor: 'rgb(233, 236, 239)'}}>
              <span style={{color: 'rgb(166, 168, 170)', font: 'bold 8pt Arial'}}>
              {/* <img style={{width:'140px' ,height:'140px'}} src={imgKey===""? users.profileImageURL: `https://s3.ap-south-1.amazonaws.com/${bucket}/${imgKey}` }  /> */}
              {/* </span> */}
            {/* </div> */} 
          </div>
        </div>
        <div className="col d-flex flex-column flex-sm-row justify-content-between mb-3">
          <div className="text-center text-sm-left mb-2 mb-sm-0">
            <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">{users.firstName} </h4>
    <p className="mb-0">{users.email}</p>
            <div className="text-muted"><small>Last seen : {users.lastLoginTime}</small></div>
            <form>
            <div className="mt-2">
              {/* <button className="btn btn-primary" type="button">
                <i className="fa fa-fw fa-camera" />
                <input type="file" id="img" name="img" accept="image/*" />
              </button> */}
               {/* <Upload {...uploadProps} >
                             <Button className=" ">
                                 <Icon type="upload" /> Click to Upload
                      </Button>
                         </Upload> */}

                        <h3>Upload Profile Picture</h3>
                         <input  type="file" onChange={(e)=>handlePhotoUpload(e)}  name="ProfileImage"/>
            
            
            </div>
            </form>
            
          </div>
        </div>
      </div>

      {/* <ul className="nav nav-tabs" style={{float:'left',marginTop:10}}>
        <li className="nav-item"><a href="#" className="active nav-link">Profile Details</a></li>
      </ul> */}


      <Tabs defaultActiveKey="1" >
    <TabPane tab="Profile Details" key="1">
    <div className="row" style={{marginTop: '50px'}}>
        <div className="col">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>First Name</label>
                <input className="form-control" type="text" name="name" 
                placeholder="Demo" defaultValue={users.firstName} onChange={handleChangeFirstName}/>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Last Name</label>
                <input className="form-control" type="text" name="username" 
                placeholder="demo" defaultValue={users.lastName} onChange={handleChangeLastName}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Phone Number</label>
                <input className="form-control" type="text" name="number" 
                placeholder="number" defaultValue={users.phoneNumber} onChange={handleChangePhoneNumber}/>
              </div>
            </div>
             <div className="col" >
              <div className="form-group">
                <label>Email</label>
                <input className="form-control" type="text" placeholder={users.email} disabled />
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-end">
          <button className="btn btn-primary" type="submit" onClick={handleSaveButtonClick}>Save Changes</button>
        </div>
      </div>

    </TabPane>
    <TabPane tab="Change Password" key="2">
    <div className="row" >
        <div className="col-12 col-sm-6 mb-3">
          <div className="mb-2" ><b></b></div>
          <div className="row" >
            <div className="col">
              <div className="form-group">
                <label>Current Password</label>
                <Input.Password type="password" placeholder="Current Password" 
                onChange={handleCurrentPasswordChange}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>New Password</label>
                <Input.Password type="password" placeholder="New Password" 
                onChange={handleNewPasswordChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Confirm <span className="d-none d-xl-inline">Password</span></label>
                <Input.Password  type="password"  placeholder="Confirm Password" 
                style={cnfmPwdClrChecker?(cnfrmPwdValidate? {borderColor:"red"}:{borderColor:"green"}):{borderColor:"#ccc"}}
                onChange={handleConfirmPasswordChange} /> 
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-end">
          <button className="btn btn-primary" type="submit"  onClick={handlePasswordSave}>Save Changes</button>
        </div>
      </div>


    </TabPane>
    
  </Tabs>

      {/* <div className="tab-content pt-3">
        <div className="tab-pane active">
          <form className="form" noValidate> */}

         

     
     
          {/* </form></div></div> */}
      
                </div></div></div></div></div></div>
        </div></div>
        </>
    );
};

export default AccountDetailsComponent;