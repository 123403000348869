import React from 'react';
import WishLIstComponenet from './WishLIstComponenet';
import { getCartItemsList } from '../action/CartAction';
import { useWishListDispatcher, useWishListContext, getWishListListResult } from '../action/WishLIstAction';
import { WishlistSearchDataRequestModel } from '../api';
import AuthService from '../_services/AuthService';
import axios from 'axios';
import { AppUrls } from '../_environments/environment';
import { message } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';

const key = 'updatable';

interface IWishListContainerProps{
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
}

interface IWishListContainerState{
    addCart:boolean,
    id:string,
    wishList:boolean,
}

const initialState = {
    addCart:false ,
    id:'',
    wishList:false
} as IWishListContainerState

const WishListContainer:React.FC<IWishListContainerProps>= (props) => {
  
    
    let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
    
    const [WishListContainerState,
        setWishListContainerState] = React.useState<IWishListContainerState>(initialState);

const {addCart,id,wishList}=WishListContainerState

        const wishListDispatcher = useWishListDispatcher();
        const wishListContext = useWishListContext();
        const {wishListResult } = wishListContext;

const {onHandleCartLoad,onHandleWishListLoad}=props
console.log(3322,wishListResult)
        React.useEffect(() => {
            (async () => {
                await getWishListListResult(wishListDispatcher,{currencyId:"1"} as WishlistSearchDataRequestModel );
            //     setHomeHeaderState({
            //         ...homeHeaderState,
            //          cartItemNo:cartItems.searchResult?.data?.length,
            //     });
             })();
        }, [addCart,wishList])


     
    React.useEffect(() => {
        if (addCart) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.AddToCart

                axios.post(url, JSON.stringify({ audioId: id }), { headers: headers }).then(res => {
                    
                    if (res) {
                        onHandleCartLoad();
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                    }
                })
            })();
        }
    }, [addCart])


    function cancel(e: any) {
        console.log(e);
      }

    React.useEffect(() => {
        if (wishList) {
            (async () => {

                let headers = {
                    Authorization:
                     `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.AddToWishList

                axios.post(url, JSON.stringify({ audioId: id }), { headers: headers }).then(res => {
                    if (res) {
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                        setWishListContainerState({
                            ...WishListContainerState,
                            addCart:false,
                            wishList: false
                        });

                    }
                })
            })();
        }
    }, [wishList])

   

        const OnHandleAddtoCart = (id:any) => {

            setWishListContainerState({
                ...WishListContainerState,
                id:id,
                addCart: true
            });
    
        }

        const OnHandleAddtoWishList = (id:any) => {
            setWishListContainerState({
                ...WishListContainerState,
                id:id,
                wishList: true
            });
        }


    return (
        <>
        <div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div id="tg-twocolumns" className="tg-twocolumns">
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12 pull-left">
                <div id="tg-content" className="tg-content">
                  <div className="tg-products">
                    <div className="tg-sectionhead">
                      <h2>Wishlist</h2>
                      <Popconfirm
                                    title="Are you sure to delete this task?"
                                    onConfirm={() => OnHandleAddtoWishList("DeleteAll")}
                                    onCancel={() => OnHandleAddtoWishList('0')}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <button
                                      //  onClick={() => handleClick(data.rowId)}
                                      className="btn btn-danger save-event waves-effect waves-light"
                                      style={{ marginLeft: 12 }}
                                    >
                                      Delete  All
                                    </button>
                                  </Popconfirm>
                    </div>
                    <div className="tg-productgrid">
                 <WishLIstComponenet
        wishListResult={wishListResult}
        OnHandleAddtoCart={OnHandleAddtoCart}
        OnHandleAddtoWishList={OnHandleAddtoWishList}
        />
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
    );
};
export default WishListContainer;