import React from 'react';
import { PackageInfo } from '../_models/PackageList';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'antd';
import AuthService from '../_services/AuthService';
import { Collapse } from 'antd';
require('../css/main.css');
require('../css/bootstrap.min.css');
require('../css/font-awesome.min.css');
require('../css/main.css');


const { Panel } = Collapse;

interface IPackagesAndOffersComponentProps extends RouteComponentProps {
  packages: PackageInfo[],
  onHandlePackagePriceLoad: (value: any) => void;
  onHandlememeberCountLoad: (memberCount: any) => void;
  keyValue: number,
  onHandleKeyChange: (value: any) => void;
  onHandleKeyChangeMobile:(value:any)=>void;
}



const PackagesAndOffersComponent: React.FC<IPackagesAndOffersComponentProps> = (props) => {
  let authorizationToken = AuthService.accessToken;
  const { packages, onHandlePackagePriceLoad, onHandlememeberCountLoad, onHandleKeyChange,onHandleKeyChangeMobile ,keyValue } = props;

  console.log(7878, authorizationToken);
  if (!packages || !packages.length) {
    return null;

  }

  const OnCheckBoxClicked = (e: any) => {
    console.log(116, e);

  }

  const OnCheckBoxClickeds = () => {
    console.log(444444);
  }

  const HandleStartButtonClick = (price: any, membersihpCount: any) => {
    onHandlePackagePriceLoad(price  );
    onHandlememeberCountLoad(membersihpCount);
    // props.history.push('/package_transaction')
  }

  const OnReadMoreClick = (value: any) => {
    onHandleKeyChange(value);
  }

const OnReadMoreClickOnMobile = (value: any) => {
    onHandleKeyChangeMobile(value);
  }
  
  console.log(7676, keyValue)

  return (
    <>
      <section className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-sectionhead">
              <div className="container">
                <div className="top">
                  <h1>Package &amp; Offer</h1>
                  {/* <div className="toggle-btn">
                    <span style={{margin: '0.8em'}}>Annually</span>
                    <label className="switch">
                      <input type="checkbox" id="checbox"  onClick={(e)=>OnCheckBoxClicked} />
                      <span className="slider round" />
                    </label>
                    <span style={{margin: '0.8em'}}>Monthly</span></div> */}
                </div>
                <div className="package-container">

                  {packages.map((packageData, index) => {
                    return (
                      <>
                        <div className="packages">
                          <h1 className={`pkgname2`}>{packageData.name}</h1>
                          <h2 className={`text1 pkgname2`}>{packageData.price}</h2>
                          {/* <h2 className="text2">$119.99</h2>  */}
                          <ul className="list">
                            <li className="first">{packageData.memberCount} Members</li>
                            <li>{packageData.dayCount} Days</li>
                            <li>{packageData.description}</li>
                            {/* <li>Email Support</li> */}
                          </ul>
                          {authorizationToken != null ?
                            <button onClick={() => HandleStartButtonClick(packageData.price, packageData.memberCount)} className={`button button1`}>Start Now</button> :
                            <Link to='/login' className={`button button${index+2}`}>Start Now</Link>}
                          <a onClick={() => OnReadMoreClick(index)} className=' read_more_on_package1'>  Read More</a>
                          <a onClick={() => OnReadMoreClickOnMobile(index)} className=' read_more_on_package2'>  Read More</a>
                        </div>
                      </>

                    )
                  })}

                </div>
              </div>
            </div>
          </div>

          {packages.map((packageData, index) => {

if(index==keyValue){
                  return (
                    <>
                    
                    <div className="row read_more_on_package1">
      {/* Blog Post Content Column */}
      <div className="col-lg-12">
        <hr />
        <h2>{packageData.name}</h2>
        <hr />
        {/* Preview Image */}
        {/* <img className="img-responsive" src={require("../images/banner-bg.jpg")} alt="" /> */}
        <hr />
        {/* Post Content */}
        <p className="lead">{packageData.description}</p>
        <hr />
      </div>
    </div>
                    
                    </>)}})}
        </div>
      </section>
    </>
  );
};

export default withRouter(PackagesAndOffersComponent);
