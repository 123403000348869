import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { IBookDetailsAction, bookDetailsReducer } from "../action/BookDetailsAction";
import { useImmerReducer } from "use-immer";
import { ProductDetail } from "../api";

export const BookDetailsDispatcherContext = React.createContext<React.Dispatch<IBookDetailsAction> | null>(null);
export const BookDetailsStateContext = React.createContext<IBookDetailsManagementState | null>(null);

export interface IBookDetailsManagementProps extends RouteComponentProps<any> { }

export interface IBookDetailsManagementState {
    visible:boolean;
    value:number;
    bookDetailsResult:ProductDetail
}

export const initialBookDetailsManagementState = {
    visible:false,
    value:0,
    bookDetailsResult:{} as ProductDetail,
} as IBookDetailsManagementState;

export const BookDetailsContextProvider: React.FC = ({ children }) => {
    const [bookDetailsState, dispatcher] = useImmerReducer(bookDetailsReducer, initialBookDetailsManagementState);

    return (
        <BookDetailsDispatcherContext.Provider value={dispatcher}>
            <BookDetailsStateContext.Provider value={bookDetailsState}>
                {children}
            </BookDetailsStateContext.Provider>
        </BookDetailsDispatcherContext.Provider>
    )
}