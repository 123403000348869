import React from 'react';
import ContactComponenet from './ContactComponenet';
import { useAboutUsDispatcher, useAboutUsContext, getAboutUs } from '../action/AboutUsAction';


const ContactUsContainer = () => {
    
    const aboutUsDispatcher = useAboutUsDispatcher();
    const aboutUsContext = useAboutUsContext();
    const {aboutUs }=aboutUsContext;

    console.log(aboutUs)
    React.useEffect(() => {
        (async () => {
         await getAboutUs(aboutUsDispatcher)
        })();
    }, [])

    return (
        <>
           < ContactComponenet
           aboutUs={aboutUs}
           />
        </>
    );
};

export default ContactUsContainer;