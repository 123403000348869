import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { ICartAction, cartReducer } from "../action/CartAction";
import { useImmerReducer } from "use-immer";
import { CartResult } from "../api/controller";
export const CartDispatcherContext = React.createContext<React.Dispatch<ICartAction> | null>(null);
export const CartStateContext = React.createContext<ICartManagementState | null>(null);

export interface ICartManagementProps extends RouteComponentProps<any> { }

export interface ICartManagementState {
    visible:boolean;
    value:number;
    cartItems:CartResult
}

export const initialCartManagementState = {
    visible:false,
    value:0,
    cartItems:{} as CartResult,
} as ICartManagementState;

export const CartContextProvider: React.FC = ({ children }) => {
    const [cartState, dispatcher] = useImmerReducer(cartReducer, initialCartManagementState);

    return (
        <CartDispatcherContext.Provider value={dispatcher}>
            <CartStateContext.Provider value={cartState}>
                {children}
            </CartStateContext.Provider>
        </CartDispatcherContext.Provider>
    )
}