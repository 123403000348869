import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { IUserAction, userReducer } from "../action/UserAction";
import { useImmerReducer } from "use-immer";
import { UserInfoViewModel } from "../api";

export const UserDispatcherContext = React.createContext<React.Dispatch<IUserAction> | null>(null);
export const UserStateContext = React.createContext<IUserManagementState | null>(null);

export interface IUserManagementProps extends RouteComponentProps<any> { }

export interface IUserManagementState {
    visible:boolean;
    value:number;
    users:UserInfoViewModel
}

export const initialUserManagementState = {
    visible:false,
    value:0,
    users:{} as UserInfoViewModel,
} as IUserManagementState;

export const UserContextProvider: React.FC = ({ children }) => {
    const [userState, dispatcher] = useImmerReducer(userReducer, initialUserManagementState);

    return (
        <UserDispatcherContext.Provider value={dispatcher}>
            <UserStateContext.Provider value={userState}>
                {children}
            </UserStateContext.Provider>
        </UserDispatcherContext.Provider>
    )
}