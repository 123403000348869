import React from 'react';
import ReactDOM from 'react-dom';
 import 'antd/dist/antd.css';
 import 'bootstrap/dist/css/bootstrap.min.css'
// import './css/login.css';
import App from './App';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { LanguageContextProvider } from './context/LanguageContext';
import Home from './home/Home';

// import './js/wow.min.js'
// require('./css/style.css')
// require('./css/login.css')
// require('./css/banner.css')
// require('./css/scroll.css')
// require('./css/animate.css')
// require('./font/css/font-awesome.css')
// require('./font/css/font-awesome.min.css')
// require('./font/css/font-family-open-san.css')
// require('./font/css/font-family-roboto.css')
// require('./css/easy-responsive-tabs.css')
// require('./css/gridimage_hover.css')
// require('./css/gridimage_hover.css')
// require('./css/login1.css')
// require('./css/flexi_slider.css')
require('./css/bootstrap.min.css')


console.log("Inside index.tsx before rendering");
ReactDOM.render(

    <App />

, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
console.log("Inside index.tsx after rendering");