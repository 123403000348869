import React from 'react';
import { RouteComponentProps, Router, Route, Redirect, withRouter } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FrontPage from '../frontPage/FrontPage';
import {
    Carousel,
    Accordion,
    Button
} from 'react-bootstrap';
import HomePageCard from '../components/HomePageCard';
import Footer from './../components/shared/Footer';
import FrontPageBookComponenet from '../components/FrontPageBookComponenet';
import HomeHeader from '../components/shared/HomeHeader';
import { HomeDispatcherContext, HomeStateContext } from '../context/HomeContext';
import { visibleDataTwo, useHomeDispatcher, useHomeContext, getHomeListProduct } from '../action/HomeAction';
import { GetHomeProductsPostModel, LanguageListVM } from '../api/controller';
import HomeBookPageContainer from '../components/shared/homeBooks/HomeBookPageContainer';
import SearchComponent from '../search/SearchComponent';
import SearchContainer from '../search/SearchContainer';
import { SearchContextProvider } from '../context/SearchContext';
import { Link } from 'react-router-dom';
import AdvanceSearchComponent from '../search/AdvanceSearchComponent';
import AccountDetailsContainer from '../accountDetails/AccountDetailsContainer';
import WishListContainer from '../wishList/WishListContainer';
import PackagesAndOffersContainer from '../packages_offers/PackagesAndOffersContainer';
import PurchasedBooksContainer from '../purchase/PurchasedBooksContainer';
import BookDetailsContainer from '../bookdetails/BookDetailsContainer';
import { BookDetailsContextProvider } from '../context/BookDetailsContext';
import { ProductReviewContextProvider } from '../context/ProductReviewContext';
import { AboutUsContextProvider } from '../context/AboutUsContext';
import { LanguageContextProvider } from '../context/LanguageContext';
import CartContainer from '../cart/CartContainer';
import { CartContextProvider } from '../context/CartContext';
import { UserContextProvider } from '../context/UserContext';
import { useLanguageDispatcher, useLanguageContext, getLanguageList } from '../action/LanguageAction';
import TransactionStatus from '../transaction/TransactionStatus';
import TransactionFormContainer from '../transaction/TransactionFormContainer';
import { WishListContextProvider } from '../context/WishListContext';
import AdvanceSearchContainer from '../search/AdvanceSearchContainer';
import TermsAndConditions from '../general/TermsAndConditions';
import ServiceAndCondition from '../general/ServiceAndCondition';
import HomeBookPageCategoryViewComponent from '../components/shared/homeBooks/HomeBookPageCategoryViewComponent';
import AboutUsContainer from '../aboutUs/AboutUsContainer';
import ContactUsContainer from '../contactUs/ContactUsContainer';
import BillingHistoryContainer from '../billingHistory/BillingHistoryContainer';
import AdminRequestContainer from '../adminRequest/AdminRequestContainer';
import PackageTransactionContainer from '../packageTransaction/PackageTransactionContainer';
import {AudioBookContextProvider} from '../context/AudioBookContext';
require('../css/bootstrap.min.css')
require('../css/color-orange.css')
require('../css/font-awesome.min.css')
require('../css/icomoon.css')
require('../css/jquery-ui.css')
require('../css/main.css')
require('../css/owl.carousel.css')
require('../css/responsive.css')
require('../css/w3css.css')

console.log("Inside Home.tsx...before IHomeProps");

interface IHomeProps extends RouteComponentProps<any> {
    // languages:LanguageListVM 
}

interface IHomeState {
    languageId: any;
    searchTerm: string;
    isCartLoad: number;
    isWishListLoad: number;
    updated: number;
    author: string;
    narrator: string;
    publisher: string;
    translator: string;
    category: string;
    packagePrice:number,
    memberCount:number,
}

const initialState = {
    
    languageId: "",
    searchTerm: "",
    isCartLoad: 0,
    isWishListLoad: 0,
    updated: 0,
    author: "",
    narrator: "",
    publisher: "",
    translator: "",
    category: "",
    packagePrice:0,
  memberCount:0,
} as IHomeState

const Home: React.FC<IHomeProps> = (props) => {
    const homeDispatcher = useHomeDispatcher();
    const homeContext = useHomeContext();
    const languagehDispatcher = useLanguageDispatcher();
    const languageContext = useLanguageContext();
    const { value, visible, homeProducts } = homeContext;
    const { languages } = languageContext;

    const [
        homeState,
        setHomeState
    ] = React.useState<IHomeState>(initialState);
   
    const { languageId, searchTerm, isWishListLoad, isCartLoad, updated, author, translator, publisher, narrator, category,memberCount,packagePrice } = homeState;
    
    //    const { }=props
    //  console.log(43,languages);
  
    //.....................................useEffects.......................................
    
    React.useEffect(() => {
        (async () => {
            await getLanguageList(languagehDispatcher)

        })();
    }, [])

    React.useEffect(() => {
        (async () => {
            let m = languages.languages?.map((lan, index) => {
                return lan.id;
            })

            setHomeState((homeState) => {
                return {
                    ...homeState,
                    languageId: m ? m[0] : null
                }
            });

        })();
    }, [languages])

     React.useEffect(() => {
        (async () => {
            await getHomeListProduct(homeDispatcher, { languageId: languageId, currencyId: "1" } as GetHomeProductsPostModel);
        })();
    }, [languageId])

    React.useEffect(() => {
        console.log(19990999);
        if(packagePrice!=0){
    console.log(100000999);
           props.history.push('/package_transaction');
        }
    }, [memberCount])

    //.....................................onHandleMethods...................................

    const onHandleLanguageId = (languageId: string) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                languageId: languageId
            }
        });
    }

    const onHandleSearchTerm = (searchTerm: string) =>
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                searchTerm: searchTerm
            }
        });
    }

    const handleUpdated = () => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                updated: updated + 1,
            }
        });
    }

    const onHandleCartLoad = () => 
    {
        console.log(111)
        setHomeState((homeState) => {
            return {
                ...homeState,
                isCartLoad: isCartLoad + 1,
            }
        });
    }

    const onHandleWishListLoad = () => 
    {
        console.log(111)
        setHomeState((homeState) => {
            return {
                ...homeState,
                isWishListLoad: isWishListLoad + 1,
            }
        });
    }

    const onHandleAuthorChange = (value: any) => 
    {
        console.log(123, value)
        setHomeState((homeState) => {
            return {
                ...homeState,
                author: value
            }
        });
    }
    
    console.log(129, author)

    const onHandleNarratorChange = (value: any) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                narrator: value
            }
        });
        console.log(24, value)
    }

    const onHandlePublisherChange = (value: any) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                publisher: value
            }
        });
        console.log(25, value)
    }

    const onHandleTranslatorChange = (value: any) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                translator: value
            }
        });
        console.log(26, value)
    }

    const OnHandleCategoyChange = (value: any) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                category: value
            }
        });
        console.log(27, value)
    }

    const onHandleRestCall = () => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
                publisher: "",
                author: "",
                category: "",
                narrator: "",
                translator: ""
            }
        });
        console.log(25, value)
    }

    const onHandlePackagePriceLoad = (value:any) => 
    {
        setHomeState((homeState) => {
            return {
                ...homeState,
              packagePrice:value,
            }
        });
        console.log(696,value);
      }
    
      const onHandlememeberCountLoad=(memberCount:any)=>
      {
        setHomeState((homeState) => {
            return {
                ...homeState,
              memberCount:memberCount,
            }
        });
        console.log(695,memberCount);
        // handlePushToTransaction();
      }

    /*  
        const handlePushToTransaction=()=>
        {
        packagePrice!==0?props.history.push('/package_transaction'):props.history.push('/package_transaction')
       }
    */
    console.log("Home.tsx line 297");

    return (
    <>
    <Route exact path="/">
        <Redirect to="/home" />
    </Route>
    <div className="tg-home tg-homevtwo">
        {/*Wrapper Start*/}
        <div id="tg-wrapper" className="tg-wrapper tg-haslayout">
            <SearchContextProvider>
                <LanguageContextProvider>
                    <UserContextProvider>
                        <CartContextProvider>
                            <WishListContextProvider>
                                <HomeHeader {...props}
                                    onHandleLanguageId={onHandleLanguageId}
                                    onHandleSearchTerm={onHandleSearchTerm}
                                    isCartLoad={isCartLoad}
                                    isWishListLoad={isWishListLoad}
                                    updated={updated}
                                    onHandleCartLoad={onHandleCartLoad}
                                    onHandleWishListLoad={onHandleWishListLoad}
                                />
                            </WishListContextProvider>
                        </CartContextProvider>
                    </UserContextProvider>
                </LanguageContextProvider>
            </SearchContextProvider>
        
{/*----------------------------------Router-Home---------------------------------------*/}

        <Route exact path="/home">
        {/* <div id="tg-homeslider"className="tg-homeslider tg-homeslidervtwo tg-haslayout owl-carousel">  */}
            <div className="item" data-vide-bg="poster: images/banner-bg.jpg" data-vide-options="position: 0% 50%">
                <img src={require('../images/banner-bg.jpg')} alt="Los Angeles" />
            </div>
        {/* </div>  */}
            
            <main id="tg-main" className="tg-main tg-haslayout">
                <UserContextProvider>
                    <CartContextProvider>
                        <WishListContextProvider>
                            <HomeBookPageContainer
                                onHandleCartLoad={onHandleCartLoad}
                                onHandleWishListLoad={onHandleWishListLoad}
                                isWishListLoad={isWishListLoad}
                                isCartLoad={isCartLoad}
                            />
                        </WishListContextProvider>
                    </CartContextProvider>
                </UserContextProvider>
            </main>
        </Route>
{/*----------------------------------Router-Home---------------------------------------*/}

    {/* <div className="tg-home tg-homevtwo"> *
            Wrapper Start
            <div id="tg-wrapper" className="tg-wrapper tg-haslayout"> 
            </div> 
        </div> 
        <Route exact path="/home">
            <div className="banner-wrapper">
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to={0} className="active" />
                        <li data-target="#myCarousel" data-slide-to={1} />
                        <li data-target="#myCarousel" data-slide-to={2} />
                    </ol>
                    
            <div className="carousel-inner">
                <div className="item active">
                    <img src={require('../images/login_banner.jpg')} alt="Los Angeles" style={{ width: '100%' }} />
                </div>
            </div>
            </div></div>
            <HomeBookPageContainer />
        </Route>*/}

{/*----------------------------------Router-search---------------------------------------*/}
        <Route exact path="/search">
            <SearchContextProvider>
                <SearchContainer
                    searchTerm={searchTerm}
                    author={author}
                    narrator={narrator}
                    publisher={publisher}
                    translator={translator}
                    category={category}
                    onHandleRestCall={onHandleRestCall}
                />
            </SearchContextProvider>
        </Route>

{/*--------------------------------Router-audioBooks-------------------------------------*/}  

        <Route exact path="/audioBooks">
            {
                <div>
                    <h2>Audio Books</h2>
                </div>}
                <main id="tg-main" className="tg-main tg-haslayout">
                    <UserContextProvider>
                        <CartContextProvider>
                            <WishListContextProvider>
                                <HomeBookPageContainer
                                    onHandleCartLoad={onHandleCartLoad}
                                    onHandleWishListLoad={onHandleWishListLoad}
                                    isWishListLoad={isWishListLoad}
                                    isCartLoad={isCartLoad}
                                />
                            </WishListContextProvider>
                        </CartContextProvider>
                    </UserContextProvider>
                </main>
        </Route>

{/*----------------------------------Router-advanceSearch----------------------------------*/}

        <Route exact path="/advanceSearch">
            <SearchContextProvider>
                <AdvanceSearchContainer
                    onHandleAuthorChange={onHandleAuthorChange}
                    onHandleNarratorChange={onHandleNarratorChange}
                    onHandlePublisherChange={onHandlePublisherChange}
                    onHandleTranslatorChange={onHandleTranslatorChange}
                    OnHandleCategoyChange={OnHandleCategoyChange}
                />
            </SearchContextProvider>
        </Route>

{/*----------------------------------Router-accountDetails---------------------------------*/}
                    
        <Route exact path="/accountDetails">
            <UserContextProvider>
                < AccountDetailsContainer
                    handleUpdated={handleUpdated}
                />
            </UserContextProvider>
        </Route>

        <Route exact path="/wishList">
            <AboutUsContextProvider>
                <WishListContextProvider>
                    < WishListContainer
                        onHandleCartLoad={onHandleCartLoad}
                        onHandleWishListLoad={onHandleWishListLoad}
                    />
                </WishListContextProvider>
            </AboutUsContextProvider>
        </Route>

                    <Route exact path="/packages_offers">
                        < PackagesAndOffersContainer 
                         onHandlePackagePriceLoad={onHandlePackagePriceLoad}
                         onHandlememeberCountLoad={onHandlememeberCountLoad}
                        />
                    </Route>

                    <Route exact path="/purchased_books">
                        < PurchasedBooksContainer />
                    </Route>

                    <Route  exact path='/package_transaction'>
      

      <PackageTransactionContainer
       packagePrice={packagePrice}
       memberCount={memberCount}
      />
       </Route>
                    <Route exact path="/cart_Items">
                        <CartContextProvider>
                            <UserContextProvider>
                                <CartContainer
                                    onHandleCartLoad={onHandleCartLoad}
                                    onHandleWishListLoad={onHandleWishListLoad}
                                />
                            </UserContextProvider>
                        </CartContextProvider>
                    </Route>


                    <Route exact path="/transaction/status/:id">
                              <UserContextProvider>
                                <CartContextProvider>
                                <TransactionStatus {...props} />
                                </CartContextProvider>
                                </UserContextProvider>
                    </Route>
                    <Route exact path="/transaction">
                        <UserContextProvider>
                            <CartContextProvider>
                                <TransactionFormContainer {...props} />
                            </CartContextProvider>
                        </UserContextProvider>
                    </Route>

                    <Route path='/terms-and-condition'>
                        <TermsAndConditions />
                    </Route>

                    <Route path='/service-and-condition'>
                        <ServiceAndCondition />
                    </Route>


                    <Route exact path="/about_us">
                        <AboutUsContextProvider>
                            <AboutUsContainer />
                        </AboutUsContextProvider>
                    </Route>



                    <Route exact path="/contact_us">
                        <AboutUsContextProvider>
                            <ContactUsContainer />
                        </AboutUsContextProvider>
                    </Route>

                    <Route exact path="/order_details">
                    <BillingHistoryContainer/>
                    </Route>
                    
                    <Route exact path="/request_to_user">
                    <AdminRequestContainer/>
                    </Route>
                
                    <main id="tg-main" className="tg-main tg-haslayout">

                        <Route exact path="/book_details/:id">
                            < BookDetailsContextProvider>
                                <ProductReviewContextProvider>
                                    <CartContextProvider>
                                        <WishListContextProvider>

                                            < BookDetailsContainer
                                                {...props}
                                                onHandleCartLoad={onHandleCartLoad}
                                                onHandleWishListLoad={onHandleWishListLoad}
                                            />
                                        </WishListContextProvider>
                                    </CartContextProvider>
                                </ProductReviewContextProvider>
                            </BookDetailsContextProvider>

                        </Route>

                    



                    
                    <Footer /></main>
                </div>
            </div>
        </>
    )
}

export default withRouter( Home);