import React from 'react';
// import '../myStyle/Register.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Container, Spinner } from 'react-bootstrap';
import { Modal, Radio, Button, message } from 'antd';
import GroupAdminMembershipModal from '../components/GroupAdminMembershipModal';
// import '../myStyle/packageRegister.css';
import RegisterPackageComponenet from './RegisterPackageComponenet';
import RegisterPackageContainer from './RegisterPackageContainer';
import { AppUrls } from '../_environments/environment';
import AuthService from '../_services/AuthService';
import axios from 'axios';
import { Route, Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { RegistrationFormValidate } from './RegistrationForm';
import { PackageInfo } from '../_models/PackageList';

console.log("Registration.tsx");

interface IRegistrationProps extends  RouteComponentProps<any> 
{
  onHandlePackagePriceLoad:(value:any)=>void;
  onHandlememeberCountLoad:(memberCount:any)=>void;
}

const key = 'updatable';

interface IRegistrationState {
  display: string;
  visible: boolean;
  visibleForm: string;
  visibleAdminPackage: string;
  registerMe:boolean;
  firstName:string;
  lastName:string;
  phoneNumber:string;
  password:string;
  userType:string;
  email:string;
  GroupKey:string;
  isBusy:boolean;
  msg:string;
  confirmPassword:string;
  borderClr:string;
  radioValue:number;
  packages:PackageInfo[],
  GroupPackageId:string;
}

const initialState = {
  display: "none",
  visible: false,
  visibleForm: "",
  visibleAdminPackage: "none",
  registerMe:false,
  firstName:"",
  lastName:"",
  phoneNumber:"",
  password:"",
  email:"",
  GroupKey:"",
  userType:"IndividualUser",
  isBusy:false,
  msg:"",
  confirmPassword:"",
  borderClr:"",
  radioValue:1,
  packages:{} as PackageInfo[] ,
  GroupPackageId:"",

} as IRegistrationState

const Registration: React.FC<IRegistrationProps> = (props: IRegistrationProps) => 
{
  const [registrationState,setRegistrationState] = React.useState<IRegistrationState>(initialState);
  let token=AuthService.accessToken;
  let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 
  const {onHandlePackagePriceLoad,onHandlememeberCountLoad}=props
  const { display, visible, visibleAdminPackage,confirmPassword,borderClr,radioValue,packages,GroupPackageId,visibleForm,registerMe,firstName,lastName,password,phoneNumber,userType,email,GroupKey,isBusy,msg } = registrationState

React.useEffect(() => {
  console.log("Registration.tx 87"); 
  (async () => 
  {
    let url_ = AppUrls.PackageGetAll;
    url_ = url_.replace(/[?&]$/, "");
    let headers = {// Authorization: `Bearer ${authorizationToken}`,
    'Content-type': 'application/json'};
    
    const postData={Page: 1,PageSize: 12,SearchTerm: "",SortOrder: ""}
    
    let formData = new FormData();
    axios.post(url_, JSON.stringify(postData), { headers: headers }).then(res => 
    {
      console.log(1576, res.data.data); 
      if(res.data)
      { 
        setRegistrationState({...registrationState,packages:res.data.packages,});
      }
    })
  }
  )();
},[])

React.useEffect(() =>
{
  console.log(registerMe + "regi");

  if(registerMe)
  {
    console.log("Registrtaion.tx Line 126 if true");
    (async () => 
      {
        console.log(767687686868768686786,"Registration.tx 128")
        let header = {"Content-Type": "application/json","Accept": "application/json","Authorization":`Bearer {authorizationToken}`,};
        
        const url=AppUrls.RegisterUrl

        axios.post(url, JSON.stringify( 
          {
            FirstName:firstName,
            LastName:lastName,
            Email:email,
            PhoneNumber:phoneNumber,
            Password:password,
            UserType:userType,
            MachineId:"1",
            DeviceType:3,
            DefaultCurrencyId:"",
            DefaultLLanguageId: "",
            MembersUserName: "",
            MembersPassword: "",
            MembersCount: 1000,
            GroupKey: "",
            GroupPackageId:GroupPackageId
          }),{ headers: header })
        .then(res => 
          {
            console.log(res + " result")
            if(res.data.isSuccess)
            {
              setRegistrationState((registrationState) => 
              {
                return {...registrationState,isBusy:false,registerMe:false,}
              });
              if(userType!=='IndividualUser')
              {
                console.log(45554454544)
                props.history.push('/package_transaction')
              }
              else
              {
                // if(userType=='GroupAdmin')
                // {
                //   console.log(55555555555)
                //   props.history.push('/package_transaction');
                // }
                props.history.push('/login')  
              }
            }
            else
            {
              setRegistrationState((registrationState) => {
                return {...registrationState,isBusy:false,msg:res.data.message,registerMe:false,}
              });}})

})();
console.log("registration.tx");
setRegistrationState((registrationState) => {
return {
...registrationState,
visible: true}});}}, [registerMe])
React.useEffect(() => {
setRegistrationState((registrationState) => {
return {
...registrationState,
borderClr:password===confirmPassword?'#f2f2f2':'red',
}});}, [confirmPassword])
const handleOk = () => {
setRegistrationState((registrationState) => {
return {
...registrationState,
visible: false,
}});}
const handleCancel = () => {
setRegistrationState((registrationState) => {
return {
...registrationState,
visible: false
}});}
const handleRegistrationButtonClicked=(e:any)=>{
e.preventDefault();
setRegistrationState((registrationState) => {
return {
...registrationState,
registerMe: true,
isBusy:true}});}
const onHandleGroupPackageSelect=(value:any)=>{
console.log(value)
setRegistrationState((registrationState) => {
return {
...registrationState,
GroupPackageId: value}});} 
const onHandlefirstNameChange=(value:any)=>{
    console.log(value)
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        firstName: value
      }
    });
   } 


  const onHandleTnCCkeck=(value:any)=>{
  
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        tnc: value,
       
        
        
      }
    });
}

const onHandleLastNameChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        lastName: value
      }
    });
   } 

const onHandlePasswordNameChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        password: value
      }
    });
   } 

  
  const onHandleConfirmPasswordNameChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        confirmPassword: value,
        // borderClr:password===confirmPassword?'black':'red',
      }
    });
   } 

 

  
  const onHandlePhoneNumberNameChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        phoneNumber: value
      }
    });
   } 

 

  
  const onHandleEmailChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        email: value
      }
    });
   } 

   const onHandleUserChange=(value:any)=>{
    setRegistrationState((registrationState) => {
      return {
        ...registrationState,
        userType: value
      }
    });
    console.log(56,value)
   } 

  // const onChangeRadio = (e:any) => {
  //   console.log('radio checked', e.target.value);
  //   setRegistrationState({
  //     ...registrationState,
  //     radioValue: e.target.value,
  //   });
  // };

   var imgUrl=require('../images/login-bg.jpg')

  return (
    <>




<div className="tg-sectionspace tg-haslayout"  style={{backgroundImage: "url("+imgUrl+")"}}>
        {/* <div className="container">
          <div className="row">
            <div className="tg-contactus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-page" style={{margin: '5% auto'}}>
                  <div className="form">
                    <label className="title">REGISTRATION</label> */}
                    {/* <Form id="myForm" className="login-form"  onSubmit={handleRegistrationButtonClicked        }>
                     <div className="row">  */}
                        
                        {/* <div className="form-check form-check-inline">
                          <input  onClick={handleMemberChecked} name='members' className="form-check-input" type="radio" id="inlineCheckbox1"  />
                          <label className="form-check-label" htmlFor="inlineCheckbox1">Group Member</label>
  </div>  */}

{/* <div className="form-check form-check-inline">
<Radio.Group onChange={onChangeRadio} value={radioValue}>
        <Radio value={1}>Single User</Radio>
        <Radio value={2}>Group Admin</Radio>
        
      </Radio.Group>
                        </div>
                     
                       </div> */}
                       <RegistrationFormValidate
                       onHandleEmailChange={onHandleEmailChange}
                       onHandlePhoneNumberNameChange={onHandlePhoneNumberNameChange}
                       onHandleConfirmPasswordNameChange={onHandleConfirmPasswordNameChange}
                       onHandlePasswordNameChange={onHandlePasswordNameChange}
                       onHandleLastNameChange={onHandleLastNameChange}
                       onHandlefirstNameChange={onHandlefirstNameChange}
                       onHandleUserChange={onHandleUserChange}
                       onHandleGroupPackageSelect={onHandleGroupPackageSelect}
                       onHandlePackagePriceLoad={onHandlePackagePriceLoad}
                       handleRegistrationButtonClicked={handleRegistrationButtonClicked}
                       onHandlememeberCountLoad={onHandlememeberCountLoad}
                       onHandleTnCCkeck={onHandleTnCCkeck}
                      //  radioValue={radioValue}
                       msg={msg}
                       isBusy={isBusy}
                       packages={packages}
                       />
                     
                    
                      {/* <Button 
                      form="myForm" key="submit" htmlType="submit"
                      
                      >{isBusy?  
          (<Spinner animation="border" variant="warning" />) :'Register'}</Button>

<p className="message"> <Link to="/login">
                      <a href="#">back to login</a></Link>
                      </p>
                      
          <br/>
          <h6 style={{color:'red'}}>{msg}</h6>
                    </Form> */}
                  {/* </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>












      {/* <div className="page-wrapper bg-red p-t-180 p-b-100 font-robo">

        <div className="wrapper wrapper--w960">
          <div className="card card-2" style={{marginTop:25}}>
            <div className="card-heading" />
            <div className="card-body">
              {/* <GroupAdminMembershipModal
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
              /> */}
              {/* <h2 className="title">Registration </h2>
              <form method="POST">

                <Container>
                  <Row>
                    <Col >
                      <Form.Check
                        type="radio"
                        label="Single User"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        defaultChecked
                        onClick={handleSingleUserChecked}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                    disabled
                        type="radio"
                        label="Ground Admin"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        onClick={handleGroupAdminChecked}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                     
                        type="radio"
                        label="Group Member"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        onClick={handleMemberChecked}
                      />
                    </Col>
                  </Row>
                </Container>
                <div style={{ display: 
                  visibleForm 
                }}>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="First Name" name="name" onChange={handlefirstNameChange}/>
                  </div>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="Last Name" name="name" onChange={handleLastNameChange} />
                  </div>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="Email" name="name" onChange={handleEmailChange} />
                  </div>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="Password" name="name" onChange={handlePasswordChange}/>
                  </div>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="Conform Password" name="name" />
                  </div>
                  <div className="input-group">
                    <input className="input--style-2" type="text" placeholder="Phone Number" name="name" onChange={handlePhoneNumberChange}/>
                  </div>
                  <div className="input-group" style={{ display: display }}>
                    <input className="input--style-2" type="text" placeholder="code" name="name" />
                  </div>

                  <div  className="p-t-30">
                    <button className="btn btn--radius btn--green" onClick={handleRegistrationButtonClicked}> 
                     {isBusy?  
          (<Spinner animation="border" variant="warning" />) :'Register'}</button>
                  </div>
                </div>
              </form>


                <div className=" group" style={{ display: 
                  visibleAdminPackage 
                }}>

                  <RegisterPackageContainer/> */} 
                  {/* <Row>
                    <Col>
       <div className="grid-1-5"> 
          <h2>Basic</h2>
          <h3><span className="uppercase">Free</span></h3>
          <p>10,000 monthly visits</p>
          <ul>
            <li>Limited Email Support</li>
            <li>Unlimited Data Transfer</li>
            <li>10GB Local Storage</li>
          </ul>
          <a href="#" className="button" >Buy</a>
        </div>
        </Col>
        <Col>
        <div className="grid-1-5">
          <h2>Startup</h2>
          <h3><sup>$</sup>79<span className="small">/mo</span></h3>
          <p>25,000 monthly visits</p>
          <ul>
            <li>Limited Email Support</li>
            <li>Unlimited Data Transfer</li>
            <li>20GB Local Storage</li>
          </ul>       
          <a href="#" className="button">Buy</a>   
        </div>
        </Col>
        <Col>
        <div className="grid-1-5">
          <h2>Growth</h2>
          <h3><sup>$</sup>179<span className="small">/mo</span></h3>
          <p>75,000 monthly visits</p>
          <ul>
            <li>Email Support</li>
            <li>Unlimited Data Transfer</li>
            <li>30GB Local Storage</li>
          </ul>   
          <a href="#" className="button">Buy</a>       
        </div>
        </Col>
        </Row> */}
      {/* </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default withRouter( Registration);