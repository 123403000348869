import React, { useEffect } from 'react';
import { Modal, Result } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
// import  '../myStyle/TransactionSuccess.css';
// require ('../myStyle/TransactionSuccess.css');
import axios from 'axios';
import AuthService from '../_services/AuthService';
import { AppUrls } from '../_environments/environment';
import { useCartDispatcher, useCartContext, getCartItemsList } from '../action/CartAction';
import { CartDataRequestModel } from '../api';
import { useUserDispatcher, useUserContext, getUserListResult } from '../action/UserAction';

export interface IITransactionStatusParams {
  id?: string;
}

interface ITransactionStatusProps extends RouteComponentProps<IITransactionStatusParams> {
}

interface ITransactionStatusState {
  isOpen: boolean;
  visible: boolean;
  isPurchase:boolean;
  PurchaseId:string;
  onLoad:boolean;
  isPurchaseItem:boolean;
  PurchaseItems:any[],
  isUserLoad:boolean,
}

const initialState = {
  isOpen: false,
  visible: true,
  isPurchase:false,
  PurchaseId:"",
  onLoad:true,
  isPurchaseItem:false,
  PurchaseItems:[],
  isUserLoad:false,
}

const TransactionStatus: React.FC<ITransactionStatusProps> = (props) => {

  let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";


  const [transactionStatusState,
    setTransactionStatusState] = React.useState<ITransactionStatusState>(initialState)
  const { isOpen, visible,PurchaseId,isPurchase,onLoad,isPurchaseItem,isUserLoad,PurchaseItems } = transactionStatusState
  
  const userDispatcher = useUserDispatcher();
  const userContext = useUserContext();
  const { users } = userContext

  const cartDispatcher = useCartDispatcher();
  const cartContext = useCartContext();
  const { cartItems } = cartContext

  useEffect(() => {
    setTransactionStatusState({
      ...transactionStatusState,
      isOpen: true,
    });
  }, []);

  React.useEffect(() => {
    (async () => {
        await getUserListResult(userDispatcher)
        setTransactionStatusState({
            ...transactionStatusState,
            isUserLoad:true,
        });
    })();
}, [authorizationToken])

  const { id } = props.match.params;
  var str = id;
  var data = str?.split('-');

  

  React.useEffect(() => {
    if(isUserLoad){
     (async () => {   
         await getCartItemsList(cartDispatcher,{currencyId:users.defaultCurrencyId} as CartDataRequestModel );
    
        
      })();
    }
 }, [isUserLoad])


  useEffect(() => {
    if(data != undefined && data[5] === "Success" && onLoad==true){
     
    setTransactionStatusState({
      ...transactionStatusState,
      isPurchase: true,
      onLoad:false
    });
  }
  }, [data]);

  React.useEffect(() => {
    if (isPurchase) {
        (async () => {
             let url_ = AppUrls.PurchasreSave;
             url_ = url_.replace(/[?&]$/, "");
            let headers = {
                                    Authorization: `Bearer ${authorizationToken}`,
                                    'Content-type': 'application/json'
                  };

              
            let formData = new FormData();
       
            
            axios.post(url_, JSON.stringify({id:"",UserId:"",Host:"",Location:"",JournalMasterId:"",Active:true}), { headers: headers }).then(res => {
                console.log(1576, res); 
                if(res.data){ 
                  setTransactionStatusState({
                    ...transactionStatusState,
                    isPurchase: false,
                    isPurchaseItem:true,
                    PurchaseId:res.data.EntityId
                  });
                   
                }   
            })
        })();
    }
}, [isPurchase])

React.useEffect(() => {
  if (isPurchaseItem) {
      (async () => {
           let url_ = AppUrls.PurchasreSave;
           url_ = url_.replace(/[?&]$/, "");
          let headers = {
                                  Authorization: `Bearer ${authorizationToken}`,
                                  'Content-type': 'application/json'
                };

            
          let formData = new FormData();
     const postData: { Id: string; PurchaseId: string; AudioId: string | undefined; Amount: number; TaxCategoryId: string; TaxPercentage: number; Tax: number; PromotionItemId: string; PromotionalDiscount: number; NetAmount: number; Active: boolean; }[]=[];
cartItems.searchResult?.data?.map((cartItem,index)=>{
  postData.push({
    Id:"",
    PurchaseId:PurchaseId,
    AudioId:cartItem.audioId,
    Amount:cartItem.offerPrice!==0?cartItem.realPrice:cartItem.offerPrice,
    TaxCategoryId:"",
    TaxPercentage:0,
    Tax: 0,
    PromotionItemId:"" ,
    PromotionalDiscount: 0,
    NetAmount: 0,
    Active: true
  })
})
          
          axios.post(url_, JSON.stringify(postData), { headers: headers }).then(res => {
              console.log(1576, res); 
              if(res.data){ 
                setTransactionStatusState({
                  ...transactionStatusState,
                  
                  isPurchaseItem:false,
                  
                });
                 
              }   
          })
      })();
  }
}, [isPurchaseItem])

  const onOkClick = () => {
    setTransactionStatusState({
      ...transactionStatusState,
      visible: false
    });
    props.history.push('/');
  }

  const onSuccess = () => {
    // setTransactionStatusState({
    //   ...transactionStatusState,
    //   isPurchase: true,
    // });
    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        closable={false}
      //    width={0}
      //    style={{marginLeft:480}}
      >


<div id="myModal" className="modaldata ">
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header">
              <div className="icon-box">
                <i className="fa fa-check" aria-hidden="true" />
              </div>
              <h4 className="modal-title">Successful!</h4>
            </div>
            <div className="modal-body">
            <p className="text-center">Thank you, your payment was successful.</p>
                  <p className="text-center">Your Order NO: <span>{data != undefined ? data[1] : null}</span></p>
                  <p className="text-center">Transaction ID:<span>{data != undefined ? data[3] : null}</span></p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-success btn-block" onClick={onOkClick} data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>



        {/* <div>


          <div id="myModal"

          >
            <div
              className="modal-dialog modal-confirm"
            >

              <div className="modal-content">
                <div className="modal-header">

                  <h4 className="modal-title">Successful!</h4>
                </div>
                <Result
                  status="success"
                />
                <div className="modal-body">
                  <p className="text-center">Thank you, your payment was successful.</p>
                  <p className="text-center">Your Order NO: <span>{data != undefined ? data[1] : null}</span></p>
                  <p className="text-center">Transaction ID:<span>{data != undefined ? data[3] : null}</span></p>
                </div>

                <div className="modal-footer">
                  <button className="btn btn-success btn-block" onClick={onOkClick} data-dismiss="modal">OK</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Modal>
    )
  }

  const onFalse = () => {
    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        closable={false}
      //    width={0}
      //    style={{marginLeft:480}}
      >
       <div id="myModal" className="modaldata ">
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header">
              <div className="icon-box">
                <i className="fa fa-times-circle" aria-hidden="true" />
              </div>				
              <h4 className="modal-title">Unsuccessful!</h4>	
            </div>
            <div className="modal-body">
              <p className="text-center">Your Transaction Is Unsuccessful.</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-success btn-block" onClick={onOkClick} data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
      </Modal>
    )
  }

  return (
    <>

      {data != undefined ? data[5] === "Success" ? onSuccess() : onFalse() : null}
    </>
  );
};

export default withRouter(TransactionStatus);