import React from 'react';
import { Purchase } from '../_models/Purchase';

interface IPurchasedBookComponentProps{
    purchaseData:Purchase
}

const PurchasedBookComponent:React.FC<IPurchasedBookComponentProps>= (props) => {
    return (
        <div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
        <div className="tg-postbook">
          <figure className="tg-featureimg">
          {/* <Link to={`/book_details/${book.audioId}`}> */}
            <div className="tg-bookimg">
              <div  className="tg-frontcover"><img src=''  alt="image description" /></div>
              <div  className="tg-backcover"><img src=""  alt="image description" /></div>
            </div>
            {/* </Link> */}
            {/* <a className="tg-btnaddtowishlist" onClick={OnHandleAddtoWishList} href="javascript:void(0);">
              <i className="icon-heart" />
              <span>add to wishlist</span>
            </a> */}
          </figure>
          <div className="tg-postbookcontent">
            <ul className="tg-bookscategories">
              <li><a href="javascript:void(0);">Art &amp; Photography</a>
              </li>
            </ul>
            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
            <div className="tg-booktitle">
              <h3><a href="javascript:void(0);">title</a></h3>
            </div>
            <span className="tg-bookwriter">By: <a href="javascript:void(0);">Angela Gunning</a></span>
            {/* <span > {starRating(book.totalStarRate)}<span /></span> */}
            <span className="tg-bookprice">
              <ins>$25.18</ins>
              <del>$27.20</del>
            </span>
            {/* <a className="tg-btn tg-btnstyletwo" onClick={OnHandleAddtoCart  } href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em>
            </a> */}
          </div>
        </div>
      </div>
    );
};

export default PurchasedBookComponent;