import { Reducer } from "react"
import { ICartManagementState, CartDispatcherContext, CartStateContext } from "../context/CartContext"
import React from "react";
import {   CartClient, CartDataRequestModel, CartResult, CartPostModel } from "../api";
import { AppUrls } from "../_environments/environment";
import axios from 'axios';
import AuthService from "../_services/AuthService";
import { message } from "antd";

const key = 'updatable';
let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 

export type ICartAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_HOME_PRODUCT_LIST",
    cartItems:CartResult 
}




export const cartReducer: Reducer<ICartManagementState, ICartAction> = (draft, action): ICartManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.cartItems=action.cartItems;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<ICartAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<ICartAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useCartDispatcher = (): React.Dispatch<ICartAction> => {
    const cartDispatcher = React.useContext(CartDispatcherContext);
    if (!cartDispatcher) {
        throw new Error("You have to provide the Cart dispatcher using theCartDispatcherContext.Provider in a parent component.");
    }
    return cartDispatcher;
}


export const useCartContext = (): ICartManagementState => {
    const cartContext = React.useContext(CartStateContext);
    if (!cartContext) throw new Error("You have to provide the cart context using the CartStateContext.Provider in a parent component.");
    return cartContext;
}



const cartApi = new CartClient();

export const getCartItemsList = async (dispatcher: React.Dispatch<ICartAction>, query:CartDataRequestModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        let header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                       "Authorization":`Bearer ${authorizationToken}`,
                    };
    
                            const url=AppUrls.GetCartItems
    
                    axios.post(url, JSON.stringify( 
                        query
                    
                     ),{ headers: header })
                    .then(res => {
                        dispatcher({ type: "SET_HOME_PRODUCT_LIST", cartItems: res.data });
                    })
    

        // const cartItems = await cartApi.getCartItems( query)
     

    }
    catch(e){

    }
}

export const deleteCartItem = async (dispatcher: React.Dispatch<ICartAction>, query:CartPostModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const cartItems = await cartApi.delete( query)
        console.log(711,cartItems)
    //    dispatcher({ type: "SET_HOME_PRODUCT_LIST", cartItems: cartItems });
if(cartItems){
    setTimeout(() => {
        message.success({ content:cartItems.message, key, duration: 2 });
    }, 1000);
}
    }
    catch(e){

    }
}

export const addToCartList = async (dispatcher: React.Dispatch<ICartAction>, query:CartPostModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const cartItems = await cartApi.addToCart(query)
        console.log(55,)
        dispatcher({ type: "SET_HOME_PRODUCT_LIST", cartItems: cartItems });

    }
    catch(e){

    }
}


