import React, { Fragment } from 'react';

import { Icon, Row, Col, Modal, Empty, Button, Tabs } from 'antd';
import Spinner from 'react-bootstrap/Spinner'
import { ProductReviewsDataResultModel, CartPostModel, ProductShortInfo } from '../api';
import { useCartDispatcher, useCartContext, addToCartList } from '../action/CartAction';
import RatingContainer from '../rating/RatingContainer';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import AuthService from '../_services/AuthService';
const { TabPane } = Tabs;
// import { Tabs, Tab } from 'react-bootstrap';
console.log("Inside BookDetailsComponent");
let authorizationToken = AuthService.accessToken;


interface IBookDetailsComponentProps {
  bookDetails: any
  reviews?: ProductReviewsDataResultModel | undefined
  OnHandleAddtoCart: () => void;
  OnHandleAddtoWishList: () => void;
  OnHandleLoadReview: () => void;
  wishLists: boolean,
  data: ProductShortInfo[] | undefined
}

interface IBookDetailsComponentState {
  visible: boolean;
  refresh: boolean;
}

const initialState = {
  visible: false,
  refresh: false
} as IBookDetailsComponentState

const BookDetailsComponent: React.FC<IBookDetailsComponentProps> = (props) => {
  const { bookDetails, reviews, OnHandleAddtoCart, OnHandleAddtoWishList, OnHandleLoadReview, wishLists, data } = props;

  const [bookDetailsComponentState, setBookDetailsComponentState] = React.useState(initialState)
  const { visible } = bookDetailsComponentState
  console.log(554, data)
  console.log(555, data?.length === 0 ? 'true' : 'false')
  const cartDispatcher = useCartDispatcher();
  const cartContext = useCartContext();
  const { cartItems } = cartContext

  const starRating = (rating: number) => {

    let str = [];
    for (let i = 1, j = 2; i <= 5; i++, j++) {

      if (rating > i && rating < j) {
        str[i] = <i className="fa fa-star-half-o" aria-hidden="true" />

      }
      else if (rating >= i) {
        str[i] = <i className="fa fa-star" aria-hidden="true" />

      }
      else {
        str[i] = <i className="fa fa-star-o" aria-hidden="true" />

      }


    }

    return str;
  }

  const handleAddToCart = (e: any) => {
    e.preventDefault();
    OnHandleAddtoCart();
    //  addToCartList(cartDispatcher, { AudioId: "e003d33f-b784-47be-b7bc-d040a17e7bd7"} as {} as CartPostModel)

  }

  const handleAddToWishList = (e: any) => {
    e.preventDefault();
    OnHandleAddtoWishList();
  }


  const showModal = () => {
    setBookDetailsComponentState({
      ...bookDetailsComponentState,
      visible: true,
    });
  };

  const handleOk = () => {
    console.log(4455);
    setBookDetailsComponentState({
      ...bookDetailsComponentState,
      visible: false,
    });
  };

  const handleCancel = (e: any) => {
    console.log(e);
    setBookDetailsComponentState({
      ...bookDetailsComponentState,
      visible: false,
    });
  };

  return (
    <>


      {bookDetails != undefined ? (
        
        <div className="container">
          <div className="row">
            <div id="tg-twocolumns" className="tg-twocolumns">
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12 pull-left">
                <div id="tg-content" className="tg-content">
                  <div className="tg-productdetail">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <div className="tg-postbook">
                          <figure className="tg-featureimg"><img src={bookDetails.bookImages[0].filename} alt="image description" /></figure>
                          <div className="tg-postbookcontent">
                            <span className="tg-bookprice">
                              <ins>
                                {bookDetails.offerPrice == 0 ? (<>{bookDetails.currencySymbol}  {bookDetails.realPrice}</>) : (<>
                                  <s style={{ color: 'sandybrown' }}>{bookDetails.currencySymbol}  {bookDetails.realPrice}</s> &nbsp;
                      {bookDetails.currencySymbol}  {bookDetails.offerPrice}
                                </>
                                )}
                              </ins>
                              <del>$27.20</del>
                            </span>
                            <span className="tg-bookwriter">You save $4.02</span>


                            <div className="audio-button" style={{ marginTop: '20px', marginLeft: '15px' }}>


                              {bookDetails.audioFiles.map((audioFile: any, index: any) => {
                                return (
                                  <>
                                    {audioFile.audioTypeId == 1 ? (

                                      <>

                                        <h6 style={{ color: "orange", marginBottom: 10, marginLeft: 20 }}>Sample Audio</h6>
                                        <audio controls style={{marginLeft:-33}}>
                                          <source src={audioFile.audioUrl} type="audio/mpeg" />
                                        </audio>
                                      </>

                                    ) : null}
                                  </>
                                )

                              })}

                            </div>


                            <a className="tg-btn tg-active tg-btn-lg" onClick={handleAddToCart}>Add To Basket</a>
                            <a className="tg-btnaddtowishlist" href="javascript:void(0);">
                              <span onClick={handleAddToWishList}>{data?.length == 0 ? 'Add to wishList' : 'Remove from wishList'}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <div className="tg-productcontent">
                          <ul className="tg-bookscategories">
                            <li><a href="javascript:void(0);">Art &amp; Photography</a></li>
                          </ul>
                          <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
                          <div className="tg-booktitle">
                            <h3>{bookDetails.title}</h3>
                          </div>
                          <span className="tg-bookwriter"><b>Written By:</b> <a href="javascript:void(0);">
                            {bookDetails.authors.map((author: any, index: any) => {
                              return (
                                <span>{author.caption}</span>
                              )
                            })}
                          </a></span>
                          <span className="tg-bookwriter"><b>Narrated By:</b> <a href="javascript:void(0);">
                            {bookDetails.narrators.map((narrator: any, index: any) => {
                              return (
                                <span>{narrator.caption}</span>
                              )
                            })}
                          </a></span>
                          <span className="tg-bookwriter"><b>Published By:</b> <a href="javascript:void(0);">
                            {bookDetails.publisher.caption}
                          </a></span>
                          <span className="tg-bookwriter"><b>Duration:</b> <a href="javascript:void(0);">{bookDetails.length}</a></span>


                          <div className="tg-productdescription">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="tg-sectionhead">
                                <h2>Product Description</h2>
                              </div>
                              <div className="tg-tab-content tab-content">




                                <Tabs defaultActiveKey="1" >
                                  <TabPane tab="Description" key="1">
                                    <p>{bookDetails.shortDescription}</p>
                                    <br /> <p >{bookDetails.fullDescription}</p>

                                  </TabPane>
                                  <TabPane tab="Reviews" key="2">
                                    <button onClick={showModal} style={{ backgroundColor: '#eb4800', color: 'white', borderRadius: '4px', border: 'none', lineHeight: '40px', padding: '0px 25px', marginBottom: 20 }}><Icon type="plus-circle" style={{ color: 'white', verticalAlign: 0 }} /> ADD REVIEW</button>

                                    <RatingContainer
                                      visible={visible}
                                      handleOk={handleOk}
                                      handleCancel={handleCancel}
                                      OnHandleLoadReview={OnHandleLoadReview}
                                    />
                                    {reviews && reviews.data && reviews.data.map((review: any, index: any) => {
                                      return (
                                        <>
                                          <Row style={{ marginBottom: 15 }}>
                                            <Col span={8}>
                                              <span>{review.userName}</span><br />
                                              <span>{starRating(review.starRate)}</span>

                                            </Col>
                                            <Col span={4}>


                                            </Col>
                                            <Col span={12}>
                                              <span>{review.title}</span><br />
                                              <span>{review.description}</span>
                                            </Col>

                                          </Row>
                                        </>

                                      )
                                    })
                                    }

                                  </TabPane>

                                </Tabs>


                              </div>


                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : <>
          <div style={{ marginLeft: 600, marginTop: 70 }}>

            <h4>Loding...</h4>
          </div>


        </>}

    </>
  );


};

export default BookDetailsComponent;