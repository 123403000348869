import { Reducer } from "react"
import { IAboutUsManagementState, AboutUsDispatcherContext, AboutUsStateContext } from "../context/AboutUsContext"
import React from "react";
import {  SettingsClient, AboutUsResultViewModel } from "../api";


export type IAboutUsAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_HOME_PRODUCT_LIST",
    aboutUs:AboutUsResultViewModel 
}




export const aboutUsReducer: Reducer<IAboutUsManagementState, IAboutUsAction> = (draft, action): IAboutUsManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.aboutUs=action.aboutUs;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IAboutUsAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<IAboutUsAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useAboutUsDispatcher = (): React.Dispatch<IAboutUsAction> => {
    const aboutUsDispatcher = React.useContext(AboutUsDispatcherContext);
    if (!aboutUsDispatcher) {
        throw new Error("You have to provide the AboutUs dispatcher using theAboutUsDispatcherContext.Provider in a parent component.");
    }
    return aboutUsDispatcher;
}


export const useAboutUsContext = (): IAboutUsManagementState => {
    const aboutUsContext = React.useContext(AboutUsStateContext);
    if (!aboutUsContext) throw new Error("You have to provide the aboutUs context using the AboutUsStateContext.Provider in a parent component.");
    return aboutUsContext;
}



const settingApi = new SettingsClient();

export const getAboutUs = async (dispatcher: React.Dispatch<IAboutUsAction>,  ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const aboutUs = await settingApi.get()
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", aboutUs: aboutUs });

    }
    catch(e){

    }
}



