import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { IAboutUsAction, aboutUsReducer } from "../action/AboutUsAction";
import { useImmerReducer } from "use-immer";
import {  AboutUsResultViewModel } from "../api/controller";
export const AboutUsDispatcherContext = React.createContext<React.Dispatch<IAboutUsAction> | null>(null);
export const AboutUsStateContext = React.createContext<IAboutUsManagementState | null>(null);

export interface IAboutUsManagementProps extends RouteComponentProps<any> { }

export interface IAboutUsManagementState {
    visible:boolean;
    value:number;
    aboutUs:AboutUsResultViewModel
}

export const initialAboutUsManagementState = {
    visible:false,
    value:0,
    aboutUs:{} as AboutUsResultViewModel,
} as IAboutUsManagementState;

export const AboutUsContextProvider: React.FC = ({ children }) => {
    const [aboutUsState, dispatcher] = useImmerReducer(aboutUsReducer, initialAboutUsManagementState);

    return (
        <AboutUsDispatcherContext.Provider value={dispatcher}>
            <AboutUsStateContext.Provider value={aboutUsState}>
                {children}
            </AboutUsStateContext.Provider>
        </AboutUsDispatcherContext.Provider>
    )
}