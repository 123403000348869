import { Reducer } from "react"
import { IUserManagementState, UserDispatcherContext, UserStateContext } from "../context/UserContext"
import React from "react";
import { ProductAPIClient, AccountClient, UserInfoViewModel } from "../api";


export type IUserAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_USER_LIST",
    users:UserInfoViewModel 
}




export const userReducer: Reducer<IUserManagementState, IUserAction> = (draft, action): IUserManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_USER_LIST":
                draft.users=action.users;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IUserAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<IUserAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useUserDispatcher = (): React.Dispatch<IUserAction> => {
    const userDispatcher = React.useContext(UserDispatcherContext);
    if (!userDispatcher) {
        throw new Error("You have to provide the User dispatcher using theUserDispatcherContext.Provider in a parent component.");
    }
    return userDispatcher;
}


export const useUserContext = (): IUserManagementState => {
    const userContext = React.useContext(UserStateContext);
    if (!userContext) throw new Error("You have to provide the user context using the UserStateContext.Provider in a parent component.");
    return userContext;
}



const accountApi = new AccountClient();

export const getUserListResult = async (dispatcher: React.Dispatch<IUserAction> ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const users = await accountApi.getUserBasicDetails( )
       dispatcher({ type: "SET_USER_LIST", users: users });
       

    }
    catch(e){

    }
}



