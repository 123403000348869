import React from 'react';

const ServiceAndCondition = () => {
    return (

<>





<section className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-aboutus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Vocabooks - Terms and Conditions for Recurring Payments for auto renewal of Vocabooks Membership plans</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Recurring Payments</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Eligible Payment Methods</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Enable Recurring Payment</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Membership</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Disable / Cancel Recurring Payments</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Notification and Communication</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <h4>Disclaimer of Liability</h4>
                  </div>
                  <div className="tg-description">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>







        {/* <div  style={{paddingLeft:120,paddingRight:120,paddingTop:60,textAlign:"justify"}}>

          <h4 style={{marginBottom:7}}> Vocabooks India Service Conditions of Use</h4>

This is an agreement between you and Amma Communications Private Limited (with its affiliates, "Vocabooks", "we" or "us")
 regarding the digital spoken word audio entertainment service operated by Vocabooks (the "Service"). These Vocabooks 
 Service Conditions of Use, the rules, policies and guidance we provide regarding the Service, the Terms & Conditions
  for Recurring Payments (all as they may be changed over time) are collectively referred to as the "Terms", form part 
  of this agreement, and govern your use of the Service. Vocabooks is an affiliate of Amma Communications Private Limited. <br/><br/>

<h4 style={{marginBottom:7}}>By signing up for or using the Service, you agree to the Terms. </h4><br/><br/>

<h4 style={{marginBottom:7}}>Your Account</h4>
If you do not have a Vocabooks account, you will need to establish an account with Vocabooks to use the Service.<br/><br/>

<h4 style={{marginBottom:7}}>Membership Plans</h4>

As part of our Service, we may offer membership plans and may change them and their features from time to time.
 If you sign up for a membership plan, you agree to the terms, conditions and limitations associated with them that
  are posted on our website(s) or applications.<br/><br/>

<h4 style={{marginBottom:7}}>Credits</h4>
As part of a membership plan or a Service promotion, we may issue you credits redeemable for content on the Service
 ("Credits"). Credits may only be redeemed on the Service, have no cash value, are non-transferrable and non-refundable.
  All Credits are valid for a limited time as described in the applicable membership and promotional terms and conditions.
   Credits expire immediately upon the cancellation or termination of your membership unless used prior to cancellation
   or termination.<br/><br/>

<h4 style={{marginBottom:7}}>Purchased Content</h4>
Content you purchase will be available in your Vocabooks library for you to access at your convenience. We encourage
 you to download a copy of your purchased content promptly after the completion of your purchase.<br/><br/>

<h4 style={{marginBottom:7}}>Promotional and Membership Content</h4>

We may make content available to you from time to time through the Service for which a purchase is not required. 
You will have access to this content only for so long as we make it available generally or, where it is provided as
 a benefit of a membership plan, for so long as you remain an active member of your plan in good standing. We may 
 also remove this content from your account, devices and applications or otherwise restrict your ability to access it.<br/><br/>

<h4 style={{marginBottom:7}}>Content Availability</h4>

We may add or remove purchasable, promotional and membership content (collectively, "Content") from our catalog, 
membership plans and our Service at any time and make no guarantee as to the availability of specific Content in 
any membership plan or as to any minimum amount of Content in any membership plan. Some of our Content may be offered
 in limited territories, and we may use geofiltering technology to restrict access outside of those territories.<br/><br/>

<h4 style={{marginBottom:7}}>Fees and Renewal</h4>
Fees for purchased content, membership plans, subscriptions and other services will be stated at the time of your
 purchase or sign-up, as applicable, and provided in our help pages. The fees for membership plans may change at 
 any time. Fees may be subject to tax, collected by us or a third party through which you transact, and are 
 non-refundable except in the specific circumstances described in the Terms. <br/><br/>

We accept a variety of payment methods depending on the type of membership plan you choose. You cannot use a credit
 or debit card issued outside of India to pay on Vocabooks.com. If you sign up for an auto-renewing membership plan,
  your Vocabooks membership will automatically renew at the end of each period and we or the third party through
   which you transact will charge you the membership fees at the time of renewal (“Recurring Payments”). By signing 
   up for an auto-renewing plan you authorize us, or a third party through which you transact to collect the membership
    fee for the next membership period and agree to the Terms & Conditions for Recurring Payments. <br/><br/>

You may disable automatic renewal of your Vocabooks membership by contacting Vocabooks customer support or by visiting
 Account Details. If you signed up for your Vocabooks membership using the Vocabooks iOS mobile application, please 
 visit this page for information on how to cancel the membership.<br/><br/>

Vocabooks membership only becomes effective upon successful authorization of payment or successfully enabling Recurring
 Payments. If the payment methods provided by you are declined for payment of your membership or subscription fees or 
 you do not have a valid payment method on file on your renewal date, we may at our option, limit access to 
 non-purchased content and membership benefits and/or cancel your membership or subscription, as applicable,
  unless you provide us with a valid payment method. If you provide us with a valid payment method and are successfully
   charged before your membership or subscription is cancelled, your new membership or subscription period will be
    based on the original renewal date and not the date of the successful charge.<br/><br/>

<h4 style={{marginBottom:7}}>Membership and Subscription Cancellation</h4>

You may cancel your membership plan and any subscriptions by visiting your Account Details page provided on 
Vocabooks.com (your "Account Details") and adjusting your membership settings or subscription preferences 
(as applicable), or by contacting our Customer Service team. If you signed up for your Vocabooks membership
 using the Vocabooks iOS mobile application, please visit this page for information on how to cancel the membership.
  If you cancel your membership or subscription, you will not receive a refund of any fees already paid. Your member 
  benefits terminate when your membership is cancelled, but cancellation does not terminate your license and access 
  to purchased content.<br/><br/>

<h4 style={{marginBottom:7}}>Service Restrictions</h4>

We reserve the right to accept or refuse membership or to restrict use of the Service in our discretion. You may not 
transfer or assign your membership or any Service benefits. We may take actions we deem reasonably necessary to prevent
 fraud and abuse, including placing restrictions on the amount of content or other services that can be accessed from 
 the Service at any one time.<br/><br/>

<h4 style={{marginBottom:7}}>Content Restrictions</h4>

You may not (i) transfer, copy or display Content, except as permitted in this Agreement; (ii) sell, rent, lease, 
distribute, or broadcast any Content; (iii) remove any proprietary notices or labels on Content; (iv) attempt to 
disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or other protection system 
applied to Content or used as part of the Service; or (v) use the Service or Content for any commercial or illegal 
purpose.<br/><br/>

<h4 style={{marginBottom:7}}>Promotional Trial Memberships</h4>

We sometimes offer certain customers various trial or other promotional memberships, which are subject to the Terms 
except as otherwise stated in the promotional offers. We reserve the right, in our sole discretion, to determine your 
eligibility. Trial members may, at any time (through your Account Details), choose not to continue the paid membership 
by cancelling prior to the end of the trial period. If a trial offer or a promotion requires you to have a valid payment
 instrument on file, such as a credit card or other permitted payment method, we may validate that payment method 
 including by requesting a temporary authorization from the financial institution issuing your payment instrument. 
 If we determine that your payment instrument is invalid, without limiting any of our other rights, we may revoke any 
 benefits, Credits or Content you may have received as part of the offer.<br/><br/>

<h4 style={{marginBottom:7}}>Notice and Procedure for Making Claims of Copyright Infringement</h4>

If you believe that your intellectual property rights have been infringed, please submit your claim using the online 
form and process set forth in the Vocabooks.com Conditions of Use. <br/><br/>

We offer the following alternative to the online form for copyright complaints only. You may submit written claims of 
copyright infringement to our Copyright Agent at:<br/><br/>

<h5>Copyright Agent</h5><br/>

<h5>Vocabooks Legal Department Address</h5><br/>

<h5>e-mail: copyright@vocabooks.com</h5> <br/><br/>

<h4 style={{marginBottom:7}}>Agreement Changes </h4>

We may in our discretion change the Terms and all elements of them and any aspect of the Service at any time. If any
 change to the Terms is found invalid, void, or for any reason unenforceable, that change is severable and does not 
 affect the validity and enforceability of any remaining changes and the remainder of the Terms. Your continued use of
  the Service after we change the Terms constitutes your acceptance of the changes. If you do not agree to any changes,
   you must not use the Service and must cancel your membership. <br/><br/>

<h4 style={{marginBottom:7}}>Termination by Us</h4> 

Our business may change over time and we reserve the right to cancel the Service and any membership plan in whole or in
 part, and to terminate your membership and use of the Service at our discretion. If we do so, we will give you a 
 prorated refund based on the number of days remaining in your membership unless we terminate your membership for 
 conduct that we determine, in our discretion, violates the Terms, violates any applicable law, involves fraud or 
 misuse of the Service, or is harmful to our interests or another user. Our failure to insist upon or enforce your strict 
 compliance with the Terms will not constitute a waiver of any of our rights.<br/><br/>


Disputes; Limitation of Liability 
Any dispute or claim arising from or relating to the Terms or your use of the Service is subject to the governing 
law, jurisdiction, disclaimer of warranties and limitation of liability and all other terms in the Vocabooks.com.
 You agree to those terms by signing up for Vocabooks. 
Our total liability to you under the Terms will not exceed the total amount you have paid us in the twelve months 
prior to the claim.<br/><br/>
 
        </div>
         */}
   </>     
    );
};

export default ServiceAndCondition;