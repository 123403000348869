import React from 'react';
import { PackageInfo } from '../_models/PackageList';

interface IPackageAndOffersDetails{
    onHandleBackButtonClick:()=>void;
    keyValue:number,
    packages: PackageInfo[],
}

const PackageAndOffersDetails:React.FC<IPackageAndOffersDetails>=(props) => {

    const {onHandleBackButtonClick,keyValue,packages}=props;

const handleBackButtonClick=()=>{
    onHandleBackButtonClick();
}

    return (
        <>
           
           {packages.map((packageData, index) => {

if(index==keyValue){
                  return (
                    <>
                    
                    <div className="row">
      {/* Blog Post Content Column */}
      <div className="col-lg-12">
        <hr />
        <h2>{packageData.name}</h2>
        <hr />
        {/* Preview Image */}
        {/* <img className="img-responsive" src={require("../images/banner-bg.jpg")} alt="" /> */}
        <hr />
        {/* Post Content */}
        <p className="lead">{packageData.description}</p>
        <hr />
      </div>
      <a onClick={handleBackButtonClick} >  Go Back</a>
    </div>
                    
                    </>)}})}
        </>
    );
};

export default PackageAndOffersDetails;