import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";

import { useImmerReducer } from "use-immer";
import { wishListReducer, IWishListAction } from "../action/WishLIstAction";
import { ProductSearchResult } from "../api";

export const WishListDispatcherContext = React.createContext<React.Dispatch<IWishListAction> | null>(null);
export const WishListStateContext = React.createContext<IWishListManagementState | null>(null);

export interface IWishListManagementProps extends RouteComponentProps<any> { }

export interface IWishListManagementState {
    visible:boolean;
    value:number;
    wishListTerm:string;
    wishListResult:ProductSearchResult
}

export const initialWishListManagementState = {
    visible:false,
    value:0,
    wishListTerm:"",
    wishListResult:{} as ProductSearchResult,
} as IWishListManagementState;

export const WishListContextProvider: React.FC = ({ children }) => {
    const [wishListState, dispatcher] = useImmerReducer(wishListReducer, initialWishListManagementState);

    return (
        <WishListDispatcherContext.Provider value={dispatcher}>
            <WishListStateContext.Provider value={wishListState}>
                {children}
            </WishListStateContext.Provider>
        </WishListDispatcherContext.Provider>
    )
}