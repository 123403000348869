import React from 'react';
import { ProductCategory } from '../../../api';
import FrontPageBookComponenet from '../../FrontPageBookComponenet';

interface IHomeBookPageCategoryViewComponentProps{
content:ProductCategory[];
onHandleCartLoad:()=>void;
onHandleWishListLoad:()=>void;
}

const HomeBookPageCategoryViewComponent:React.FC<IHomeBookPageCategoryViewComponentProps>=(props) => {
const {content,onHandleCartLoad,onHandleWishListLoad}=props;
console.log(9999,content);
    return (
        <>

<div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div id="tg-twocolumns" className="tg-twocolumns">
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12 pull-left">
                <div id="tg-content" className="tg-content">
                  <div className="tg-products">
                  <div className="tg-sectionhead">
        <h2>{content[0].title}</h2>
      </div>

      <div className="row">
     

{/* <section className="best_seller">
                
                    <div className=" container">
                        
                            <h1 style={{marginLeft:469}} className="pull-left">{content[0].title}</h1><br/><br/><br/> */}

                            {
                            content[0].products!=undefined ?(content[0].products.map((products, index) => {
    return (
        <>
            <FrontPageBookComponenet
                key={`product_${products.audioId}`}
                book={products}
                onHandleCartLoad={onHandleCartLoad}
                onHandleWishListLoad={onHandleWishListLoad}
            />
        </>
    )
})):null
} 

</div>
</div></div></div></div></div></div></div>
                    {/* </div>
              
            </section> */}
        </>
    );
};

export default HomeBookPageCategoryViewComponent;