import React from 'react';
import { Link } from 'react-router-dom';
import { ProductSearchResult } from '../api';

interface IWishLIstComponenetProps {
    wishListResult: ProductSearchResult,
    OnHandleAddtoCart:(id:any)=>void;
    OnHandleAddtoWishList:(id:any)=>void;
}

const WishLIstComponenet: React.FC<IWishLIstComponenetProps> = (props) => {

    
    const starRating = (rating: number) => {

        let str = [];
        for (let i = 1, j = 2; i <= 5; i++ , j++) {

            if (rating > i && rating < j) {
                str[i] = <i className="fa fa-star-half-o" aria-hidden="true" />

            }
            else if (rating >= i) {
                str[i] = <i className="fa fa-star" aria-hidden="true" />

            }
            else {
                str[i] = <i className="fa fa-star-o" aria-hidden="true" />

            }


        }

        return str;
    }



    const { wishListResult,OnHandleAddtoCart,OnHandleAddtoWishList } = props;

    if (!wishListResult || !wishListResult.searchResult || !wishListResult.searchResult.data || wishListResult.searchResult.data.length <= 0) {
        return (
            <>

            </>
        );
    }

    console.log(678,wishListResult.searchResult.data);
    return (
        <>

{wishListResult.searchResult.data.map((wishList, index) => {
                                    return (
                                        <>


                      <div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                        <div className="tg-postbook">
                          <figure className="tg-featureimg">
                            <div className="tg-bookimg">
                              <div className="tg-frontcover"><img src={wishList && wishList.bookImages!=undefined?wishList.bookImages[0].filename:""} alt="image description" /></div>
                              <div className="tg-backcover"><img src={wishList && wishList.bookImages!=undefined?wishList.bookImages[0].filename:""} alt="image description" /></div>
                            </div>
                            <a className="tg-btnaddtowishlist" onClick={()=>OnHandleAddtoWishList(wishList.audioId)} >
                              <i className="icon-heart" />
                              <span>Remove from wishlist</span>
                            </a>
                          </figure>
                          <div className="tg-postbookcontent">
                            <ul className="tg-bookscategories">
                              <li><a href="javascript:void(0);">Art &amp; Photography</a></li>
                            </ul>
                            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
                            <div className="tg-booktitle">
                              <h3><a href="javascript:void(0);">{wishList.title}</a></h3>
                            </div>
                            <span className="tg-bookwriter">By: 
                            <a >{wishList.authors?.map((author,index)=>{
                                        return author.caption
                                    })}</a>
                                    </span>
                            <span className="y"> {starRating(wishList.totalStarRate)}<span />
                            </span>
                            <br/>
                            <span className="tg-bookprice">
                              <ins>{wishList.currencySymbol} {wishList.realPrice}</ins>
                              <del>$27.20</del>
                            </span>
                            <a className="tg-btn tg-btnstyletwo" onClick={()=>OnHandleAddtoCart(wishList.audioId)}>
                              <i className="fa fa-shopping-basket" />
                              <em>Add To Basket</em>
                            </a>
                          </div>
                        </div>
                      </div>

                      </>)
                                })}
                    
       





            {/* <div id="wrapper" className="container">
                <section className="header_text sub">
                    <h4><span>Wish List</span></h4>
                </section>
                <section className="main-content">
                    <div className="row">
                        <div className="span9">
                            <ul className="thumbnails listing-products">

                                {wishListResult.searchResult.data.map((wishList, index) => {
                                    return (
                                        <>
                                <li className="span3">
        <div className="product-box">
          <span className="sale_tag" />	
          <Link to={`/book_details/${wishList.audioId}`}>											
          <a href="product_detail.html"><img style={{width:250,height:250}} alt="" src={wishList && wishList.bookImages!=undefined?wishList.bookImages[0].filename:""} /></a><br />
          <a href="#" className="category">{wishList.title}</a><br />
                                    <a href="product_detail.html" className="title">{wishList.authors?.map((author,index)=>{
                                        return author.caption
                                    })}</a>

                                    <p className="price">{wishList.currencySymbol} {wishList.realPrice}</p>
          </Link>
          <p className="button-btn">
            <button className="pull-right" onClick={()=>OnHandleAddtoCart(wishList.audioId)}><i className="fa fa-shopping-cart" aria-hidden="true" /></button> 
            <button className="pull-right" onClick={()=>OnHandleAddtoWishList(wishList.audioId)}><i className="fa fa-trash" aria-hidden="true" /></button>
          </p>
          
        </div>
      </li>   
                                        </>)
                                })}

                            </ul>
                        </div>
                    </div>
                </section>
            </div>

*/}

        </> 
    );
};

export default WishLIstComponenet;