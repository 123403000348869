import React from 'react';
import { ProductCategory, ProductShortInfo } from '../../../api/controller';
import {  Accordion, Button, Carousel } from 'react-bootstrap';
import HomeBookPageComponent from './HomeBookPageComponent';


interface IHomeBookPageCategoryComponentProps {
    book: ProductCategory,
    OnHandleViewClick: (category: any) => void;
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
    isWishListLoad:number;
    isCartLoad:number;
}



const HomeBookPageCategoryComponent: React.FC<IHomeBookPageCategoryComponentProps> = (props: any) => {





    const { book, OnHandleViewClick,onHandleCartLoad,
        onHandleWishListLoad,isWishListLoad,isCartLoad } = props
    let content: any[] = [];
    let contentData: any[] = [];


    const onViewClick = (book: string) => {
        console.log(2121, book);
        OnHandleViewClick(book);
    }


    const dataIterate = () => {
        let ln = book.products.length / 6;

        for(let i=0;i<=ln;i++){
            for(let j=0;j<6;j++)
            {
                if(book.products.length>(i*6)+j)
                {
                    content.push(book.products[(i*6)+j]);
                }
                else
                    break;
            }
        
          contentData.push (
            <Carousel.Item>
              <div>{
                  content.map((product: ProductShortInfo, index: any) => {
                    
                      return (

                          <HomeBookPageComponent key={`product_${product.productId}`} 
                          product={product}
                           indexData={index}
                           onHandleCartLoad={onHandleCartLoad}
                           onHandleWishListLoad={onHandleWishListLoad}
                           isWishListLoad={isWishListLoad}
                           isCartLoad={isCartLoad}
                           />

                      )
                  }

                  )
              }
              </div>
              </Carousel.Item>
          )
          content=[];
        }
       
       return contentData

    }



    return (
        <>



<section className=" tg-haslayout">
        <div className="container">
          <div className="row"> 
             <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> 
              <div className="tg-sectionhead">
                <h2>{book.title}</h2>
                <a className="tg-btn" href="javascript:void(0);" onClick={()=>onViewClick(book.title)}>View All</a>
                
              </div>
             </div> 
             </div>
            {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"> */}
              {/* <div style={{width:'250px'}} id="tg-bestsellingbooksslider" className="tg-bestsellingbooksslider tg-bestsellingbooks "> */}
              {/* <Carousel autoplay> */}
              
              <Carousel>
{dataIterate()} 
              {/* <Carousel.Item>
             {
                        book.products.map((product: ProductShortInfo, index: any) => {

                            return (

                                <HomeBookPageComponent key={`product_${product.productId}`}
                                    product={product}
                                    indexData={index}

                                />

                            )
                        }

                        )
                    } 
                     </Carousel.Item>                  */}
</Carousel>
{/* </Carousel> */}
                                

{/* </div> */}
             {/* </div>    */}
          </div>
        {/* </div> */}
        </section>


            {/* <section className="best_seller">
                <Accordion defaultActiveKey="0">
                    <div className=" container">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            <h1 className="pull-left">{book.title}</h1>
                        </Accordion.Toggle>
                        <div className="view_all"><button style={{    border: 'none',
    backgroundColor: 'white',color: 'darkorange'}} onClick={()=>onViewClick(book.title)}>View All <img src='' /></button></div>
                        <div className="clearfix" />
                        <Accordion.Collapse eventKey="0">
                            <div className="row1">

                                <div className=" clearfix" />

                                <Carousel>
                                    {dataIterate()}


                                </Carousel>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            </section>  */}
        </>
    );
};

export default HomeBookPageCategoryComponent;