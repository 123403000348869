import React from 'react';

interface BillingHistoryComponenetProps{
  onVisibleClick:(value:any)=>void;
}

interface BillingHistoryComponenetState{

}
const initialState={

}
const BillingHistoryComponenet:React.FC<BillingHistoryComponenetProps>=(props) => {

    const [billingHistoryComponenetState,setBillingHistoryComponenetState]=React.useState<BillingHistoryComponenetState>(initialState)
    
    const {onVisibleClick}=props
    
    return (
        <>
       <section className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-sectionhead" style={{marginTop: '50px'}}>
              <div className="container">
                <div className="row">
                  <div className="wrapper">
                    <div className="list">
                      <ul>
                        <li>Order - ID</li>
                        <li>Order - Date</li>
                        <li>Item - Price</li>
                        <li>View-Bill</li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <button onClick={()=>onVisibleClick(true)}>View Bill</button>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li data-label="order-id">#406-0633467</li>
                        <li data-label="order-date">17-Jun-2020</li>
                        <li data-label="order-price"><i className="fa fa-rupee" />2000</li>
                        <li data-label="view-bill">
                          <div className="tbl-button">
                            <a href="#">View Bill</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    );
};

export default BillingHistoryComponenet;