import React, { useRef } from 'react';
import BookDetailsComponent from './BookDetailsComponent';
import { useBookDetailsDispatcher, useBookDetailsContext, getBookDetailsListProduct } from '../action/BookDetailsAction';
import { ProductDetail, ProductReviewsDataRequestModel, CartPostModel, CartClient, ProductInfo, WishlistSearchDataRequestModel, ProductShortInfo } from '../api';
import { useProductReviewDispatcher, useProductReviewContext, getProductReviewList } from '../action/ProductReviewAction';
import { RouteComponentProps, withRouter } from 'react-router';
import { useCartDispatcher, useCartContext, addToCartList } from '../action/CartAction';
import axios from 'axios';
import AuthService from '../_services/AuthService';
import { AppUrls } from '../_environments/environment';
import { Button, message, Empty } from 'antd';
import BookDetailsComponentOnFrontPage from './BookDetailsComponentOnFrontPage';
import { useWishListDispatcher, useWishListContext, getWishListListResult } from '../action/WishLIstAction';

const key = 'updatable';
console.log("Inside BookDetailsContainer");

export interface IBookDetailsContaineParams {
    id?: string;
}

interface IBookDetailsContainerProps extends RouteComponentProps<IBookDetailsContaineParams> {
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
}

interface IBookDetailsContainerState {
    addCart: boolean,
    wishList: boolean,
    rating:boolean;
    loadReview:number;
    bookDetails:ProductDetail,
refreshVariable:number;
data:ProductShortInfo[] |undefined
}

const initialState = {
    addCart: false,
    wishList: false,
    rating:false,
    loadReview:0,
    bookDetails:{},
    refreshVariable:0,
    data:{}
} as IBookDetailsContainerState


const BookDetailsContainer: React.FC<IBookDetailsContainerProps> = (props) => {
    const bookDetailsDispatcher = useBookDetailsDispatcher();
    const bookDetailsContext = useBookDetailsContext();
    const { bookDetailsResult } = bookDetailsContext

    const productReviewDispatcher = useProductReviewDispatcher();
    const productReviewContext = useProductReviewContext();
    const { productReviewResult } = productReviewContext

    const cartDispatcher = useCartDispatcher();
    const cartContext = useCartContext();
    const { cartItems } = cartContext


    const wishListDispatcher = useWishListDispatcher();
    const wishListContext = useWishListContext();
    const {wishListResult } = wishListContext;

    const [bookContainerState, setBookContainerState] = React.useState<IBookDetailsContainerState>(initialState)
    const { match,onHandleCartLoad ,onHandleWishListLoad} = props
    const { id } = props.match.params;

    let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
    const { addCart, wishList,rating,loadReview,bookDetails,refreshVariable,data } = bookContainerState

    React.useEffect(() => {
        (async () => {

            await getBookDetailsListProduct(bookDetailsDispatcher, { audioId: id, currencyId: "1" } as {} as ProductDetail);

        })();
    }, [id])

    React.useEffect(() => {
        (async () => {

            await getProductReviewList(productReviewDispatcher, { audioId: id } as {} as ProductReviewsDataRequestModel);

        })();
    }, [id,loadReview])
  

    React.useEffect(() => {
        (async () => {
            await getWishListListResult(wishListDispatcher,{currencyId:"1"} as WishlistSearchDataRequestModel );
            setBookContainerState({
                ...bookContainerState,
               refreshVariable:refreshVariable+1
            });
    
         })();
    }, [addCart,wishList])

    React.useEffect(() => {
        (async () => {

            setBookContainerState({
                ...bookContainerState,
               data:wishListResult.searchResult?.data?.filter(data=>data.audioId===id)
            });

        })();
    }, [refreshVariable])

    React.useEffect(() => {
        (async () => {
            let headers = {
               
                "Content-Type": "application/json",
            };

            let formData = new FormData();
            const url = AppUrls.GetFrontPageproductDetails
            axios.post(url, JSON.stringify({ audioId: id,currencyId: "1" }), { headers: headers }).then(res => {
                
                if (res) {
                    setBookContainerState((bookContainerState) => {
                        return {
                            ...bookContainerState,
                           bookDetails:res.data
                        }
                    });
                }
            })
           
        })();
    }, [id])

    React.useEffect(() => {
        if (addCart) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.AddToCart

                axios.post(url, JSON.stringify({ audioId: id }), { headers: headers }).then(res => {
                    
                    if (res) {
                        onHandleCartLoad();
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                    }
                })
            })();
        }
    }, [addCart])


    React.useEffect(() => {
        if (wishList) {
            (async () => {
                let headers = {
                    Authorization:  `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();
                const url = AppUrls.AddToWishList
                axios.post(url, JSON.stringify({ audioId: id }), { headers: headers }).then(res => {
                    if (res) {
                        setBookContainerState({
                            ...bookContainerState,
                            wishList: false
                        });
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                    }
                })
            })();
        }
    }, [wishList])


    const OnHandleLoadReview=()=>{
        setBookContainerState((bookContainerState) => {
            return {
                ...bookContainerState,
                loadReview:loadReview+1,
            }
        });
    }

    const OnHandleAddtoCart = () => {

        setBookContainerState({
            ...bookContainerState,
            addCart: true
        });

    }

    const OnHandleAddtoWishList = () => {
        setBookContainerState({
            ...bookContainerState,
            wishList: true
        });
    }


    if(authorizationToken===""){
        return (
            <>
                <BookDetailsComponentOnFrontPage
                    bookDetails={bookDetails.productInfo}
                    reviews={productReviewResult.reviews}
                    OnHandleAddtoCart={OnHandleAddtoCart}
                    OnHandleAddtoWishList={OnHandleAddtoWishList}
                    OnHandleLoadReview={OnHandleLoadReview}
                />
            </>
        );
    }
else{
    return (
        <>
            <BookDetailsComponent
                bookDetails={bookDetailsResult.productInfo}
                reviews={productReviewResult.reviews}
                OnHandleAddtoCart={OnHandleAddtoCart}
                OnHandleAddtoWishList={OnHandleAddtoWishList}
                OnHandleLoadReview={OnHandleLoadReview}
                wishLists={wishList}
                 data={data}
            />
        </>
    );
}
};

export default withRouter(BookDetailsContainer);