import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {


  const scrollTop=()=>{
    window.scrollTo({ 
      top: 0,
      behavior: "smooth"})
  }
    return (
        <>

<footer id="dk-footer" className="dk-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="dk-footer-box-info">
                <a href="index.html" className="footer-logo">
                  <img src={require('../../images/logo.png')} alt="footer_logo" className="img-fluid" />
                </a>
                <p className="footer-info-text">
                  ENTERTAINMENT AND ENLIGHTENMENT
                  COMBINE TO FORM VOCABOOKS.
                </p>
                <div className="footer-social-link">
                  <h3>Follow us</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* End Social link */}
              </div>
              {/* End Footer info */}
            </div>
            {/* End Col */}
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-us">
                    <div className="contact-icon">
                      <i className="fa fa-map-o" aria-hidden="true" />
                    </div>
                    {/* End contact Icon */}
                    <div className="contact-info">
                      <p>B-12, GCDA, NGO Quarters - Mavelipuram Rd, Kakkanad</p>
                    </div>
                    {/* End Contact Info */}
                  </div>
                  {/* End Contact Us */}
                </div>
                {/* End Col */}
                <div className="col-md-6">
                  <div className="contact-us contact-us-last">
                    <div className="contact-icon">
                      <i className="fa fa-volume-control-phone" aria-hidden="true" />
                    </div>
                    {/* End contact Icon */}
                    <div className="contact-info">
                      <h3>+91 8848738678</h3>
                      <p>Give s a call</p>
                    </div>
                    {/* End Contact Info */}
                  </div>
                  {/* End Contact Us */}
                </div>
                {/* End Col */}
              </div>
              {/* End Contact Row */}
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="footer-widget footer-left-widget">
                    <div className="section-heading">
                      <h3>Useful Links</h3>
                      <span className="animate-border border-black" />
                    </div>
                    <ul>
                      <li>
                        <Link to='/about_us' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>About us</Link>
                        {/* <a href="#"></a> */}
                      </li>
                      <li>
                      <Link to="/service-and-condition" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Services</Link>
                        {/* <a href="#">Services</a> */}
                      </li>
                      <li>
                      {/* <Link to=''></Link> */}
                        <a href="#">Projects</a>
                      </li>
                      <li>
                        <a href="#">Our Team</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                      <Link to='/contact_us' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Contact us</Link>
                        {/* <a href="#">Contact us</a> */}
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>
                      <li>
                        <a href="#">Testimonials</a>
                      </li>
                      <li>
                        <a href="#">Faq</a>
                      </li>
                    </ul>
                  </div>
                  {/* End Footer Widget */}
                </div>
                {/* End col */}
                <div className="col-md-12 col-lg-6">
                  <div className="footer-widget">
                    <div className="section-heading">
                      <h3>Subscribe</h3>
                      <span className="animate-border border-black" />
                    </div>
                    <p>
                      Suscribe to our newsletter and get the lastest scoop right to your inbox.</p>
                    <form action="#">
                      <div className="form-row">
                        <div className="col dk-footer-form">
                          <input type="email" className="form-control" placeholder="Email Address" />
                          <button type="submit">
                            <i className="fa fa-send" />
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* End form */}
                  </div>
                  {/* End footer widget */}
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
            {/* End Col */}
          </div>
          {/* End Widget Row */}
        </div>
        {/* End Contact Container */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span>Copyright © 2020, All Right Reserved Vocabooks</span>
              </div>
              {/* End Col */}
              <div className="col-md-6">
                <div className="copyright-menu">
                  <ul>
                    <li>
                    <Link to='/' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Home</Link>
                      {/* <a href="#">Home</a> */}
                    </li>
                    <li>
                    <Link to='/terms-and-condition' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Terms</Link>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                    <Link to='/terms-and-condition' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Terms</Link>
                      {/* <a href="/service-and-condition">Privacy Policy</a> */}
                    </li>
                    <li>
                    <Link to='/contact_us' onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }} > Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End col */}
            </div>
            {/* End Row */}
          </div>
          {/* End Copyright Container */}
        </div>
        {/* End Copyright */}
        {/* Back to top */}
        <div id="back-to-top" className="back-to-top">
          <button className="btn btn-dark" title="Back to Top" style={{display: 'block'}}>
            <i className="fa fa-angle-up" />
          </button>
        </div>
      </footer>


{/* <footer id="tg-footer" className="tg-footer tg-haslayout">
        <div className="tg-footerarea">
          <div className="container">
            <div className="row">
              <div className="tg-threecolumns">
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="tg-footercol tg-widget tg-widgetnavigation">
                    <div className="tg-widgettitle">
                      <h3>Links</h3>
                    </div>
                    <div className="tg-widgetcontent">
                      <ul>
                        <li>
                          <Link to='/'  > Home</Link></li>
                        <li><Link to='/contact_us'  > Contact Us</Link></li>
                        <li><a href="javascript:void(0);">Help</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="tg-footercol tg-widget tg-widgetnavigation">
                    <div className="tg-widgettitle">
                      <h3>Socila  Media</h3>
                    </div>
                    <div className="tg-widgetcontent">
                      <ul>
                        <li><a href="javascript:void(0);">Facebook</a></li>
                        <li><a href="javascript:void(0);">Twitter</a></li>
                        <li><a href="javascript:void(0);">Instagram</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="tg-footercol tg-widget tg-widgetnavigation">
                    <div className="tg-widgettitle">
                      <h3>Sellers</h3>
                    </div>
                    <div className="tg-widgetcontent">
                      <ul>
                        <li><a href="javascript:void(0);">Best Sellers</a></li>
                        <li><a href="javascript:void(0);">New Releases</a></li>
                        <li><a href="javascript:void(0);">Indian Listens</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="tg-footercol tg-widget tg-widgetnavigation">
                    <div className="tg-widgettitle">
                      <h3>Category</h3>
                    </div>
                    <div className="tg-widgetcontent">
                      <ul>
                        <li><a href="javascript:void(0);">Mysteries &amp; Thrillers</a></li>
                        <li><a href="javascript:void(0);">Romance</a></li>
                        <li><a href="javascript:void(0);">Fiction</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tg-footerbar">
          <a id="tg-btnbacktotop" className="tg-btnbacktotop" href="javascript:void(0);"><i onClick={scrollTop} className="icon-chevron-up" /></a>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <span className="tg-paymenttype"><Link to="/terms-and-condition">Terms &amp; Conditions</Link> |
                <Link to="/service-and-condition">Service &amp; Conditions </Link>   |
                  Interest- Based Ads | 
                  Recurring Payment Terms</span>
                <span className="tg-copyright">2019 All Rights Reserved By © <a href="#">Audio Books</a></span>
              </div>
            </div>
          </div>
        </div>
      </footer> */}


                {/* <section> 
        <div className="bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".6s">   
                <ul><Link to="/">
                  <li>Home</li>
                  </Link>
                  <Link to="/about_us">
                  <li><a href="#">About Us </a></li>
                  </Link>
                  <Link to="/contact_us">
                  <li><a href="#">Contact Us</a></li>              
                  </Link>
                </ul> 
              </div>
              <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".6s">   
                <ul>
                  <li><a href="#">Facebook</a></li>
                  <li><a href="#">Twitter</a></li>
                  <li><a href="#">Instagram</a></li>              
                   
                </ul> 
              </div>
              <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".6s">   
                <ul>
                  <li><a href="#">Best Sellers</a></li>
                  <li><a href="#">New Releases</a></li>
                  <li><a href="#">Indian Listens</a></li>              
                    
                </ul> 
              </div>
              <div className="col-sm-3 wow fadeInLeft" data-wow-delay=".6s">   
                <ul>
                  <li><a href="#">Mysteries &amp; Thrillers </a></li>
                  <li><a href="#">Romance</a></li>
                  <li><a href="#">Fiction</a></li>              
                
                </ul> 
              </div> 
            </div> 
          </div>
        </div> 
        <div className="bottom-sec1 wow fadeInLeft" data-wow-delay=".4s">  
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/terms-and-condition">
                Terms And Conditions  
                </Link>
                <Link to="/service-and-condition">
                Service And Condition
                </Link>
                <a href="#">Interest-Based Ads</a>
                <a href="#">Recurring Payment Terms </a>
                <a href="#">India (English)</a>  
              </div>
              <div className="col-sm-6 text-left">Copyright 2019 Audio. All Rights Reserved</div>
              <div className="col-sm-6 text-right">Designed By </div>
            </div>
          </div>
        </div>
      </section>   */}
        </>
    );
};

export default Footer;