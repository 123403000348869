import React from 'react';
import { useHomeDispatcher, useHomeContext } from '../action/HomeAction';
import { ProductShortInfo } from '../api';
import { Link } from 'react-router-dom';
import AuthService from '../_services/AuthService';
import { useCartDispatcher, useCartContext } from '../action/CartAction';
import { useWishListDispatcher, useWishListContext } from '../action/WishLIstAction';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import { message } from 'antd';

const key = 'updatable';

interface IFrontPageBookComponenetProps{
key:string,
book:ProductShortInfo,
onHandleCartLoad:()=>void;
onHandleWishListLoad:()=>void;
}

interface IFrontPageBookComponenetState {
  addCart: boolean,
  wishList: boolean,
  
}

const initialState = {
  addCart: false,
  wishList: false,
 
} as IFrontPageBookComponenetState


const FrontPageBookComponenet:React.FC<IFrontPageBookComponenetProps>=(props) => {

    const homeDispatcher = useHomeDispatcher();
    const homeContext = useHomeContext();
    const { value,visible,homeProducts } = homeContext;

const {book,onHandleCartLoad,onHandleWishListLoad}=props

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";

const [homeBookPageComponentState, sethomeBookPageComponentState] = React.useState<IFrontPageBookComponenetState>(initialState);
const {addCart,wishList}=homeBookPageComponentState

const cartDispatcher = useCartDispatcher();
const cartContext = useCartContext();
const { cartItems } = cartContext


const wishListDispatcher = useWishListDispatcher();
const wishListContext = useWishListContext();
const {wishListResult } = wishListContext;

React.useEffect(() => {
    if (addCart) {
        (async () => {

            let headers = {
                Authorization: `Bearer ${authorizationToken}`,
                "Content-Type": "application/json",
            };

            let formData = new FormData();

            const url = AppUrls.AddToCart

            axios.post(url, JSON.stringify({ audioId: book.audioId }), { headers: headers }).then(res => {
                
                if (res) {
                    onHandleCartLoad();
                    onHandleWishListLoad();
                    // message.loading({ content: 'Loading...', key });
                    setTimeout(() => {
                        message.success({ content: res.data.message, key, duration: 2 });
                    }, 1000);

                }
            })
        })();
    }
}, [addCart])


React.useEffect(() => {
    if (wishList) {
        (async () => {
            let headers = {
                Authorization:  `Bearer ${authorizationToken}`,
                "Content-Type": "application/json",
            };

            let formData = new FormData();
            const url = AppUrls.AddToWishList
            axios.post(url, JSON.stringify({ audioId: book.audioId  }), { headers: headers }).then(res => {
                if (res) {
                  sethomeBookPageComponentState({
                        ...homeBookPageComponentState,
                        wishList: false
                    });
                    onHandleWishListLoad();
                    // message.loading({ content: 'Loading...', key });
                    setTimeout(() => {
                        message.success({ content: res.data.message, key, duration: 2 });
                    }, 1000);

                }
            })
        })();
    }
}, [wishList])


const OnHandleAddtoCart = () => {

  sethomeBookPageComponentState({
      ...homeBookPageComponentState,
      addCart: true
  });

}

const OnHandleAddtoWishList = () => {
sethomeBookPageComponentState({
      ...homeBookPageComponentState,
      wishList: true
  });
}


    const starRating = (rating: number) => {

        let str = [];
        for (let i = 1, j = 2; i <= 5; i++ , j++) {

            if (rating > i && rating < j) {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star-half-o" aria-hidden="true" />

            }
            else if (rating >= i) {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star" aria-hidden="true" />

            }
            else {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star-o" aria-hidden="true" />

            }


        }

        return str;
    }
    return (
        <>

<div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
        <div className="tg-postbook">
          <figure className="tg-featureimg">
          <Link to={`/book_details/${book.audioId}`}>
            <div className="tg-bookimg">
              <div  className="tg-frontcover"><img src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''}  alt="image description" /></div>
              <div  className="tg-backcover"><img src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''}  alt="image description" /></div>
            </div>
            </Link>
            <a className="tg-btnaddtowishlist" onClick={OnHandleAddtoWishList} href="javascript:void(0);">
              <i className="icon-heart" />
              <span>add to wishlist</span>
            </a>
          </figure>
          <div className="tg-postbookcontent">
            <ul className="tg-bookscategories">
              <li><a href="javascript:void(0);">Art &amp; Photography</a>
              </li>
            </ul>
            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
            <div className="tg-booktitle">
              <h3><a href="javascript:void(0);">{book.title}</a></h3>
            </div>
            <span className="tg-bookwriter">By: <a href="javascript:void(0);">Angela Gunning</a></span>
            <span > {starRating(book.totalStarRate)}<span /></span>
            <span className="tg-bookprice">
              <ins>$25.18</ins>
              <del>$27.20</del>
            </span>
            <a className="tg-btn tg-btnstyletwo" onClick={OnHandleAddtoCart  } href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em>
            </a>
          </div>
        </div>
      </div>



            {/* <div className="col-sm-3 col-xs-4">
        
            <Link to={`/book_details/${book.audioId}`}>
                    <div className="try_shadow" style={{ margin:10}}>
                    <div className="try_audio_sec">
                    <div ><img style={{height:225,width:225}}
                     src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''} 
                     className="img-responsive center-block" /></div>
                      
                    </div>
                    <div className="book_author">{book.title}</div>
                    <div className="rating">
                    {starRating(book.totalStarRate)}
                    </div></div>
                </Link>
            </div> */}
            
        </>
    );
};

export default FrontPageBookComponenet;
