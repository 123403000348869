import React from 'react';
import { CartResult } from '../../api';
import { Link } from 'react-router-dom';
import { Tooltip, Button, Popconfirm } from 'antd';
// import Popconfirm from "antd/lib/popconfirm";createError
interface ISmallCardCartListProps{
    cartItems:CartResult,
    onhandleCartDeleteClick:(id:any)=>void;
}

interface ISmallCardCartListState{

}

const SmallCardCartList:React.FC<ISmallCardCartListProps>=(props) => {
    const {cartItems,onhandleCartDeleteClick}=props;
    var subtotal=0;
   
    if (!cartItems || !cartItems.searchResult || !cartItems.searchResult.data || cartItems.searchResult.data.length <= 0) {
        return (
          <>
               <div className='tg-minicartbody'>
            <p>
            Cart is empty
            </p>
            
          </div>
          </>
        );
      }

      const onDeleteButtonClick=(id:any)=>{
        console.log(55533333,id)
         onhandleCartDeleteClick(id);
      
      }
      const handleClearAll=(id:any)=>{
        // onhandleWishListDeleteClick(id);
        console.log(4424)
      }

      
      function cancel(e: any) {
        console.log(e);
      }
      var currencySymbol=cartItems.searchResult.data[0].currencySymbol;
    return (
        <>
           <div className="tg-minicartbody">
                              {cartItems.searchResult.data.map((cartItem, index) => {
subtotal=subtotal+cartItem.realPrice;

                                return (<li  style={{ listStyle: 'none' }}>
                                  <div className="tg-minicarproduct">
                                    <figure>
                                      <img style={{ width: 70, height: 70 }} src={cartItem && cartItem.bookImages != undefined ? cartItem.bookImages[0].filename : ""} alt="image description" />
                                    </figure>
                                    <div className="tg-minicarproductdata">
                                      <h5><span >{cartItem.title}</span></h5>
                                      <h6><span >  {cartItem.offerPrice == 0 ? (<>{cartItem.currencySymbol}  {cartItem.realPrice}</>) : (<>
                                        <s style={{ color: 'sandybrown' }}>{cartItem.currencySymbol}  {cartItem.realPrice}</s> &nbsp;
                      {cartItem.currencySymbol}  {cartItem.offerPrice}
                                      </>
                                      )}</span></h6>
                                      <Tooltip title="Clear all">
                <Button
                    shape="circle"
                     style={{ float: 'right',marginTop:-30 }}
                    icon="delete"
                    size="small"
                    onClick={()=>onDeleteButtonClick(cartItem.audioId)}
                />
            </Tooltip>
                                    </div>
                                  </div>

                                </li>)
                              })}
                              <div className="tg-minicartfoot">
                                <span className="tg-btnemptycart" >
                                  {/* <i className="fa fa-trash-o" />
                                  <span>Clear Your Cart</span> */}
                                                       










                 </span>
                 <div>
               <Popconfirm
              
                                  title={ 
                                      <div>
                                        <p>Are you sure to approve this job?</p>
                                      </div>
                                    }
                                    onConfirm={() =>
                                      // handleApprovalStatus(clientJob.rowId, 0, 1)
                                      {
                                        onDeleteButtonClick("0")
                                      }
                                    }
                                
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        float: "left",
                                      }}
                                    >
                                     Clear your cart
                                    </a>
                                  </Popconfirm>
                                  </div>
                                <span className="tg-subtotal">Subtotal:{currencySymbol}{subtotal} <strong></strong></span>
                                <div className="tg-btns">
                                <Link to='/cart_Items'>  <span className="tg-btn tg-active" >View Cart</span></Link>
                                <Link to='/transaction'> <span className="tg-btn">Checkout</span></Link> 
                                </div>
                              </div>

                            </div>
        </>
    );
};

export default SmallCardCartList;