class AppTranslationService {
    getDefaultLanguage() {
        return "en-US";
    }

    getBrowserLanguage() {
        return navigator.language;
    }

    useBrowserLanguage(): string | void {
        const browserLang = this.getBrowserLanguage();

        if (browserLang.match(/en|fr|de|pt|ar|ko/)) {
            this.changeLanguage(browserLang);
            return browserLang;
        }
    }

    useDefaultLangage() {
        return this.changeLanguage(null);
    }

    changeLanguage(language: string | null) {
        if (!language) {
            language = this.getDefaultLanguage();
        }

        if (language !== this.getBrowserLanguage()) {
            setTimeout(() => {
                //change ui lang
            });
        }

        return language;
    }
}

export default new AppTranslationService();