import { Reducer } from "react"
import { IWishListManagementState, WishListDispatcherContext, WishListStateContext } from "../context/WishListContext"
import React from "react";
import { ProductAPIClient, WishlistSearchDataRequestModel, ProductSearchResult,   } from "../api";


export type IWishListAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo",
    wishListTerm:string;
}|{
    type:"SET_HOME_PRODUCT_LIST",
     wishListResult:ProductSearchResult 
}




export const wishListReducer: Reducer<IWishListManagementState, IWishListAction> = (draft, action): IWishListManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
          draft.wishListTerm=action.wishListTerm;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.wishListResult=action.wishListResult;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IWishListAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}





export const useWishListDispatcher = (): React.Dispatch<IWishListAction> => {
    const wishListDispatcher = React.useContext(WishListDispatcherContext);
    if (!wishListDispatcher) {
        throw new Error("You have to provide the WishList dispatcher using theWishListDispatcherContext.Provider in a parent component.");
    }
    return wishListDispatcher;
}


export const useWishListContext = (): IWishListManagementState => {
    const wishListContext = React.useContext(WishListStateContext);
    if (!wishListContext) throw new Error("You have to provide the wishList context using the WishListStateContext.Provider in a parent component.");
    return wishListContext;
}



const ProductApi = new ProductAPIClient();

export const getWishListListResult = async (dispatcher: React.Dispatch<IWishListAction>, query:WishlistSearchDataRequestModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const wishListResult = await ProductApi.getWishList( query)
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", wishListResult: wishListResult });

    }
    catch(e){

    }
}


// export const deleteWishListItem = async (dispatcher: React.Dispatch<ICartAction>, query:CartPostModel ) => {
//     // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
//     try{

//         const cartItems = await ProductApi.( query)
//     //    dispatcher({ type: "SET_HOME_PRODUCT_LIST", cartItems: cartItems });

//     }
//     catch(e){

//     }
// }

// export const addToWishList = async (dispatcher: React.Dispatch<ICartAction>, query:CartPostModel ) => {
//     // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
//     try{

//         const cartItems = await cartApi.addToCart(query)
//     //    dispatcher({ type: "SET_HOME_PRODUCT_LIST", cartItems: cartItems });

//     }
//     catch(e){

//     }
// }



