import React from 'react';
import { Link } from 'react-router-dom';
import { ProductShortInfo } from '../api';

interface ISearchComponentProps{
searchItem:ProductShortInfo
}

interface ISearchComponentState{
    
}

const SearchComponent:React.FC<ISearchComponentProps>= (props) => {
    
const {searchItem} = props;
    const starRating = (rating: number) => {

        let str = [];
        for (let i = 1, j = 2; i <= 5; i++ , j++) {

            if (rating > i && rating < j) {
                str[i] = <i className="fa fa-star-half-o" aria-hidden="true" />

            }
            else if (rating >= i) {
                str[i] = <i className="fa fa-star" aria-hidden="true" />

            }
            else {
                str[i] = <i className="fa fa-star-o" aria-hidden="true" />

            }


        }

        return str;
    }

    return (
        <>



<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        <div className="tg-postbook">
          <figure className="tg-featureimg">
          <Link to={`/book_details/${searchItem.audioId}`}>
            <div className="tg-bookimg">
              <div className="tg-frontcover"><img src={ searchItem.bookImages!=undefined?searchItem.bookImages[0].filename:"" }  alt="image description" /></div>
              <div className="tg-backcover"><img src={ searchItem.bookImages!=undefined?searchItem.bookImages[0].filename:"" }  alt="image description" /></div>
            </div>
            </Link>
            <a className="tg-btnaddtowishlist" href="javascript:void(0);">
              <i className="icon-heart" />
              <span>add to wishlist</span>
            </a>
          </figure>
          <div className="tg-postbookcontent">
            <ul className="tg-bookscategories">
              <li><a href="javascript:void(0);">Art &amp; Photography</a>
              </li>
            </ul>
            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
            <div className="tg-booktitle">
              <h3><a href="javascript:void(0);">{searchItem.title}
                  </a></h3>
            </div>
     {/* <span className="tg-bookwriter">By: <a href="javascript:void(0);">
        {searchItem.authors!=undefined? searchItem.authors.map((author:any,index:any)=>{
                      return(
                    <span>{author.caption}</span>
                      )
                    }):null
                    }</a></span> */}
            <span > 
            {starRating(searchItem.totalStarRate)}
                <span /></span>
            <span className="tg-bookprice">
    <ins>{searchItem.currencySymbol}{searchItem.realPrice}</ins>
              <del>{searchItem.currencySymbol}{searchItem.offerPrice}</del>
            </span>
            <a className="tg-btn tg-btnstyletwo" href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em>
            </a>
          </div>
        </div>
      </div>







           {/* <div className="col-sm-2 col-xs-4">
                <Link to={`/book_details/${searchItem.audioId}`}>
                
                    <div className="try_audio_sec">
                        <div>
                            
                            <img src={ searchItem.bookImages!=undefined?searchItem.bookImages[0].filename:"" } className="img-responsive center-block" /></div>
                       
                    </div>
                   
                    <div className="book_author">{searchItem.title}</div>
                    <div className="rating">
                        {starRating(3)}

                    </div>
               
                </Link>
            </div> */}
  
        </>
    );
};

export default SearchComponent;