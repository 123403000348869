import { DBkeys } from "./DBkeys";
import { environment } from "../_environments/environment";
import { Utilities } from "./Utilities";
import LocalStoreManager from "./LocalStoreManager";
import AppTranslationService from "./AppTranslationService";

interface UserConfiguration {
    language: string;
    homeUrl: string;
}

class ConfigurationService {
    _language: string | null = null;
    _homeUrl: string | null = null;

    public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
    public static readonly defaultLanguage: string = 'en-US';
    public static readonly defaultHomeUrl: string = '/';

    private saveToLocalStore(data: any, key: string) {
        setTimeout(() => localStorage.savePermanentData(data, key));
    }

    set language(value: string) {
        this._language = value;
        this.saveToLocalStore(value, DBkeys.LANGUAGE);
        AppTranslationService.changeLanguage(value);
    }

    get language() {
        return this._language || ConfigurationService.defaultLanguage;
    }

    set homeUrl(value: string) {
        this._homeUrl = value;
        this.saveToLocalStore(value, DBkeys.HOME_URL);
    }

    get homeUrl() {
        return this._homeUrl || ConfigurationService.defaultHomeUrl;
    }

    public clearLocalChanges() {
        this._language = null;
        this._homeUrl = null;

        LocalStoreManager.deleteData(DBkeys.LANGUAGE);
        LocalStoreManager.deleteData(DBkeys.HOME_URL);

        this.resetLanguage();
    }

    private resetLanguage() {
        const language = AppTranslationService.useBrowserLanguage();

        if (language) {
            this._language = language;
        } else {
            this._language = AppTranslationService.useDefaultLangage();
        }
    }

    public import(jsonValue: string) {

        this.clearLocalChanges();

        if (jsonValue) {
            const importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

            if (importValue.language != null) {
                this.language = importValue.language;
            }

            if (importValue.homeUrl != null) {
                this.homeUrl = importValue.homeUrl;
            }
        }
    }

    public export(changesOnly = true): string {

        const exportValue: UserConfiguration = {
            language: (changesOnly ? this._language : this.language) || ConfigurationService.defaultLanguage,
            homeUrl: (changesOnly ? this._homeUrl : this.homeUrl) || ConfigurationService.defaultHomeUrl
        };

        return JSON.stringify(exportValue);
    }
}

export default new ConfigurationService();