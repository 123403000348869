import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { ISearchAction, searchReducer } from "../action/SearchAction";
import { useImmerReducer } from "use-immer";
import { ProductSearchResult, ProductSelectBoxDataViewModel } from "../api/controller";
export const SearchDispatcherContext = React.createContext<React.Dispatch<ISearchAction> | null>(null);
export const SearchStateContext = React.createContext<ISearchManagementState | null>(null);

export interface ISearchManagementProps extends RouteComponentProps<any> { }

export interface ISearchManagementState {
    visible:boolean;
    value:number;
    searchTerm:string;
    searchResult:ProductSearchResult;
    selectBoxData:ProductSelectBoxDataViewModel
}

export const initialSearchManagementState = {
    visible:false,
    value:0,
    searchTerm:"",
    searchResult:{} as ProductSearchResult,
    selectBoxData:{} as ProductSelectBoxDataViewModel
} as ISearchManagementState;

export const SearchContextProvider: React.FC = ({ children }) => {
    const [searchState, dispatcher] = useImmerReducer(searchReducer, initialSearchManagementState);

    return (
        <SearchDispatcherContext.Provider value={dispatcher}>
            <SearchStateContext.Provider value={searchState}>
                {children}
            </SearchStateContext.Provider>
        </SearchDispatcherContext.Provider>
    )
}