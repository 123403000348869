import React from 'react';
import { Modal, Input, Rate } from 'antd';
import Form from 'react-bootstrap/Form';
interface IRatingComponenetProps{
visible:boolean;
handleOk:(e:any)=>void;
    handleCancel:(e:any)=>void;
    OnHandleRateStory:(storyRate:number)=>void;
    OnHandleRateNarrator:(narratorRate:number)=>void;
    OnHandleChangeTitle:(title:string)=>void;
    OnHandleChangeDiscription:(description:string)=>void;
    OnHandleSaveRating:()=>void;
}

const RatingComponenet:React.FC<IRatingComponenetProps>=(props) => {

    const {visible,handleOk,handleCancel,OnHandleChangeDiscription,OnHandleSaveRating,
      OnHandleChangeTitle,OnHandleRateNarrator,OnHandleRateStory}=props

    const handleRateStory=(value:number)=>{
      OnHandleRateStory(value);
    }

    const handleRateNarrator=(value:number)=>{
      OnHandleRateNarrator(value);
    }

    const handleChangeTitle=(e:any)=>{
      OnHandleChangeTitle(e.target.value);
    }

    const handleChangeDiscription=(e:any)=>{
      OnHandleChangeDiscription(e.target.value)
    }

    const handleSaveRating=()=>{
      OnHandleSaveRating();
    }

    return (
        <>
             <Modal
          title="Rating"
          visible={visible}
          onOk={handleSaveRating}
          onCancel={handleCancel}
        >
          <h2>Rate Story</h2>
          <Rate  onChange={handleRateStory}/>
          <h2>Rate the Narrator</h2>
          <Rate onChange={handleRateNarrator} />
          <h2>title</h2>
          <Form.Control type="text" placeholder="Normal text" onChange={handleChangeTitle} />
          <h2>Discription</h2>
          <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label></Form.Label>
    <Form.Control as="textarea"   onChange={handleChangeDiscription} />
  </Form.Group>
        </Modal> 
        </>
    );
};

export default RatingComponenet;