import { Reducer } from "react"
import { IBookDetailsManagementState, BookDetailsDispatcherContext, BookDetailsStateContext } from "../context/BookDetailsContext"
import React from "react";
import { ProductAPIClient, GetAudioDetailPostModel, ProductDetail } from "../api";


export type IBookDetailsAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_BOOK_DETAILS_LIST",
    bookDetailsResult:ProductDetail 
}




export const bookDetailsReducer: Reducer<IBookDetailsManagementState, IBookDetailsAction> = (draft, action): IBookDetailsManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_BOOK_DETAILS_LIST":
                draft.bookDetailsResult=action.bookDetailsResult;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IBookDetailsAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<IBookDetailsAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useBookDetailsDispatcher = (): React.Dispatch<IBookDetailsAction> => {
    const bookDetailsDispatcher = React.useContext(BookDetailsDispatcherContext);
    if (!bookDetailsDispatcher) {
        throw new Error("You have to provide the BookDetails dispatcher using theBookDetailsDispatcherContext.Provider in a parent component.");
    }
    return bookDetailsDispatcher;
}


export const useBookDetailsContext = (): IBookDetailsManagementState => {
    const bookDetailsContext = React.useContext(BookDetailsStateContext);
    if (!bookDetailsContext) throw new Error("You have to provide the bookDetails context using the BookDetailsStateContext.Provider in a parent component.");
    return bookDetailsContext;
}



const ProductApi = new ProductAPIClient();

export const getBookDetailsListProduct = async (dispatcher: React.Dispatch<IBookDetailsAction>, query:GetAudioDetailPostModel ) => {
    //dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const bookDetailsResult = await ProductApi.getProductDetail( query)
       dispatcher({ type: "SET_BOOK_DETAILS_LIST", bookDetailsResult: bookDetailsResult });

    }
    catch(e){

    }
}



