import React from 'react';
import { ProductShortInfo, ProductCategory } from '../../../api';
import { Navbar, Nav, NavDropdown, Carousel } from 'react-bootstrap';
import FrontPageTabViewComponenet from '../../FrontpageTabViewComponent';
import { Console } from 'console';
// import { Tabs } from 'antd';
import { Tab, Tabs } from 'react-bootstrap';
// const { TabPane } = Tabs;

interface IFrontPageTabViewProps {
  mostratedAudios: ProductShortInfo[],
  newReleasedAudios: ProductShortInfo[],
  homeProducts: ProductCategory[] | undefined,
  onHandleCartLoad: () => void;
  onHandleWishListLoad: () => void;
  onTabkeyChange: (e: any) => void;
}

interface IFrontPageTabViewState {
  key: string,
}

const initialState = {
  key: '1'
}
const FrontPageTabView: React.FC<IFrontPageTabViewProps> = (props) => {

  let content: any[] = [];
  let contentData: any[] = [];
  let content1: any[] = [];
  let contentData1: any[] = [];
  let content2: any[] = [];
  let contentData2: any[] = [];
  const { mostratedAudios, onHandleCartLoad, onHandleWishListLoad, newReleasedAudios, onTabkeyChange, homeProducts } = props;

  const [frontPageTabViewState, setFrontPageTabViewState] = React.useState<IFrontPageTabViewState>(initialState);
  const { key } = frontPageTabViewState

  const handleTabChange = (value: any) => {
    onTabkeyChange(value);
    setFrontPageTabViewState({
      ...frontPageTabViewState,
      key: value
    });
  }

  if (!homeProducts || homeProducts.length <= 0) {
    return (
      <>
        <h4>loding...</h4>
      </>
    );
  }

  const dataIterateMostratedAudios = () => {
    let ln = mostratedAudios.length / 6;

    for (let i = 0; i <= ln; i++) {
      for (let j = 0; j < 6; j++) {
        if (mostratedAudios.length > (i * 6) + j) {
          content.push(mostratedAudios[(i * 6) + j]);
        }
        else
          break;
      }

      contentData.push(
        <Carousel.Item>
          <div>{
            content.map((product: ProductShortInfo, index: any) => {

              return (

                <FrontPageTabViewComponenet
                  key={`product_${product.audioId}`}
                  book={product}
                  onHandleCartLoad={onHandleCartLoad}
                  onHandleWishListLoad={onHandleWishListLoad}
                />


              )
            }

            )
          }
          </div>
        </Carousel.Item>
      )
      content = [];
    }

    return contentData

  }

  console.log(45, newReleasedAudios);

  const dataIterateNewReleased = () => {
    let ln = newReleasedAudios.length / 6;

    for (let i = 0; i <= ln; i++) {
      for (let j = 0; j < 6; j++) {
        if (newReleasedAudios.length > (i * 6) + j) {
          content1.push(newReleasedAudios[(i * 6) + j]);
        }
        else
          break;
      }

      contentData1.push(
        <Carousel.Item>
          <div>{
            content1.map((product: ProductShortInfo, index: any) => {

              return (

                <FrontPageTabViewComponenet
                  key={`product_${product.audioId}`}
                  book={product}
                  onHandleCartLoad={onHandleCartLoad}
                  onHandleWishListLoad={onHandleWishListLoad}
                />


              )
            }

            )
          }
          </div>
        </Carousel.Item>
      )
      content1 = [];
    }

    return contentData1

  }

  const dataIterateCategory = () => {
    let ln = homeProducts.length / 6;

    for (let i = 0; i <= ln; i++) {
      for (let j = 0; j < 6; j++) {
        if (homeProducts.length > (i * 6) + j) {
          content2.push(homeProducts[(i * 6) + j]);
        }
        else
          break;
      }

      contentData2.push(
        <Carousel.Item>
          <div>{
            content2.map((product: ProductCategory, index: any) => {

              return (

                <>
                  <div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                    <div className="tg-postbook">
                      <figure className="tg-featureimg">
                        <div className="tg-bookimg">
                          <div className="tg-frontcover">
                            <img src={"https://s3.ap-south-1.amazonaws.com/btc.audiotrial/KA7SFHQN7ZR20img-02.jpg"} alt="image description" />
                          </div>
                          <div className="tg-backcover">
                            <img src={"https://s3.ap-south-1.amazonaws.com/btc.audiotrial/KA7SFHQN7ZR20img-02.jpg"} alt="image description" />
                          </div>
                        </div>
                      </figure>
                      <a className="tg-btnaddtowishlist">
                      {/* <OrderedListOutlined /> */}
              <span>{product.title}</span>
            </a>
                    </div>
                  </div>
                </>
              );
            }

            )
          }
          </div>
        </Carousel.Item>
      )
      content2 = [];
    }

    return contentData2

  }

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k: any) => handleTabChange(k)} >

        <Tab eventKey='1' title="Most Rated Audio" >


          <div className="row" style={{ marginLeft: 1 }}>
            {

              <Carousel>
                {dataIterateMostratedAudios()}
              </Carousel>
            }
            {mostratedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

              <h4>Loding...</h4> </div> : null}


          </div>

        </Tab>
        <Tab eventKey='2' title="New Release" >

          <div className="row" style={{ marginLeft: 1 }}>
            {

              <Carousel>
                {dataIterateNewReleased()}
              </Carousel>
            }

            {newReleasedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

              <h4>Loding...</h4> </div> : null}


          </div>
        </Tab>

        <Tab eventKey='3' title="Category" >

          <div className="row" style={{ marginLeft: 1 }}>
            {

              <Carousel>
                {dataIterateCategory()}
              </Carousel>
            }

            {newReleasedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

              <h4>Loding...</h4> </div> : null}


          </div>
        </Tab>

      </Tabs>

      {/* <Tabs defaultActiveKey="1" onChange={handleTabChange}>
    <TabPane tab="Tab 1" key="1">
    <div className="row" style={{ marginLeft: 1 }}>
                        {

<Carousel>
{dataIterateMostratedAudios()}
</Carousel>
                        }
                        {mostratedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

                          <h4>Loding...</h4> </div> : null}


                      </div>
    </TabPane>
    <TabPane tab="Tab 2" key="2">
    <div className="row" style={{ marginLeft: 1 }}>
                        {

<Carousel>
  {dataIterateNewReleased()}
  </Carousel>
   }
   
   {newReleasedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

     <h4>Loding...</h4> </div> : null}


 </div>
    </TabPane>
    <TabPane tab="Tab 3" key="3">
      Content of Tab Pane 3
    </TabPane>
  </Tabs> */}

    </>
  );
};

export default FrontPageTabView;