import React from 'react';
import { AboutUsViewModel, AboutUsResultViewModel } from '../api';
// require('../myStyle/Contact.css');

interface IContactComponenetProps{
    aboutUs: AboutUsResultViewModel 
}

const ContactComponenet:React.FC<IContactComponenetProps>=(props) => {

    const {aboutUs}=props
    return (
        <>



<div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-contactus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tg-sectionhead">
                  
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="col-lg-6">
                  <div className="tg-contactdetail">
                    <div className="tg-sectionhead">
                      <h2>Get In Touch With Us</h2>
                    </div>
                    <ul className="tg-contactinfo">
                      <li>
                        <i className="icon-apartment" />
                        <address>B-12, GCDA, NGO Quarters - Mavelipuram Rd, Kakkanad, Kochi, Kerala 682030</address>
                      </li>
                      <li>
                        <i className="icon-phone-handset" />
                        <span>
                          <em>+91 8848738678</em>
                          <em>+91 8848738678</em>
                        </span>
                      </li>
                      <li>
                        <i className="icon-clock" />
                        <span>Serving 7 Days A Week From 9am - 5pm</span>
                      </li>
                      <li>
                        <i className="icon-envelope" />
                        <span>
                          <em><a href="mailto:support@domain.com">support@vocabooks.com</a></em>
                          <em><a href="mailto:info@domain.com">info@vocabooks.com</a></em>
                        </span>
                      </li>
                    </ul>
                    <ul className="tg-socialicons">
                      <li className="tg-facebook"><a href="javascript:void(0);"><i className="fa fa-facebook" /></a></li>
                      <li className="tg-twitter"><a href="javascript:void(0);"><i className="fa fa-twitter" /></a></li>
                      <li className="tg-linkedin"><a href="javascript:void(0);"><i className="fa fa-linkedin" /></a></li>
                      <li className="tg-googleplus"><a href="javascript:void(0);"><i className="fa fa-google-plus" /></a></li>
                      <li className="tg-rss"><a href="javascript:void(0);"><i className="fa fa-rss" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tg-sectionhead">
                    <h2>For Enquiries</h2>
                  </div>
                  <form className="tg-formtheme tg-formcontactus">
                    <fieldset>
                      <div className="form-group">
                        <input type="text" name="first-name" className="form-control" placeholder="First Name*" />
                      </div>
                      <div className="form-group">
                        <input type="text" name="last-name" className="form-control" placeholder="Last Name*" />
                      </div>
                      <div className="form-group">
                        <input type="email" name="email" className="form-control" placeholder="Last Name*" />
                      </div>
                      <div className="form-group">
                        <input type="text" name="subject" className="form-control" placeholder="Subject (optional)" />
                      </div>
                      <div className="form-group tg-hastextarea">
                        <textarea placeholder="Comment" defaultValue={""} />
                      </div>
                      <div className="form-group">
                        <button type="submit" className="tg-btn tg-active">Submit</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>








        
                 {/* Start Content Section */}
      {/* <section id="content">
        <div className="container cnt-bg">
          <div className="row">
            <div className="col-md-6">
              <h2 style={{    marginLeft: 45,fontSize: 22}}>Contact Form</h2>    */}
              {/* Start Contact Form */}
              {/* <form role="form" id="contactForm" className="contact-form" data-toggle="validator" style={{padding:20}}>
                <div className="form-group">
                  <div className="controls">
                    <input type="text" id="name" className="form-control" placeholder="Name" required data-error="Please enter your name" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="controls">
                    <input type="email" className="email form-control" id="email" placeholder="Email" required data-error="Please enter your email" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="controls">
                    <input type="text" id="msg_subject" className="form-control" placeholder="Phone Number" required data-error="Please enter your message subject" />
                    <div className="help-block with-errors" />
                  </div>
                </div>
                <div className="form-group">
                  <div className="controls">
                    <textarea id="message" rows={7} placeholder="Massage" className="form-control" required data-error="Write your message" defaultValue={""} />
                    <div className="help-block with-errors" />
                  </div>  
                </div>
                <button type="submit" id="submit" className="btn btn-success"> Send Message</button>
                <div id="msgSubmit" className="h3 text-center hidden" /> 
                <div className="clearfix" />   
              </form>   */}

              {/* End Contact Form */}
            {/* </div>
            <div className="col-md-6" style={{padding:20}}>
              <h2 className="big-title" style={{fontSize:20}}>Contact Info</h2>   
              <div className="information">              
                <div className="contact-datails">
                  <p> <i className="fa fa-map-marker" aria-hidden="true" />B-12, GCDA, NGO Quarters - Mavelipuram Rd,<br /> <span>Kakkanad, Kochi, Kerala 682030</span></p>
                  <p> <i className="fa fa-phone-square" aria-hidden="true" />{aboutUs.aboutUs?.phone1} </p>
                  <p> <i className="fa fa-envelope" aria-hidden="true" />{aboutUs.aboutUs?.eMail} </p>
                </div>
              </div>
              <div className="cnt-map">
                <div className="mapouter"><div className="gmap_canvas"><iframe width={520} height={370} id="gmap_canvas" src="https://maps.google.com/maps?q=B-12%2C%20GCDA%2C%20NGO%20Quarters%20-%20Mavelipuram%20Rd%2C%20Kakkanad%2C%20Kochi%2C%20Kerala%20682030&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.online-timer.net" /></div></div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End Content Section  */}
        </>
    );
};

export default ContactComponenet;