import { Reducer } from "react"
import { ISearchManagementState, SearchDispatcherContext, SearchStateContext } from "../context/SearchContext"
import React from "react";
import { ProductAPIClient, ProductSearchDataRequestModel, ProductSearchResult, ProductSelectBoxDataViewModel, ProductClient } from "../api";


export type ISearchAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo",
    searchTerm:string;
}|{
    type:"SET_HOME_PRODUCT_LIST",
    searchResult:ProductSearchResult 
}|{
    type:"SET_SELECTBOXDATA",
    selectBoxData:ProductSelectBoxDataViewModel 
}




export const searchReducer: Reducer<ISearchManagementState, ISearchAction> = (draft, action): ISearchManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
          draft.searchTerm=action.searchTerm;
            return draft;
            case "SET_SELECTBOXDATA":
draft.selectBoxData=action.selectBoxData;
                return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.searchResult=action.searchResult;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<ISearchAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}





export const useSearchDispatcher = (): React.Dispatch<ISearchAction> => {
    const searchDispatcher = React.useContext(SearchDispatcherContext);
    if (!searchDispatcher) {
        throw new Error("You have to provide the Search dispatcher using theSearchDispatcherContext.Provider in a parent component.");
    }
    return searchDispatcher;
}


export const useSearchContext = (): ISearchManagementState => {
    const searchContext = React.useContext(SearchStateContext);
    if (!searchContext) throw new Error("You have to provide the search context using the SearchStateContext.Provider in a parent component.");
    return searchContext;
}



const ProductApi = new ProductAPIClient();

export const getSearchListResult = async (dispatcher: React.Dispatch<ISearchAction>, query:ProductSearchDataRequestModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const searchResult = await ProductApi.getProducts( query)
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", searchResult: searchResult });

    }
    catch(e){

    }
}

export const setSearchtermValue = async (dispatcher: React.Dispatch<ISearchAction>, query:string ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        
       dispatcher({ type: "SET_VALUETwo", searchTerm: query });

    }
    catch(e){

    }
}

const productSelectBoxApi= new ProductClient()

export const getSelectBoxdata = async (dispatcher: React.Dispatch<ISearchAction>  ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        
        const selectBoxData = await productSelectBoxApi.getSelectboxData( )
        dispatcher({ type: "SET_SELECTBOXDATA", selectBoxData: selectBoxData });

    }
    catch(e){

    }
}


