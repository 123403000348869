import React from "react";
import PurchasedBookComponent from "./PurchasedBookComponent";
import AuthService from "../_services/AuthService";
import axios from 'axios';
import { AppUrls } from '../_environments/environment';
import { Purchase } from "../_models/Purchase";
interface IPurchasedBooksContainerProps{

}

interface IPurchasedBooksContainerState{
purchase:Purchase[]
}

const initialState = {
purchase:[]

} as IPurchasedBooksContainerState

const PurchasedBooksContainer:React.FC<IPurchasedBooksContainerProps>= (props) => {

    let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";


    const [purchasedBooksContainerState,
        setPurchasedBooksContainerState] = React.useState<IPurchasedBooksContainerState>(initialState);

        const {purchase}=purchasedBooksContainerState;

        React.useEffect(() => {
            if (1) {
                (async () => {
                    let url_ = AppUrls.PurchaseGetAll;
                    url_ = url_.replace(/[?&]$/, "");
                    let headers = {
                                            Authorization: `Bearer ${authorizationToken}`,
                                            'Content-type': 'application/json'
                          };
    
                      
                    let formData = new FormData();
               
                    
                    axios.post(url_, JSON.stringify({}), { headers: headers }).then(res => {
                        console.log(1576, res); 
                        if(res.data){ 
                            setPurchasedBooksContainerState({
                                ...purchasedBooksContainerState,
                                purchase: res.data.data
                                
                            });
                           
                        }   
                    })
                })();
            }
        }, [])
        console.log(777,purchase);
    return (
        <>
        <div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div id="tg-twocolumns" className="tg-twocolumns">
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12 pull-left">
                <div id="tg-content" className="tg-content">
                  <div className="tg-products">
                  <div className="tg-sectionhead">
        <h2>PuChased Items</h2>
      </div>

      <div className="row">
       {purchase.length!==0?
       purchase.map((purchaseData,index)=>{
return(
    <PurchasedBookComponent
    purchaseData={purchaseData}
    />
)
       })
        :'No item Purchased'

       }
        </div>
</div></div></div></div></div></div></div>
        </>
    );
};
export default PurchasedBooksContainer;