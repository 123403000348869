import React from 'react';
import { getSearchListResult, useSearchDispatcher, useSearchContext } from '../action/SearchAction';
import { ProductSearchDataRequestModel } from '../api/controller';
import SearchComponent from './SearchComponent';
import { Col, Row } from 'antd';
import * as InfiniteScroll from 'react-infinite-scroll-component';




interface ISearchContainerProps {
    searchTerm: string;
    author:string;
    narrator:string;
    publisher:string;
    translator:string;
    category:string;
    onHandleRestCall:()=>void;
}

interface ISearchContainerState {
    searchedValue: string;
    categoryValue:string;
    authorValue:string;
    narratorValue:string;
    publisherValue:string;
    translatorValue:string;
    isLoaded:boolean;
}

const initialState = {
    searchedValue: "",
    categoryValue:"",
    authorValue:"",
    narratorValue:"",
    publisherValue:"",
    translatorValue:"",
    isLoaded:false,
} as ISearchContainerState

const SearchContainer: React.FC<ISearchContainerProps> = (props: any) => {
    const { searchTerm,author,narrator,translator,publisher,category,onHandleRestCall } = props
    const searchDispatcher = useSearchDispatcher();
    const searchContext = useSearchContext();

    const [searchContainerState, setSearchContainerState] = React.useState<ISearchContainerState>(initialState)

    const { value, visible, searchResult, } = searchContext;

const {authorValue,categoryValue,narratorValue,publisherValue,translatorValue,isLoaded}=searchContainerState
    console.log(29,category)


    React.useEffect(() => {
        (async () => {
           onHandleRestCall();
                    
        })();
    }, [])



    React.useEffect(() => {
        (async () => {
            setSearchContainerState({
                ...searchContainerState,
                categoryValue:category,
                authorValue:author,
                narratorValue:narrator,
                publisherValue:publisher,
                translatorValue:translator,
                isLoaded:true
            });      
             console.log(556,categoryValue)       
        })();
    }, [category,author,narrator,publisher,translator])

    React.useEffect(() => {
        if(isLoaded || searchTerm!==""){
        (async () => {
            console.log(199)
           
            console.log(31,publisher);

            await getSearchListResult(searchDispatcher,
                {
                    SearchCriteria: { CategoryIds:categoryValue!==""?[categoryValue]:[] ,authorIds:authorValue,publisherIds:publisherValue!==""?[publisherValue]:[] ,
                        narratorIds:narratorValue,translatorIds:translatorValue,
                        LanguageIds: [], CurrencyId: "1" },
                    Page: 1,
                    PageSize: 20,
                    SearchTerm: searchTerm
                } as {} as ProductSearchDataRequestModel);

                setSearchContainerState({
                    ...searchContainerState,
                    categoryValue:"",
                    authorValue:"",
                    narratorValue:"",
                    publisherValue:"",
                    translatorValue:"",
                    isLoaded:false
                });     

        })();
    }
    }, [searchTerm,isLoaded])

console.log(88,value)
    console.log(344, searchTerm);
    console.log(345, searchResult);

const handleLoad =()=>{

}



    let content: any[] = [];
    let contentData: any[] = [];

    const dataIterate = () => {
        if (searchResult.searchResult?.data?.length != undefined) {
            let ln = searchResult.searchResult?.data?.length / 6;

            for (let i = 0; i <= ln; i++)
             {

                for (let j = 0; j < 6; j++) {
                    if (searchResult.searchResult?.data?.length > (i * 6) + j)
                     {
                        content.push(searchResult.searchResult?.data[(i * 6) + j]);
                    }
                    else
                        break;
                }

                contentData.push(
                    <Col>
                        {
                            content.map((searchItem, index) => {
                                return (
                                    <>
                                        <SearchComponent
                                            key={`searchItem_${searchItem.audioId}`}
                                            searchItem={searchItem}
                                        />
                                    </>
                                )
                            })
                        }
                    </Col>
                )

                 content = [];
            }

            return contentData

        }
    }

    return (
        <>
   

   <div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div id="tg-twocolumns" className="tg-twocolumns">
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-12 pull-left">
                <div id="tg-content" className="tg-content">
                  <div className="tg-products">


                  <div className="tg-sectionhead">
        <h2>Products List</h2>
      </div>
      <div className="tg-productgrid">
      </div>
      <div className="tg-refinesearch">
        <span>showing 1 to 8 of 20 total</span>
        <form className="tg-formtheme tg-formsortshoitems">
          <fieldset>
            <div className="form-group">
              <label>sort by:</label>
              <span className="tg-select">
                <select>
                  <option>name</option>
                  <option>name</option>
                  <option>name</option>
                </select>
              </span>
            </div>
            <div className="form-group">
              <label>show:</label>
              <span className="tg-select">
                <select>
                  <option>8</option>
                  <option>16</option>
                  <option>20</option>
                </select>
              </span>
            </div>
          </fieldset>
        </form>
      </div>
      <div className="row">
     
<Row>

{

    dataIterate()
}
</Row>




</div></div></div></div></div></div></div> </div>

        </>
    );
};

export default SearchContainer;