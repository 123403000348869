import React from 'react';


interface AdminRequestComponentProps{

}

interface AdminRequestComponentState{

}
const initialState={

}
const AdminRequestComponent:React.FC<AdminRequestComponentProps>=(props) => {

    const [AdminRequestComponentState,setAdminRequestComponentState]=React.useState<AdminRequestComponentState>(initialState)
    return (
        <>
             <section className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-sectionhead" >
              <div className="row">
                <div className="col-lg-6">
                  <input type="search" className="form-control table-filter" placeholder="Enter Keywords..." />
                </div>
                <div className="col-lg-6">
                  <button>Send</button>
                </div>
              </div>
              <table className="table-responsive table-striped" cellPadding="5px">
                <tbody><tr>
                    <th><input type="checkbox" id="selectall" className="regular-checkbox" /><label htmlFor="selectall" /></th>
                    <th className="align-left">Serial No</th>	
                    <th className="align-left">Name</th>
                    <th className="align-left">Email ID</th>
                    <th className="align-left">Phone No</th>
                    <th className="align-left">Send</th>
                  </tr>
                  <tr>
                    <td  align="center"><input type="checkbox" name="name" className="regular-checkbox name" value={1} id="checkbox-1-1" /><label htmlFor="checkbox-1-1" /></td>
                    <td className="align-left">1</td>
                    <td className="align-left">Vinod kumar</td>
                    <td className="align-left">vinod@gmail.com</td>
                    <td className="align-left">+91 123 456 789</td>
                    <td className="align-left">
                      <button className="btn btn-4 btn-sep icon-send">Send</button>
                    </td>
                  </tr>
                  <tr>
                    <td align="center"><input type="checkbox" name="name" className="regular-checkbox name" value={2} id="checkbox-1-2" /><label htmlFor="checkbox-1-2" /></td>
                    <td className="align-left">2</td>
                    <td className="align-left">Sharad Gupta</td>
                    <td className="align-left">sgupta@gmail.com</td>
                    <td className="align-left">+91 123 456 789</td>
                    <td className="align-left">
                      <button className="btn btn-4 btn-sep icon-send">Send</button>
                    </td>
                  </tr>
                  <tr>
                    <td align="center"><input type="checkbox" name="name" className="regular-checkbox name" value={3} id="checkbox-1-3" /><label htmlFor="checkbox-1-3" /></td>
                    <td className="align-left">3</td>
                    <td className="align-left">Nitin Bhardwaj</td>
                    <td className="align-left">bnitin@gmail.com</td>
                    <td className="align-left">+91 123 456 789</td>
                    <td className="align-left">
                      <button className="btn btn-4 btn-sep icon-send">Send</button>
                    </td>
                  </tr>
                  <tr>
                    <td align="center"><input type="checkbox" name="name" className="regular-checkbox name" value={4} id="checkbox-1-4" /><label htmlFor="checkbox-1-4" /></td>
                    <td className="align-left">4</td>
                    <td className="align-left">Aman</td>
                    <td className="align-left">aman@gmail.com</td>
                    <td className="align-left">+91 123 456 789</td>
                    <td className="align-left">
                      <button className="btn btn-4 btn-sep icon-send">Send</button>
                    </td>
                  </tr>
                  <tr>
                    <td align="center"><input type="checkbox" name="name" className="regular-checkbox name" value={5} id="checkbox-1-5" /><label htmlFor="checkbox-1-5" /></td>
                    <td className="align-left">5</td>
                    <td className="align-left">Rahul Sharma</td>
                    <td className="align-left">rahuls@gmail.com</td>
                    <td className="align-left">+91 123 456 789</td>
                    <td className="align-left">
                      <button className="btn btn-4 btn-sep icon-send">Send</button>
                    </td>
                  </tr>
                </tbody></table>
              <ul className="pagination pagination-lg">
                <li className="page-item"><a className="page-link" href="#" aria-label="Previous">«</a></li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item active"><a className="page-link " href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">4</a></li>
                <li className="page-item"><a className="page-link" href="#">5</a></li>
                <li className="page-item"><a className="page-link" href="#">6</a></li>
                <li className="page-item"><a className="page-link" href="#">7</a></li>
                <li className="page-item"><a className="page-link" href="#">8</a></li>
                <li className="page-item"><a className="page-link" href="#">9</a></li>
                <li className="page-item"><a className="page-link" href="#" aria-label="Next">»</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
        </>
    );
};

export default AdminRequestComponent;