import React from 'react';
import AdminRequestComponent from './AdminRequestComponent';

interface AdminRequestContainerProps{

}

interface AdminRequestContainerState{

}
const initialState={

}
const AdminRequestContainer:React.FC<AdminRequestContainerProps>=(props) => {

    const [AdminRequestContainerState,setAdminRequestContainerState]=React.useState<AdminRequestContainerState>(initialState)
    return (
        <>
            <AdminRequestComponent/>
        </>
    );
};

export default AdminRequestContainer;