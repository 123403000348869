import React from 'react';
import CartComponent from './CartComponent';
import { useCartDispatcher, useCartContext, getCartItemsList, deleteCartItem } from '../action/CartAction';
import { CartDataRequestModel, CartPostModel } from '../api';
import { useUserDispatcher, useUserContext, getUserListResult } from '../action/UserAction';

interface ICartContainerProps{
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
}

interface ICartContainerState{
    isCarted:boolean,
    isCartitemRemoved:boolean;
    cartId:string;
    totalAmount:number;
}

const initialState={
    isCarted:false,
    isCartitemRemoved:false,
    cartId:"",
    totalAmount:0
} as ICartContainerState

const CartContainer:React.FC<ICartContainerProps> = (props) => {
const {onHandleCartLoad,onHandleWishListLoad}=props
    const cartDispatcher = useCartDispatcher();
    const cartContext = useCartContext();
    const { value, visible, cartItems} = cartContext;

    const userDispatcher = useUserDispatcher();
    const userContext = useUserContext();
    const { users } = userContext

    const [cartContainerState,
    setCartContainerState]= React.useState<ICartContainerState>(initialState)
    const {isCarted,isCartitemRemoved,cartId}=cartContainerState

    React.useEffect(() => {
        (async () => {
            await getUserListResult(userDispatcher)
            setCartContainerState({
                ...cartContainerState,
                 isCarted:true,
            });
        })();
    }, [])


    React.useEffect(() => {
        if(isCarted ||isCartitemRemoved ){
        (async () => {   
            await getCartItemsList(cartDispatcher,{currencyId:users.defaultCurrencyId} as CartDataRequestModel );
       
            setCartContainerState({
                ...cartContainerState,
                isCartitemRemoved:false,
            });
         })();
        //  console.log(9999,users.defaultCurrencyId)
        }
    }, [isCarted,isCartitemRemoved])

    React.useEffect(() => {
        if(isCarted){
        (async () => {   
            await deleteCartItem(cartDispatcher,{audioId:cartId} as CartPostModel );
            onHandleCartLoad();
            onHandleWishListLoad();
            setCartContainerState({
        ...cartContainerState,
        isCartitemRemoved:true,
    });
         })();
       
        }
    }, [cartId])

const OnHandleCartItemRemoved=(cartId:any)=>{
    setCartContainerState({
        ...cartContainerState,
      
        cartId:cartId
    });
}

    return (
        <>
            <CartComponent
            cartItems={cartItems}
            OnHandleCartItemRemoved={OnHandleCartItemRemoved}
            />
        </>
    );
};

export default CartContainer;