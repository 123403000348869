
import * as React from 'react';
import { Form, Icon, Input, Checkbox, Row, Col, Button, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// import '../myStyle/Login.css';
import { Spinner } from 'react-bootstrap';
import { Link, Route } from 'react-router-dom';
import { url } from 'inspector';
import { NavigateToItem } from 'typescript';
import { useHistory } from 'react-router';

export interface ILoginFormProps extends FormComponentProps {
  onHandleUserChange: (Value: any) => void;
  onHandlePasswordChange: (value: any) => void;
  onHandleSubmit: () => void;
  isBusy: boolean;
  loginMsg: string;
}


const LoginForm: React.FC<ILoginFormProps> = (props) => {

  const { getFieldDecorator } = props.form;
  const { onHandleUserChange, onHandlePasswordChange, onHandleSubmit, isBusy, loginMsg } = props;

  const handleChangeUser = (e: any) => {
    onHandleUserChange(e.target.value);
  }

  const handleChangePassword = (e: any) => {
    onHandlePasswordChange(e.target.value);
  }
  const history = useHistory();

  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
       
    onHandleSubmit(  
   );
   
      }})
   
    //console.log("submit clicked")
  
  }
  console.log(isBusy)

 
  var imgUrl = require('../images/login-bg.jpg')

  return (
    <>
    <div className="tg-sectionspace tg-haslayout" style={{ backgroundImage: "url(" + imgUrl + ")" }}>
        <div className="container">
          <div className="row">
            <div className="tg-contactus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-page" style={{margin: '10% auto'}}>
                  <div className="form">
                    <label className="title">LOGIN</label>
                    <Form className="login-form" onSubmit={handleSubmit}>

<Form.Item key="1" >
  {
    getFieldDecorator('Title', {

      rules: [
        { required:true, message: "please Enter Email!" },
        { type:'email', message: "please Enter a Valid Email" },
  ],
    })(<Input placeholder="Email"
      name="email"
      onChange={handleChangeUser}
    />)
  }
</Form.Item>
<Form.Item key="1" >
  {
    getFieldDecorator('password', {

      rules: [
        {
      required:true,
      message: "please Enter Password!"
        }
    ]
    })(< Input.Password placeholder="Password"
      name="password"
      onChange={handleChangePassword}
    />
    )
  }
</Form.Item>




<button type="submit"> {isBusy ?
  (<Spinner animation="border" variant="warning" />) : 'Login'}</button>
<p className="message">Not registered? <Link to="/register">
  <a href="#">Create an account</a></Link>

</p>


</Form>
<Checkbox style={{color:'white'}} defaultChecked={true} >Remember me</Checkbox>
<Link to='/forgot_password' style={{color:'green',fontSize:'small'}} >ForgotPassword?</Link>
<h6 style={{ color: 'red' }}>{loginMsg}</h6>
                    {/* <form className="login-form">
                      <input type="text" placeholder="Username" />
                      <input type="password" placeholder="Password" />
                      <button>login</button>
                      <p className="message">Not registered? <a href="#">Create an account</a></p>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="tg-sectionspace tg-haslayout " style={{ backgroundImage: "url(" + imgUrl + ")" }}>
        <div className="container">
          <div className="row">
            <div className="tg-contactus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-page" style={{ margin: '10% auto' }}>


                  <div className="form">
                    <label className="title">LOGIN</label>





                    <Form className="login-form" onSubmit={handleSubmit}>

                      <Form.Item key="1" >
                        {
                          getFieldDecorator('Title', {

                            rules: [
                              { required:true, message: "please Enter Email!" },
                              { type:'email', message: "please Enter a Valid Email" },
                        ],
                          })(<Input placeholder="Email"
                            name="email"
                            onChange={handleChangeUser}
                          />)
                        }
                      </Form.Item>
                      <Form.Item key="1" >
                        {
                          getFieldDecorator('password', {

                            rules: [
                              {
                              pattern: /(?=.*?[a-z])/,message: `At least one lower case English letter!`
                            },
                            { pattern: /^(?=.*?[A-Z])/, message: "At least one upper case!" },
                            { pattern: /(?=.*?[0-9])/, message: "At least one digit!" },
                            { pattern: /(?=.*?[#?!@$%^&*-])/, message: "At least one special character!" },
                            { pattern: /.{8,}/, message: "Minimum eight in length!" },
                          ]
                          })(< Input.Password placeholder="Password"
                            name="password"
                            onChange={handleChangePassword}
                          />
                          )
                        }
                      </Form.Item>
                      <Checkbox defaultChecked={true} >Remember me</Checkbox>



                      <button type="submit"> {isBusy ?
                        (<Spinner animation="border" variant="warning" />) : 'Login'}</button>
                      <p className="message">Not registered? <Link to="/register">
                        <a href="#">Create an account</a></Link>

                      </p>
                      <h6 style={{ color: 'red' }}>{loginMsg}</h6>
                    </Form>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}



      {/* 
  <div className="page-wrapper bg-red p-t-180 p-b-100 font-robo">
 <div className="wrapper wrapper--w960">
   <div className="card card-2" style={{marginTop:25}}>
     <div className="card-heading" />
     <div className="card-body">
       <h2 className="title">LOGIN </h2>
       <form  onSubmit={handleSubmit}>  
         <div className="input-group">
           <input className="input--style-2" type="text" placeholder="User Name" name="name" onChange={handleChangeUser} />
         </div>
         <div className="input-group">
           <input className="input--style-2" type="password" placeholder="Password" name="name" onChange={handleChangePassword} />
         </div>
         <div className="p-t-30">
           <button className="btn btn--radius btn--green" type="submit">
             {isBusy?  
          (<Spinner animation="border" variant="warning" />) :'Login'}
          </button>

         </div>
       </form>
     </div>
   </div>
 </div>
</div>  */}
    </>
  )
}
//  export default LoginForm
export const LoginEditForm = Form.create<ILoginFormProps>()(LoginForm);
