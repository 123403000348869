import React from 'react';
import { useHomeDispatcher, useHomeContext } from '../../../action/HomeAction';
import HomeBookPageCategoryComponent from './HomeBookPageCategoryComponent';
import { RouteComponentProps, withRouter, Route } from 'react-router';
import { ProductCategory } from '../../../api';
import HomeBookPageCategoryViewComponent from './HomeBookPageCategoryViewComponent';

interface IHomeBookPageContainerProps  extends RouteComponentProps{
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
    isWishListLoad:number,
    isCartLoad:number,
}

interface IHomeBookPageContainerState{
    isViewClick:boolean;
    productsData:ProductCategory,
    category:string;
    }
    
    const initialState={ 
    isViewClick:false,
    productsData:[],
    category:''
    }

const HomeBookPageContainer:React.FC<IHomeBookPageContainerProps>=(props) => {
  
    const [homeBookPageContainerState,
        setHomeBookPageContainerState]=React.useState(initialState);

        const {isViewClick,productsData,category}=homeBookPageContainerState
const {onHandleCartLoad,onHandleWishListLoad,isWishListLoad,isCartLoad}=props;
    const homeDispatcher = useHomeDispatcher();
    const homeContext = useHomeContext();
    const { value, visible, homeProducts } = homeContext;

let content: any[] = [];

    React.useEffect(() => { 
        (async () => {   
           
         })();  
    }, [])

const OnHandleViewClick=(category:any)=>{
 content=homeProducts.bookCategory!=undefined? homeProducts.bookCategory?.filter(books=>books.title=='Autobiography'):[]
 console.log(7896,content);

    setHomeBookPageContainerState({
        ...homeBookPageContainerState,
        isViewClick:true,
        category:category
        
    });
   
}
    console.log(3000,homeProducts)
    return (
        <>
       {homeProducts.bookCategory!=undefined?(homeProducts.bookCategory.length===0?<div style={{marginLeft:'580px '}}> 

 <h4>Loding...</h4> </div>:null):null}

        {!isViewClick? 

homeProducts.bookCategory?.map((homeProduct, index) => {
    return (
        <>
            <HomeBookPageCategoryComponent
                key={`product_${homeProduct.id}`}
                book={homeProduct}
                OnHandleViewClick={OnHandleViewClick}
                onHandleCartLoad={onHandleCartLoad}
                onHandleWishListLoad={onHandleWishListLoad}
                isWishListLoad={isWishListLoad}
                isCartLoad={isCartLoad}
            />
        </>
    )
}):

<HomeBookPageCategoryViewComponent
content={homeProducts.bookCategory!=undefined? homeProducts.bookCategory?.filter(books=>books.title==category):[]}
onHandleCartLoad={onHandleCartLoad}
onHandleWishListLoad={onHandleWishListLoad}
/>
}
           
        </>
    );
};

export default withRouter(HomeBookPageContainer);
