import { Reducer } from "react"
import { IHomeManagementState, HomeDispatcherContext, HomeStateContext } from "../context/HomeContext"
import React from "react";
import { ProductAPIClient, GetHomeProductsPostModel, HomeDetailViewModel } from "../api";


export type IHomeAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_HOME_PRODUCT_LIST",
    homeProducts:HomeDetailViewModel 
}




export const homeReducer: Reducer<IHomeManagementState, IHomeAction> = (draft, action): IHomeManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.homeProducts=action.homeProducts;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IHomeAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<IHomeAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useHomeDispatcher = (): React.Dispatch<IHomeAction> => {
    const homeDispatcher = React.useContext(HomeDispatcherContext);
    if (!homeDispatcher) {
        throw new Error("You have to provide the Home dispatcher using theHomeDispatcherContext.Provider in a parent component.");
    }
    return homeDispatcher;
}


export const useHomeContext = (): IHomeManagementState => {
    const homeContext = React.useContext(HomeStateContext);
    if (!homeContext) throw new Error("You have to provide the home context using the HomeStateContext.Provider in a parent component.");
    return homeContext;
}



const ProductApi = new ProductAPIClient();

export const getHomeListProduct = async (dispatcher: React.Dispatch<IHomeAction>, query:GetHomeProductsPostModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const homeProducts = await ProductApi.getHomeData( query)
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", homeProducts: homeProducts });

    }
    catch(e){

    }
}



