import React from 'react';

const TermsAndConditions = () => {
    return (



      <section className="tg-sectionspace tg-haslayout">
      <div className="container">
        <div className="row">
          <div className="tg-aboutus">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Vocabooks - Terms and Conditions for Recurring Payments for auto renewal of Vocabooks Membership plans</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Recurring Payments</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Eligible Payment Methods</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Enable Recurring Payment</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Membership</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Disable / Cancel Recurring Payments</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Notification and Communication</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
              <div className="tg-aboutusshortcode">
                <div className="tg-sectionhead">
                  <h4>Disclaimer of Liability</h4>
                </div>
                <div className="tg-description">
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>








        // <div style={{paddingLeft:120,paddingRight:120,paddingTop:60,textAlign:"justify"}}>
        //     <h4 style={{marginBottom:9}}>
        //    Vocabooks - Terms and Conditions for Recurring Payments for auto renewal of Vocabooks Membership plans</h4>

        //   <p>Here are the terms and conditions for Recurring Payments for auto renewal of Vocabooks membership plans ("Terms").
        //        These Terms are between you and Amma Communications Private Limited ("Vocabooks", "We" or "Us") and/or its affiliates
        //         and govern our respective rights and obligations in relation to recurring payment services offered by us on www.vocabooks.com ("Vocabooks.com"),
        //         for auto renewal of Vocabooks membership plans ("Membership").</p>

        //  <p>Your use of Vocabooks products and services is also governed by the agreements as well as all other applicable terms,
        //       conditions, limitations, and requirements on the Vocabooks.com website, which (as changed over time) are incorporated into these Terms.
          
        //     Your Membership will be governed by the Vocabooks Service Conditions of Use.</p><br/><br/>

        //     <h4 style={{marginBottom:7}}>Recurring Payments</h4>   

        //     <p>Vocabooks may make available an option to customers to make payments on a recurring basis ("Recurring Payments") in respect of the Membership
        //          that is offered by Vocabooks and/or its affiliate(s). It is clarified that the Membership is provided on a subscription basis, and a customer
        //           is required to make periodic payments to continue to avail the services and the benefits of the Membership.</p>

        //  <p>   Vocabooks or its affiliates may, at any time, without any notice to you, discontinue offering Recurring Payments for the Membership.</p>

        //     You may choose, but will not be bound, to avail Recurring Payments for the payments towards your Membership. In the event you opt for Recurring Payments,
        //      you agree to be bound by these Terms in addition to the terms and conditions applicable to your Membership.
        //      <br/><br/>

        //      <h4 style={{marginBottom:7}}>  Eligible Payment Methods</h4>


        //    You can opt for Recurring Payments only in the event a payment method registered with your Vocabooks.com  
        //    account and selected by you for the purpose of Recurring Payments is
        //     current, valid and acceptable (for the purpose) to us. Such method is hereinafter referred to as "Payment Method".


        //     We reserve the right to decide the Payment Methods eligible for Recurring Payments.
        //      We may also choose to extend the Recurring Payments to selected Payment Methods / products offered by a bank. 
        //      In other words, our recognition of a specific Payment Method / product offered by a particular bank would not
        //     imply our recognition of other Payment Methods / products offered by such bank.

        //     Vocabooks may provide you with the option to change your Payment Method for payment of Membership fees using
        //     Recurring Payments, provided however such Payment Method is eligible for Recurring Payments. In the event the
        //      option is made available by Vocabooks and in case of such change, to activate Recurring Payments through such
        //       new Payment Method, you will need to enable Recurring Payments on such new Payment Method.


        //     You are solely responsible for the accuracy of the information about the Payment Method provided by you, 
        //     including without limitation, card (credit / debit) details.<br/><br/>

        //   <h4 style={{marginBottom:7}}>  Enable Recurring Payment</h4>

        //     We may offer you the ability to enable Recurring Payments at the time you initiate, renew or otherwise
        //      make payments towards the Membership.

        //     If you select the Recurring Payments option, your Membership will auto renew for such Membership period(s)
        //      chosen by you and you will be charged automatically for the Membership fees, through the Payment Method 
        //      registered with your Vocabooks.com account and selected by you for Recurring Payments, on the last day of
        //       each Membership period. For avoidance of any doubt, it is clarified that auto renewal will be effective 
        //       (if you specifically opt for the same), upon the expiry of your then current Membership period.

        //     Your Membership may begin with a free trial or promotional period, if you choose to opt for and enable
        //      Recurring Payments for your Membership for payments of Membership fees on expiry of such period. Under 
        //      such free trial or promotional period, you may not be required to pay any Membership fees or may be 
        //      required to pay a discounted amount of Membership fees.

        //     Before Recurring Payments is effected for your Membership, a token amount may be charged on the Payment
        //      Method proposed to be used by you (for Recurring Payments), for verification / authentication purposes. 
        //      Once the verification / authentication is successful, you will be registered for Recurring Payments and 
        //      the token amount charged will be refunded to you.

        //     You agree, understand and acknowledge that Vocabooks may engage third party payment processors / gateway
        //      service providers to facilitate processing of Recurring Payments. Accordingly, you may be required to 
        //      follow / adhere any terms and conditions of such third party payment processors / gateway service providers,
        //       as communicated to you, from time to time.<br/><br/>

        //   <h4 style={{marginBottom:7}}>  Membership</h4>

        //     Membership will be made available to you, upon payment being charged to your Payment Method.
        //     Once Recurring Payments has been enabled for your Membership, you authorize us or the third party
        //      through which you transact to charge the Membership fees for the next Membership period(s) during
        //       the subsistence of your Membership, unless you cancel or disable Recurring Payment, by means specified
        //        by us or the third party through which you transacted and applicable at such time. In this case your
        //         Membership will not renew automatically.

        //     In case you cancel your Membership before the end of the then current Membership period, you will not be
        //      entitled to any compensation, monetary or otherwise, from Vocabooks and/or its affiliates for the unutilized 
        //      Membership period.. The amount of Membership fees shall be governed by the terms of the Membership, and you 
        //      acknowledge that the same is subject to change.<br/><br/>

        //               <h4 style={{marginBottom:7}}>  Disable / Cancel Recurring Payments</h4>

        //     You may cancel or disable Recurring Payments for your Membership either in the settings / preferences 
        //     of your Vocabooks.com account or by contacting Vocabooks customer support. If you signed up for your
        //      Vocabooks Membership using the Vocabooks iOS mobile application, please visit this page for information
        //       on how to cancel the Membership. For avoidance of any doubt, it is clarified that if you cancel or 
        //       disable Recurring Payments or auto renewal of your Membership, your then on-going Membership will 
        //       continue for the remainder of the Membership period, however in the event you cancel or disable 
        //       Recurring Payments during the free trial or promotional period, any unavailed period shall lapse and 
        //       will not be available to you.

        //     If you cancel or disable Recurring Payments or auto renewal of your Membership, your Membership will 
        //     not renew at the end of your then current Membership period. However, in case one of your Recurring 
        //     Payments is declined for any reason whatsoever, including expiry of your card (credit / debit), you 
        //     agree that Vocabooks may use any of your cards on file with Vocabooks and Vocabooks.com for the purpose 
        //     of processing the said payment. If all cards we have on file for you are declined for payment of your
        //      Membership or subscription fees, we may cancel your Membership or subscription, as applicable, unless
        //       you provide us with a new card. If you provide us with a new card and are successfully charged before
        //        your Membership or subscription is cancelled, your new Membership or subscription period will be based
        //         on the original renewal date and not the date of the successful charge.<br/><br/>

        //    <h4 style={{marginBottom:7}}> Notification and Communication</h4>

        //     You authorize us to communicate with you through emails in connection with your Membership and/or Recurring
        //      Payments. You acknowledge that we may also communicate with you through our affiliate(s) providing the
        //       Membership or the benefits of the Membership to you.<br/><br/>

        //    <h4 style={{marginBottom:7}}> Disclaimer of Liability</h4>

        //     You agree that we will not be liable for any losses or damages suffered by you on account of your use of
        //      Recurring Payments for the Membership, including as a result of any fraud in connection with any payment
        //       of Membership fee using your Payment Method.<br/><br/>


        // </div>
    );
};

export default TermsAndConditions;