import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { IProductReviewAction, productReviewReducer } from "../action/ProductReviewAction";
import { useImmerReducer } from "use-immer";
import { ProductReviewResultModel } from "../api/controller";
export const ProductReviewDispatcherContext = React.createContext<React.Dispatch<IProductReviewAction> | null>(null);
export const ProductReviewStateContext = React.createContext<IProductReviewManagementState | null>(null);

export interface IProductReviewManagementProps extends RouteComponentProps<any> { }

export interface IProductReviewManagementState {
    visible:boolean;
    value:number;
    productReviewResult:ProductReviewResultModel
}

export const initialProductReviewManagementState = {
    visible:false,
    value:0,
    productReviewResult:{} as ProductReviewResultModel,
} as IProductReviewManagementState;

export const ProductReviewContextProvider: React.FC = ({ children }) => {
    const [productReviewState, dispatcher] = useImmerReducer(productReviewReducer, initialProductReviewManagementState);

    return (
        <ProductReviewDispatcherContext.Provider value={dispatcher}>
            <ProductReviewStateContext.Provider value={productReviewState}>
                {children}
            </ProductReviewStateContext.Provider>
        </ProductReviewDispatcherContext.Provider>
    )
}