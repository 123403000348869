import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { IHomeAction, homeReducer } from "../action/HomeAction";
import { useImmerReducer } from "use-immer";
import { HomeDetailViewModel } from "../api/controller";

console.log("Inside HomeContext.tsx");
export const HomeDispatcherContext = React.createContext<React.Dispatch<IHomeAction> | null>(null);
export const HomeStateContext = React.createContext<IHomeManagementState | null>(null);

export interface IHomeManagementProps extends RouteComponentProps<any> { }

export interface IHomeManagementState {
    visible:boolean;
    value:number;
    homeProducts:HomeDetailViewModel
}

export const initialHomeManagementState = {
    visible:false,
    value:0,
    homeProducts:{} as HomeDetailViewModel,
} as IHomeManagementState;

export const HomeContextProvider: React.FC = ({ children }) => {
    const [homeState, dispatcher] = useImmerReducer(homeReducer, initialHomeManagementState);

    return (
        <HomeDispatcherContext.Provider value={dispatcher}>
            <HomeStateContext.Provider value={homeState}>
                {children}
            </HomeStateContext.Provider>
        </HomeDispatcherContext.Provider>
    )
}