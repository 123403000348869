import React, { useState } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Select } from 'antd'
import {Country , State , City} from 'country-state-city'
import {ICountry , IState , ICity} from 'country-state-city'
// require('../myStyle/transaction.css')


const { Option } = Select;
const country1 = Country.getAllCountries();
interface PackageTransactionComponentProps extends FormComponentProps {
  total: number;
  onHandleChangeAmount: (value: any) => void;
  onHandleChangeCurrency: (value: any) => void;
  onHandleChangeBillingType: (value: any) => void;
  onHandleChangeBillingName: (value: any) => void;
  onHandleChangeBillingEmail: (value: any) => void;
  onHandleChangeBillingStates: (value: any) => void;
  onHandleChangeBillingCity: (value: any) => void;
  onHandleChangeBillingZip: (value: any) => void;
  onHandleChangeBillingCountry: (value: any) => void;
  onHandleChangeBillingPhone: (value: any) => void;
  onHandleChangeBillingAdress: (value: any) => void;
  onHandleSaveButtonClicks: () => void;
}

const PackageTransactionComponent: React.FC<PackageTransactionComponentProps> = (props) => {

  const { getFieldDecorator } = props.form;
  const [countryid , setCountryid] = useState("0");
const [stateid , setStateid] = useState("0");

  const { onHandleChangeAmount, onHandleChangeBillingAdress,
    onHandleChangeBillingCity, onHandleChangeBillingCountry, onHandleChangeBillingEmail,
    onHandleChangeBillingName, onHandleChangeBillingPhone, onHandleChangeBillingStates,
    onHandleChangeBillingType, onHandleChangeBillingZip, onHandleChangeCurrency, onHandleSaveButtonClicks, total
  } = props

  const handleSaveButtonClicks = (e: any) => {
    e.preventDefault();
    onHandleSaveButtonClicks();
  }

  const handleChangeAmount = (e: any) => {
    onHandleChangeAmount(e.target.value);
  }

  const handleChangeCurrency = (value: any) => {
    onHandleChangeCurrency(value);
  }

  const handleChangeBillingType = (value: any) => {
    onHandleChangeBillingType(value)
  }

  const handleChangeBillingName = (e: any) => {
    onHandleChangeBillingName(e.target.value);
  }

  const handleChangeBillingEmail = (e: any) => {
    onHandleChangeBillingEmail(e.target.value);
  }

  const handleChangeBillingState = (e: any) => {
    const StateName = State.getStateByCodeAndCountry(e.target.value,countryid);
    console.log("SateIdrrrrrrrrrrrrrr",StateName?.name)
    onHandleChangeBillingStates(StateName?.name);
    const getStateid = e.target.value
    
    setStateid(getStateid);
  }

  const handleChangeBillingCity = (e: any) => {
    onHandleChangeBillingCity(e.target.value)
  }

  const handleChangeBillingZip = (e: any) => {
    onHandleChangeBillingZip(e.target.value);
  }

  // const handleChangeBillingCountry = (e: any) => {
  //   onHandleChangeBillingCountry(e.target.value)
  // }

  const handleChangeBillingCountry = (e: any) => {
   
    const CountryName = Country.getCountryByCode(e.target.value);
    console.log("CountryIdrrrrrrrrrrrrrr",CountryName?.name)
    onHandleChangeBillingCountry(CountryName?.name)
    const getCountryid = e.target.value
    
    setCountryid(getCountryid);
    console.log("wwwwwwwwwwwwww",State.getStatesOfCountry(getCountryid))
  }
  const handleChangeBillingPhone = (e: any) => {
    onHandleChangeBillingPhone(e.target.value);
  }

  const handleChangeBillingAdress = (e: any) => {
    onHandleChangeBillingAdress(e.target.value)
  }


  return (
    <>
      <div className="container frm-style">
        <form onSubmit={handleSaveButtonClicks}>
          <h2>Billing Form</h2><br />
          {/* <div className="row"> */}
            {/* <div className="col-md-6"> */}

              {/*  col-md-6   */}
              {/* <div className="col-md-6">
              <div className="form-group">

             <Form.Item key="34" label="Currency">
                        {
                            getFieldDecorator('Currency', {
                                
                                rules: [{
                                    required: true,
                                    message: 'Please Select  Currency!'
                                }],
                            })(
                                <Select
                                    placeholder="Select one"
                                    style={{}}
                                    showSearch={true}
                                    onChange={handleChangeCurrency}
                                    optionFilterProp="children">
                                    
                                      <Option key='$INR' value="INR">INR</Option>
                                    
                                    
                                </Select>
                            )
                        }
                    </Form.Item> 

                
              </div>
            </div> */}
              {/*  col-md-6   */}
            {/* </div> */}
            <div className="row">
              <div className="col-md-6">

                <div className="form-group">
                  <label htmlFor="amount">Amount</label>
                  <input type="text" style={{ fontSize: 17 }} disabled value={total} className="form-control" placeholder="Amount" id="amount" onChange={handleChangeAmount} />
                </div>
              </div>

              {/* <div className="form-group"> */}
              {/* <Form.Item key="34" label="BillingType">
                        {
                            getFieldDecorator('Category', {
                               
                                rules: [{
                                    required: true,
                                    message: 'Please Select  BillingType!'
                                }],
                            })(
                                <Select
                                    placeholder="Select one"
                                    style={{}}
                                    showSearch={true}
                                    onChange={handleChangeBillingType}
                                    optionFilterProp="children">
                                    
                                      <Option key='$1' value="1">1</Option>
                                    
                                    
                                </Select>
                            )
                        }
                    </Form.Item> */}


              {/* </div> */}

              {/*  col-md-6   */}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Billing Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Name" onChange={handleChangeBillingName} />
                </div>
              </div>
              {/*  col-md-6   */}
            </div>
          {/* </div> */}
          {/*  row   */}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">Billing Email</label>
                <input type="email" className="form-control" id="email" placeholder="name@example.com" onChange={handleChangeBillingEmail} />
              </div>
            </div>
            {/*  col-md-6   */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="state">Billing State</label>
                {/* <input type="input" className="form-control" id="state" placeholder="State" onChange={handleChangeBillingState} /> */}
                <select
                    name="state"
                    className="form-control"
                     onChange={handleChangeBillingState}
                    defaultValue="0"
                   
                  >
                    <option value="0">select</option>
                     * {State.getStatesOfCountry(countryid).map((item: any, index: any) => {
                      return (
                        <option key={item["isoCode"]} value={item["isoCode"]}>
                          {item["name"]}
                        </option>
                      );
                    })}   
                  </select>
              </div>
            </div>
            {/*  col-md-6   */}
          </div>
          {/*  row   */}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="amount">Billing City</label>
                {/* <input type="text" className="form-control" placeholder="City" id="city" onChange={handleChangeBillingCity} /> */}
                <select
                    name="city"
                    className="form-control"
                     onChange={handleChangeBillingCity}
                    defaultValue="0"
                   
                  >
                    <option value="0">select</option>
                     * {City.getCitiesOfState(countryid,stateid).map((item: any, index: any) => {
                      return (
                        <option key={item["Id"]} value={item["Id"]}>
                          {item["name"]}
                        </option>
                      );
                    })}   
                  </select>
              </div>
            </div>
            {/*  col-md-6   */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="amount">Billing Zip</label>
                <input type="text" className="form-control" placeholder="Zip" id="zip" onChange={handleChangeBillingZip} />
              </div>
            </div>
            {/*  col-md-6   */}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="country">Billing Country</label>
                {/* <input type="text" className="form-control" placeholder="Country" id="country" onChange={handleChangeBillingCountry} /> */}
                <select
                    name="country"
                    className="form-control"
                     onChange={handleChangeBillingCountry}
                    defaultValue="0"
                   
                  >
                    <option value="0">Select</option>
                    {country1.map((item: any, index: any) => {
                      return (
                        <option key={item["isoCode"]} value={item["isoCode"]} >
                          {item["name"]}
                        </option>
                      );
                    })}
                  </select>
              </div>
            </div>
            {/*  col-md-6   */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="telephone">Billing Telephone</label>
                <input type="text" className="form-control" placeholder="Telephone" id="telephone" onChange={handleChangeBillingPhone} />
              </div>
            </div>
            {/*  col-md-6   */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="address">Billing Address</label>
                <textarea className="form-control" id="address" rows={3} defaultValue={""} onChange={handleChangeBillingAdress} />
              </div>
            </div>
            {/*  col-md-12   */}
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>

    </>
  );
};

export const PackageTransactionComponentForm = Form.create<PackageTransactionComponentProps>()(PackageTransactionComponent);

