import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ProductShortInfo, WishlistSearchDataRequestModel, CartDataRequestModel, CartPostModel } from '../../../api/controller';
import { Link } from 'react-router-dom'; 
import { useCartDispatcher, useCartContext, getCartItemsList, deleteCartItem } from '../../../action/CartAction';
import { useWishListDispatcher, useWishListContext, getWishListListResult } from '../../../action/WishLIstAction';
import AuthService from '../../../_services/AuthService';
import { AppUrls } from '../../../_environments/environment';
import axios from 'axios';
import { message, Popover } from 'antd';
// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from "react-h5-audio-player";
import { getUserListResult, useUserDispatcher, useUserContext } from '../../../action/UserAction';
import { Spinner } from 'react-bootstrap';

const key = 'updatable';

interface IHomeBookPageComponentProps {
    product: ProductShortInfo;
    indexData:string;
    onHandleCartLoad:()=>void;
    onHandleWishListLoad:()=>void;
    isWishListLoad:number;
    isCartLoad:number;
}


interface IHomeBookPageComponentState {
  addCart: boolean,
  wishList: boolean,
  WishListdata: ProductShortInfo[] | undefined,
  cartListData:ProductShortInfo[] | undefined,
refresh:number;
cartRefresh:number;
isUserLoad:boolean,
cartId:string;
  cartItemRemoved:boolean,
  isBusy:boolean,
  isWishListBusy:boolean;
}

const initialState = {
  addCart: false,
  wishList: false,
  WishListdata:{},
  cartListData:{},
  refresh:0,
  isUserLoad:false,
  cartId:"",
  cartItemRemoved:false,
  cartRefresh:0,
  isBusy:false,
  isWishListBusy:false,
} as IHomeBookPageComponentState


const HomeBookPageComponent: React.FC<IHomeBookPageComponentProps> = (props: any) => {
    const { product, indexData,onHandleCartLoad,onHandleWishListLoad,isWishListLoad,isCartLoad } = props;

    let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
    const [homeBookPageComponentState, sethomeBookPageComponentState] = React.useState<IHomeBookPageComponentState>(initialState);
    const {addCart,wishList,WishListdata,refresh,isUserLoad,cartRefresh,isBusy,isWishListBusy,
      cartListData,cartId,cartItemRemoved}=homeBookPageComponentState

    const cartDispatcher = useCartDispatcher();
    const cartContext = useCartContext();
    const { cartItems } = cartContext

    const userDispatcher = useUserDispatcher();
    const userContext = useUserContext();
    const { users } = userContext

    const wishListDispatcher = useWishListDispatcher();
    const wishListContext = useWishListContext();
    const {wishListResult } = wishListContext;

    React.useEffect(() => {
      (async () => {
          await getUserListResult(userDispatcher)
          sethomeBookPageComponentState({
              ...homeBookPageComponentState,
              isUserLoad:true,
          });
      })();
  }, [authorizationToken])

    React.useEffect(() => {
      if(isUserLoad){
      (async () => {
          await getWishListListResult(wishListDispatcher,{currencyId:users.defaultCurrencyId} as WishlistSearchDataRequestModel );
     
       })();
      }
  }, [refresh,isUserLoad,isWishListLoad])

  React.useEffect(() => {
   if(isUserLoad  ||cartRefresh>=1||isCartLoad>=1){
    (async () => {   
        await getCartItemsList(cartDispatcher,{currencyId:users.defaultCurrencyId} as CartDataRequestModel );
   
       
     })();
   }
}, [cartRefresh,isUserLoad,isCartLoad])

    React.useEffect(() => {
      (async () => {

        sethomeBookPageComponentState({
              ...homeBookPageComponentState,
              WishListdata:wishListResult.searchResult?.data?.filter(data=>data.audioId=== product.audioId)
          });

      })();
  }, [ wishListResult])

  React.useEffect(() => {
    (async () => {

      sethomeBookPageComponentState({
            ...homeBookPageComponentState,
            cartListData:cartItems.searchResult?.data?.filter(data=>data.audioId=== product.audioId)
        });

    })();
}, [ cartItems,refresh])
  
  // console.log(353,wishListResult);
  // console.log(354,wishListResult.searchResult);
  // console.log(355,wishListResult.searchResult?.data);
 console.log(356,cartListData?.length);
  React.useEffect(() => {
        if (addCart) {
          console.log("abc");
            (async () => {
                
                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.AddToCart;

                axios.post(url, JSON.stringify({ audioId: product.audioId }), { headers: headers }).then(res => {
                    console.log(res);
                    if (res) {
                      sethomeBookPageComponentState({
                        ...homeBookPageComponentState,
                        addCart: true,
                        cartRefresh:cartRefresh+1,
                        isBusy:false,
                    });
                        onHandleCartLoad();
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                    }
                })
            })();
        }
    }, [addCart])


    React.useEffect(() => {
        if (wishList) {
            (async () => {
                let headers = {
                    Authorization:  `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();
                const url = AppUrls.AddToWishList
                console.log("HomeBookPageContainer");
                axios.post(url, JSON.stringify({ audioId: product.audioId  }), { headers: headers }).then(res => {
                    if (res) {
                      sethomeBookPageComponentState({
                            ...homeBookPageComponentState,
                            wishList: false,
                            refresh:refresh+1,
                            isWishListBusy:false
                        });
                        onHandleWishListLoad();
                        // message.loading({ content: 'Loading...', key });
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

                    }
                })
            })();
        }
    }, [wishList])


    React.useEffect(() => {
      if(cartItemRemoved){
      (async () => {   
          await deleteCartItem(cartDispatcher,{audioId:cartId} as CartPostModel );
          onHandleCartLoad();
          onHandleWishListLoad();
          sethomeBookPageComponentState({
      ...homeBookPageComponentState,
     cartItemRemoved:false,
     cartRefresh:cartRefresh+1,
     isBusy:false,
     cartId:""
  });
       })();
     
      }
  }, [cartId])

  const onHandleRemoveFromCart=(cartId:any)=>{
    sethomeBookPageComponentState({
      ...homeBookPageComponentState,
      cartItemRemoved: true,
      cartId:cartId,
      isBusy:true,
      });
  }


    const OnHandleAddtoCart = () => {

      sethomeBookPageComponentState({
          ...homeBookPageComponentState,
          addCart: true,
          isBusy:true,
      });

  }

  const OnHandleAddtoWishList = () => {
    sethomeBookPageComponentState({
          ...homeBookPageComponentState,
          wishList: true,
          isWishListBusy:true,
      });
  }

    const starRating = (rating: number) => {

        let str = [];
        for (let i = 1, j = 2; i <= 5; i++ , j++) {

            if (rating > i && rating < j) {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star-half-o" aria-hidden="true" />

            }
            else if (rating >= i) {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star" aria-hidden="true" />

            }
            else {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star-o" aria-hidden="true" />

            }


        }

        return str;
    }



const content = (
  <div>
  <span className="sp-title">ABCDEFGH</span><br />
  <span className="sp-author">Asif Rahem</span><br />
  <span className="sp-narrator">Jimjan Irfan</span><br />
  <span className="sp-ratings">Rating</span><br />
  <span className="tg-stars" /><br />
  <span className="sp-para">Measure similarity between vary in time<br /> or speed measure similarity between vary in time</span><br />
  <Link to='/'>See More Details</Link>
</div>
);


    
    return (
        <>




<div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
        <div className="tg-postbook">
          <figure className="tg-featureimg">
          
          <Link to={`/book_details/${product.audioId}`}>
          <Popover content={content} placement="right"  title="Details">
            <div className="tg-bookimg">
           
{/* <div className="tooltip"> */}


              <div  className="tg-frontcover " >
              
                <img src={product.bookImages[0].filename}  alt="image description" />
              
                </div>  
              <div   className="tg-backcover"><img src={product.bookImages[0].filename}  alt="image description" /></div>
               
              {/* <span className="tooltiptext" >Title:ABCDEFGH</span> */}
              </div></Popover> 
            {/* </div> */}
            </Link>
            <a className="tg-btnaddtowishlist" onClick={OnHandleAddtoWishList} href="javascript:void(0);">
              <i className="icon-heart" />
              <span>
              {cartListData?.length == 0 ||cartListData?.length ==undefined ?
                WishListdata?.length == 0 ?isWishListBusy ?
                        (<Spinner animation="border" variant="warning" />) : 'Add to wishList'
               
                : isWishListBusy ?
                (<Spinner animation="border" variant="warning" />) : ' Remove from w..'
               :'Item Carted'}
               </span>
            </a>
            
            <AudioPlayer
    // autoPlay
   
    src="http://s3.ap-south-1.amazonaws.com/btc.audiotrial/K4L3MI58PGQT6kazhukan+vazhunna+naadu-(1)-+(online-audio-converter.com).flac"
    // onPlay={e => console.log("onPlay")}
    // other props here
  />
          </figure>
          <div className="tg-postbookcontent">
            <ul className="tg-bookscategories">
              <li><a href="javascript:void(0);">Art &amp; Photography</a>
              </li>
            </ul>
            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
            <div className="tg-booktitle">
               <h3><a href="javascript:void(0);">{product.title}</a></h3> 
            </div>
            <span className="tg-bookwriter">By: <a href="javascript:void(0);">
              Allen joy
            {/* {product.authors.map((author: any, index: any) => {
                              return (
                                <span>{author.caption}</span>
                              )
                            })} */}
              </a></span>
           <span > {starRating(product.totalStarRate)}<span /></span> 
            <span className="tg-bookprice">
              <ins>{product.offerPrice == 0 ? (<>{product.currencySymbol}  {product.realPrice}</>) : (<>
                                  <s style={{ color: 'sandybrown' }}>{product.currencySymbol}  {product.realPrice}</s> &nbsp;
                      {product.currencySymbol}  {product.offerPrice}
                                </>
                                )}</ins>
              {/* <del>$27.20</del> */}
            </span>
    
            {cartListData?.length == 0 ||cartListData?.length ==undefined ?  <a className="tg-btn tg-btnstyletwo" onClick={OnHandleAddtoCart} href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              {isBusy ?
                        (<Spinner animation="border" variant="warning" />) : <em>Add to Basket</em>}
              
            </a> :  <a className="tg-btn tg-btnstyletwo" onClick={()=>onHandleRemoveFromCart(product.audioId)} href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              {isBusy ?
                        (<Spinner animation="border" variant="warning" />) : <em>Remove From..</em>}
            </a>}
            {/* <a className="tg-btn tg-btnstyletwo" onClick={OnHandleAddtoCart} href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em>
            </a> */}
          </div>
        </div>
      </div>













{/* <div className="item">


        <div className="tg-postbook">
          <figure className="tg-featureimg">
          <Link to={`/book_details/${product.audioId}`}>
            <div className="tg-bookimg">
              <div className="tg-frontcover"><img  src={product.bookImages[0].filename} alt="image description" /></div>
              <div className="tg-backcover"><img src={product.bookImages[0].filename} alt="image description" /></div>
            </div>
            </Link>
            <a className="tg-btnaddtowishlist" href="javascript:void(0);">
              <i className="icon-heart" />
              <span>add to wishlist</span>
            </a>
          </figure>
          <div className="tg-postbookcontent">
            <div className="tg-booktitle">
              <h3><a href="javascript:void(0);">{product.title}</a></h3>
            </div>
            <span className="tg-bookwriter">By: <a href="javascript:void(0);">{product.authors}</a></span>
            
            {starRating(product.totalStarRate)}
                
            <span className="tg-bookprice">
    <ins>${product.realPrice}</ins>
    <del>${product.offerPrice}</del>
            </span>
            <a className="tg-btn tg-btnstyletwo" href="javascript:void(0);">
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em>
            </a>
          </div>
        </div>
      
      </div> */}



{/* 
            <div className="col-sm-3 col-xs-4"  >
                <Link to={`/book_details/${product.audioId}`}>
                    <div className="try_shadow">
                    <div className="try_audio_sec">
                         <div>
                            <img style={{width:'225px' ,height:'225px'}} src={product.bookImages[0].filename} className="img-responsive center-block" />
                            </div> 
                    </div>
                    <div className="book_author">{product.title}</div>
                    <div className="rating">
                        {starRating(product.totalStarRate)}
                    </div>
                    </div>
                </Link>
            </div> */}


        </>
    );
};

export default HomeBookPageComponent;


