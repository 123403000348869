import React, { Fragment } from 'react';

import { Tabs, Icon, Row, Col, Modal } from 'antd';
import Spinner from 'react-bootstrap/Spinner'
import { ProductReviewsDataResultModel, CartPostModel } from '../api';
import { useCartDispatcher, useCartContext, addToCartList } from '../action/CartAction';
import RatingContainer from '../rating/RatingContainer';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import AuthService from '../_services/AuthService';
import { Link } from 'react-router-dom';
let authorizationToken=AuthService.accessToken;
const { TabPane } = Tabs;

interface IBookDetailsComponentOnFrontPageProps{
  bookDetails:any 
  reviews?:ProductReviewsDataResultModel | undefined
  OnHandleAddtoCart:()=>void;
  OnHandleAddtoWishList:()=>void;
  OnHandleLoadReview:()=>void;
}

interface IBookDetailsComponentOnFrontPageState{
  visible:boolean
}

const initialState={
  visible:false
}as IBookDetailsComponentOnFrontPageState

const BookDetailsComponentOnFrontPage:React.FC<IBookDetailsComponentOnFrontPageProps>=(props) => {
  const {bookDetails,reviews,OnHandleAddtoCart,OnHandleAddtoWishList,OnHandleLoadReview}=props;

  const [bookDetailsComponentState,setBookDetailsComponentOnFrontPageState]=React.useState(initialState)
const {visible}= bookDetailsComponentState


const cartDispatcher = useCartDispatcher();
    const cartContext = useCartContext();
    const {cartItems}=cartContext

const starRating = (rating: number) => {

  let str = [];
  for (let i = 1, j = 2; i <= 5; i++ , j++) {

      if (rating > i && rating < j) {
          str[i] = <i className="fa fa-star-half-o" aria-hidden="true" />

      }
      else if (rating >= i) {
          str[i] = <i className="fa fa-star" aria-hidden="true" />

      }
      else {
          str[i] = <i className="fa fa-star-o" aria-hidden="true" />

      }


  }

  return str;
}

const handleAddToCart=(e:any)=>{
  e.preventDefault();
   OnHandleAddtoCart();
//  addToCartList(cartDispatcher, { AudioId: "e003d33f-b784-47be-b7bc-d040a17e7bd7"} as {} as CartPostModel)

}

const handleAddToWishList=(e:any)=>{
  e.preventDefault();
   OnHandleAddtoWishList();
}


const showModal = () => {
  setBookDetailsComponentOnFrontPageState({
    visible: true,
  });
};

const handleOk = () => {
  console.log(4455);
  setBookDetailsComponentOnFrontPageState({
    visible: false,
  });
};

const  handleCancel =(e:any) => {
  console.log(e);
  setBookDetailsComponentOnFrontPageState({
    visible: false,
  });
};
  
  return (
    <>
    {bookDetails!=undefined?(
      <div id="wrapper" className="container">
        <section className="header_text sub">
          <h4><span>Product Detail</span></h4>
        </section>

        <section className="main-content">
          <div className="row">
            <div className="span9">

              <div className="row">

                <div className="span4">
                  <a href="#" className="thumbnail" data-fancybox-group="group1" title="Description 1">
                    <img alt="" src={bookDetails.bookImages[0].filename
} /></a>
                </div>
                <div className="span5">
                  <address>
                    <strong>Name:</strong> <span><b>{bookDetails.title}</b></span><br />
                    <strong>Written By:</strong>
                    {bookDetails.authors.map((author:any,index:any)=>{
                      return(
                    <span>{author.caption}</span>
                      )
                    })}
                    
                     <br />
                    <strong>Narrated By:</strong> 
                    {bookDetails.narrators.map((narrator:any,index:any)=>{
                      return(
                    <span>{narrator.caption}</span>
                      )
                    })} 
                    <br />
                    <strong>Published By:</strong>
                  <span>{bookDetails.publisher.caption}</span>
                     <br />
                    <strong>Duration:</strong> <span>{bookDetails.length}</span><br />
                  </address>
                  <h4 style={{ marginLeft:'15px'}}><strong>Price:
                    {bookDetails.offerPrice==0?( <>{bookDetails.currencySymbol}  {bookDetails.realPrice}</>):(<>
                      <s style={{color:'sandybrown'}}>{bookDetails.currencySymbol}  {bookDetails.realPrice}</s> &nbsp; 
                      {bookDetails.currencySymbol}  {bookDetails.offerPrice}
                       </>
                   )}
                     </strong></h4>
                  <form className="form-inline">
                   

                    {/* <label>Qty:</label><input style={{ width:'60px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px', padding: '12px', marginRight: '10px' }} type="text" className="" placeholder={"1"} /> */}
<Link to="/login">
                    <button className="btn btn-inverse" >Add to cart</button>
                    </Link> 
                    
                  </form>
                  <div className="span5">
                  <div className="row">
                      <div className="audio-button" style={{ marginTop: '20px', marginLeft:'15px' }}>


                      {bookDetails.audioFiles.map((audioFile:any,index:any)=>{
                        
                      return(
                        <>
                        {audioFile.audioTypeId==1?(  
                        // <button style={{ marginRight: '10px'}} className="btn btn-inverse" type="submit">
                        //    <i className="">
                        //   <img alt="Play" src={require('../images/play-circle.png')} />       
                        //   </i>
                        //   Sample Audio
                        // </button>
                        <>
                        
   <h6 style={{color:"orange",marginBottom:10,marginLeft:20}}>Sample Audio</h6>                     
<audio controls>
  <source src={audioFile.audioUrl} type="audio/mpeg"/>
</audio>
                        </>

                        ):null}
                        </>
                      )
                        
                    })}      

                       <br/> <button  style={{marginTop:3, display:"none"}}className="btn btn-inverse" type="submit"><i className="">
                          {/* <img alt="Play" src={require('../images/dwn-pdf.png')} /> */}
                          </i>Download PDF</button>
                       
                      </div>
                      
                    </div>
                  </div>
                </div>

                
              </div>

              <div className="row">
        <div className="span9">

        <Tabs defaultActiveKey="1" >
    <TabPane tab="Description" key="1">
                  <p>{bookDetails.shortDescription}</p>
                  <br/> <p >{bookDetails.fullDescription}</p>

    </TabPane>
    <TabPane tab="Additional Information" key="2">
    <Link to="/login">
     <button  style={{backgroundColor:'#eb4800', color:'white', borderRadius:'4px', border:'none', lineHeight:'40px', padding:'0px 25px',marginBottom:20}}><Icon type="plus-circle"  style={{color:'white', verticalAlign:0}}/> ADD REVIEW</button>
  </Link>
      <RatingContainer
      visible={visible}
      handleOk={handleOk}
      handleCancel={handleCancel}
      OnHandleLoadReview={OnHandleLoadReview}
      />
    {reviews && reviews.data && reviews.data.map((review:any,index:any)=>{
      return(
        <>
        <Row style={{marginBottom:15}}>
         <Col span={8}>
           <span>{review.userName}</span><br/>
           {/* <span>{review.reviewDate}</span> */}
           <span>{starRating(review.starRate)}</span>
           
           </Col> 
           <Col span={4}>
           
           
           </Col> 
           <Col  span={12}>
           <span>{review.title}</span><br/>
           <span>{review.description}</span>
           </Col> 
        
        </Row>
        </>
      
      )
     })
     }
      
    </TabPane>
   
  </Tabs>
        </div>
        </div>

            </div>
          </div>
        </section>
      </div>):<>
      <div style={{marginLeft:600,marginTop:70}}>
      
{/* <img src={require('../images/audio.svg')}/> */}
 <h4>Loding...1</h4> </div>
    
 
      </> }
    </>
  );
 
               
};

export default BookDetailsComponentOnFrontPage;