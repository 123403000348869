import React from 'react';
import { useHomeDispatcher, useHomeContext } from '../action/HomeAction';
import { ProductShortInfo } from '../api';
import { Link } from 'react-router-dom';
import AuthService from '../_services/AuthService';
import { useCartDispatcher, useCartContext } from '../action/CartAction';
import { useWishListDispatcher, useWishListContext } from '../action/WishLIstAction';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import { message, Popover } from 'antd';
import AudioPlayer from "react-h5-audio-player";

const key = 'updatable';

interface IFrontPageTabViewComponenetProps{
key:string,
book:ProductShortInfo,
onHandleCartLoad:()=>void;
onHandleWishListLoad:()=>void;
}

interface IFrontPageTabViewComponenetState {
  addCart: boolean,
  wishList: boolean,
  
}

const initialState = {
  addCart: false,
  wishList: false,
 
} as IFrontPageTabViewComponenetState


const FrontPageTabViewComponenet:React.FC<IFrontPageTabViewComponenetProps>=(props) => {

    const homeDispatcher = useHomeDispatcher();
    const homeContext = useHomeContext();
    const { value,visible,homeProducts } = homeContext;

const {book,onHandleCartLoad,onHandleWishListLoad}=props

let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";

const [homeTabViewPageComponentState, sethomeTabViewPageComponentState] = React.useState<IFrontPageTabViewComponenetState>(initialState);
const {addCart,wishList}=homeTabViewPageComponentState

const cartDispatcher = useCartDispatcher();
const cartContext = useCartContext();
const { cartItems } = cartContext


const wishListDispatcher = useWishListDispatcher();
const wishListContext = useWishListContext();
const {wishListResult } = wishListContext;

React.useEffect(() => {
    if (addCart) {
        (async () => {

            let headers = {
                Authorization: `Bearer ${authorizationToken}`,
                "Content-Type": "application/json",
            };

            let formData = new FormData();

            const url = AppUrls.AddToCart

            axios.post(url, JSON.stringify({ audioId: book.audioId }), { headers: headers }).then(res => {
                
                if (res) {
                    onHandleCartLoad();
                    onHandleWishListLoad();
                    // message.loading({ content: 'Loading...', key });
                    setTimeout(() => {
                        message.success({ content: res.data.message, key, duration: 2 });
                    }, 1000);

                }
            })
        })();
    }
}, [addCart])


React.useEffect(() => {
    if (wishList) {
        (async () => {
            let headers = {
                Authorization:  `Bearer ${authorizationToken}`,
                "Content-Type": "application/json",
            };

            let formData = new FormData();
            const url = AppUrls.AddToWishList
            axios.post(url, JSON.stringify({ audioId: book.audioId  }), { headers: headers }).then(res => {
                if (res) {
                  sethomeTabViewPageComponentState({
                        ...homeTabViewPageComponentState,
                        wishList: false
                    });
                    onHandleWishListLoad();
                    // message.loading({ content: 'Loading...', key });
                    setTimeout(() => {
                        message.success({ content: res.data.message, key, duration: 2 });
                    }, 1000);

                }
            })
        })();
    }
}, [wishList])


const OnHandleAddtoCart = () => {

  sethomeTabViewPageComponentState({
      ...homeTabViewPageComponentState,
      addCart: true
  });

}

const OnHandleAddtoWishList = () => {
sethomeTabViewPageComponentState({
      ...homeTabViewPageComponentState,
      wishList: true
  });
}

const content = (
    <div>
    <span className="sp-title">ABCDEFGH</span><br />
    <span className="sp-author">Asif Rahem</span><br />
    <span className="sp-narrator">Jimjan Irfan</span><br />
    <span className="sp-ratings">Rating</span><br />
    <span className="tg-stars" /><br />
    <span className="sp-para">Measure similarity between vary in time<br /> or speed measure similarity between vary in time</span><br />
    <Link to='/'>See More Details</Link>
  </div>
  );

    const starRating = (rating: number) => {

        let str = [];
        for (let i = 1, j = 2; i <= 5; i++ , j++) {

            if (rating > i && rating < j) {
                str[i] = <i style={{color:'#fa420f'}} className="fa fa-star-half-o" aria-hidden="true" />

            }
            else if (rating >= i) {
                str[i] = <i  style={{color:'#fa420f'}} className="fa fa-star" aria-hidden="true" />

            }
            else {
                str[i] = <i className="fa fa-star-o" aria-hidden="true" />

            }


        }

        return str;
    }
    return (
        <>

<div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
        <div className="tg-postbook">
          <figure className="tg-featureimg">
          <Link to='/login'>
          <Popover content={content} placement="right"  title="Details">
            <div className="tg-bookimg">
              <div className="tg-frontcover"><img src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''}  alt="image description" /></div>
              <div  className="tg-backcover"><img src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''}  alt="image description" /></div>
            </div>
            </Popover>
            </Link>
            <a className="tg-btnaddtowishlist" onClick={OnHandleAddtoCart} href="javascript:void(0);">
              <i className="icon-heart" />
              <span>add to wishlist</span>
            </a>
            <AudioPlayer
    // autoPlay
    src="https://s3.ap-south-1.amazonaws.com/btc.audiotrial/K4L3MI58PGQT6kazhukan+vazhunna+naadu-(1)-+(online-audio-converter.com).flac"
    // onPlay={e => console.log("onPlay")}
    // other props here
  />
          </figure>
          <div className="tg-postbookcontent">
            <ul className="tg-bookscategories">
              <li><a href="javascript:void(0);">Art &amp; Photography</a>
              </li>
            </ul>
            <div className="tg-themetagbox"><span className="tg-themetag">sale</span></div>
            <div className="tg-booktitle">
              <h3><a href="javascript:void(0);">{book.title}</a></h3>
            </div>
            <span className="tg-bookwriter">By: <a href="javascript:void(0);">{book.authors}</a></span>
            <span > {starRating(book.totalStarRate)}<span /></span>
            <span className="tg-bookprice">
            <ins>{book.offerPrice == 0 ? (<>{book.currencySymbol}  {book.realPrice}</>) : (<>
                                  <s style={{ color: 'sandybrown' }}>{book.currencySymbol}  {book.realPrice}</s> &nbsp;
                      {book.currencySymbol}  {book.offerPrice}
                                </>
                                )}</ins>
            </span>
            {/* <a className="tg-btn tg-btnstyletwo" onClick={OnHandleAddtoWishList} href="javascript:void(0);"> */}
            <Link to='/login' className="tg-btn tg-btnstyletwo" >
              <i className="fa fa-shopping-basket" />
              <em>Add To Basket</em> 
              </Link>
            {/* </a> */}
          </div>
        </div>
      </div>



            {/* <div className="col-sm-3 col-xs-4">
        
            <Link to={`/book_details/${book.audioId}`}>
                    <div className="try_shadow" style={{ margin:10}}>
                    <div className="try_audio_sec">
                    <div ><img style={{height:225,width:225}}
                     src={book && book.bookImages && book.bookImages[0]!=undefined?book.bookImages[0].filename:''} 
                     className="img-responsive center-block" /></div>
                      
                    </div>
                    <div className="book_author">{book.title}</div>
                    <div className="rating">
                    {starRating(book.totalStarRate)}
                    </div></div>
                </Link>
            </div> */}
            
        </>
    );
};

export default FrontPageTabViewComponenet;
