import React from 'react';
import { Redirect } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import './App.css';
import Home from './home/Home';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FrontPage from './frontPage/FrontPage';
import AuthService from './_services/AuthService';
import { HomeContextProvider } from './context/HomeContext';
import { LanguageContextProvider } from './context/LanguageContext';
import { getLanguageList, useLanguageDispatcher, useLanguageContext } from './action/LanguageAction';
import { BrowserRouter } from 'react-router-dom';
import {HashRouter as Router, Route, Switch} from "react-router-dom";



let authorizationToken=AuthService.accessToken;
const App: React.FC = (props: any) => {
  console.log("Inside App function first line");
  console.log(89706, authorizationToken);
  return(
    authorizationToken==null?( 

      //..............................................If not logged in......................................

       // <BrowserRouter>
      <Router>
        <Route  path="/">
          <LanguageContextProvider>
          <HomeContextProvider>
          <FrontPage
            authorizationToken={authorizationToken}
          />
          </HomeContextProvider>
          </LanguageContextProvider>
        </Route>
      </Router>
    // </BrowserRouter>
    ):(

  //..................................................If logged in.............................................. 

  //  <BrowserRouter>
    <Router >
      <Route  path="/">
        <HomeContextProvider>
        <LanguageContextProvider>
        <Home {...props}
        /> 
        </LanguageContextProvider>
        </HomeContextProvider>
      </Route>
    </Router>
      // </BrowserRouter>
    )
  ) //Closing return function
console.log("Inside App.tsx last line");
}
export default App;
