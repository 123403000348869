import React from 'react';
import { useCartDispatcher, useCartContext, getCartItemsList } from '../action/CartAction';
import { CartDataRequestModel, CartResult } from '../api';
import { useUserDispatcher } from '../action/UserAction';
import { RouteComponentProps, withRouter } from 'react-router';
import Popconfirm from 'antd/lib/popconfirm';
// require('../myStyle/CartList.css')


interface ICartComponentProps extends  RouteComponentProps<any>{ 
  cartItems: CartResult,
  OnHandleCartItemRemoved: (cartId: any) => void;
}

function cancel(e: any) {
  console.log(e);
}

const CartComponent: React.FC<ICartComponentProps> = (props) => {

  const { cartItems, OnHandleCartItemRemoved } = props

  if (!cartItems || !cartItems.searchResult || !cartItems.searchResult.data || cartItems.searchResult.data.length <= 0) {
    return (
      <>

      </>
    );
  }

  let total=0;
   
  for(let i=0;i<cartItems.searchResult.data.length;i++){
    total=total+cartItems.searchResult.data[i].realPrice;
        
 }  

  const handleCartItemRemoved = (cartId: any) => {
    OnHandleCartItemRemoved(cartId);
  }

  const handleCheckOut=()=>{
props.history.push('/transaction')
  }

  return (
    <>


<section className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-aboutus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tg-aboutusshortcode">
                  <div className="tg-sectionhead">
                    <div>
                    <h2>Cart Items</h2>
                    
                  </div>
                  <div>
                                  <Popconfirm
                                    title="Are you sure to delete all items?"
                                    onConfirm={() => handleCartItemRemoved("0")}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a
                                      //  onClick={() => handleClick(data.rowId)}
                                      className="btn btn-danger save-event waves-effect waves-light"
                                      style={{ marginLeft: 12 }}
                                    >
                                      Delete All 
                                    </a>
                                  </Popconfirm></div>
                                  </div>
                  <table id="cart" className="table table-hover table-condensed">
                    <thead>
                      <tr>
                        <th style={{width: '50%'}}>Product</th>
                        <th style={{width: '10%'}}>Price</th>
                        <th style={{width: '8%'}}>Quantity</th>
                        <th style={{width: '22%'}} className="text-center">Subtotal</th>
                        <th style={{width: '10%'}} />
                      </tr>
                    </thead>
                    <tbody>
                    {cartItems.searchResult.data.map((cartItem, index) => {

return (<>

                      <tr>
                        <td data-th="Product">
                          <div className="row">
                            <div className="col-sm-2 hidden-xs"><img src={cartItem && cartItem.bookImages != undefined ? cartItem.bookImages[0].filename : ""} alt="..." className="img-responsive" /></div>
                            <div className="col-sm-10">
                              <h4 className="nomargin">{cartItem.title}</h4>
                              <p>{cartItem && cartItem.authors != undefined ? cartItem.authors[0].caption : ""}</p>
                            </div>
                          </div>
                        </td>
                        <td data-th="Price">
                        {cartItem.offerPrice == 0 ? (<>{cartItem.currencySymbol}  {cartItem.realPrice}</>) : (<>
                            <s style={{ color: 'sandybrown' }}>{cartItem.currencySymbol}  {cartItem.realPrice}</s> &nbsp;
                      {cartItem.currencySymbol}  {cartItem.offerPrice}
                          </>
                          )}

                        </td>
                        <td data-th="Quantity">
                          <input type="number" className="form-control text-center" value={1} />
                        </td>
                        <td data-th="Subtotal" className="text-center">
                          
                        {cartItem.offerPrice == 0 ? (<>{cartItem.currencySymbol}  {cartItem.realPrice}</>) : (<>
                            <s style={{ color: 'sandybrown' }}>{cartItem.currencySymbol}  {cartItem.realPrice}</s> &nbsp;
                      {cartItem.currencySymbol}  {cartItem.offerPrice}
                          </>
                          )}
                        </td>
                        <td className="actions" data-th>
                          <button className="btn btn-info btn-sm"><i className="fa fa-refresh" /></button>
                          <button className="btn btn-danger btn-sm" onClick={() => handleCartItemRemoved(cartItem.audioId)}><i className="fa fa-trash-o" /></button>								
                        </td>
                      </tr>

                      </>)
                })}

                    </tbody>
                    <tfoot>
                      <tr className="visible-xs">
                        <td className="text-center"><strong>Total {cartItems.searchResult.data[0].currencySymbol} {total}</strong></td>
                      </tr>
                      <tr>
                        <td><a href="#" className="btn btn-warning"><i className="fa fa-angle-left" /> Continue Shopping</a></td>
                        <td colSpan={2} className="hidden-xs" />
              <td className="hidden-xs text-center"><strong>Total {cartItems.searchResult.data[0].currencySymbol} {total}</strong></td>
                        <td><button onClick={handleCheckOut}  className="btn btn-success btn-block">Checkout <i className="fa fa-angle-right" /></button></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>















      {/* <button className="cart_quantity_delete" onClick={handleCheckOut} >Check Out</button>
      <section id="cart_items">
        <div className="container">
          <div className="table-responsive cart_info">
            <table className="table table-condensed">

              <thead>
                <tr className="cart_menu">
                  <td className="image">Item</td>
                  <td className="description"></td>
                  <td className="price">Price</td>
                  <td className="total">Total</td>
                  <td></td>
                </tr>
              </thead>

              <tbody>

                {cartItems.searchResult.data.map((cartItem, index) => {

                  return (<>

                    <tr>
                      <td className="cart_product">
                        <a href="">
                          <img style={{ width: 100, height: 100 }} src={cartItem && cartItem.bookImages != undefined ? cartItem.bookImages[0].filename : ""} alt="" />
                        </a>
                      </td>
                      <td className="cart_description">
                        <h4><a href="#">{cartItem.title}</a></h4>
                        <p>{cartItem && cartItem.authors != undefined ? cartItem.authors[0].caption : ""}</p>
                      </td>
                      <td className="cart_price">
                        <p>
                          {cartItem.offerPrice == 0 ? (<>{cartItem.currencySymbol}  {cartItem.realPrice}</>) : (<>
                            <s style={{ color: 'sandybrown' }}>{cartItem.currencySymbol}  {cartItem.realPrice}</s> &nbsp;
                      {cartItem.currencySymbol}  {cartItem.offerPrice}
                          </>
                          )}
                        </p>
                      </td>

                      <td className="cart_total">
                        <p className="cart_total_price">{cartItem.currencySymbol}{cartItem.offerPrice == 0 ? cartItem.realPrice : cartItem.offerPrice}</p>
                      </td>
                      <td className="cart_delete">
                        <button className="cart_quantity_delete" onClick={() => handleCartItemRemoved(cartItem.audioId)} >
                          <i className="fa fa-times"></i></button>
                      </td>
                    </tr>


                  </>)
                })}

              </tbody>
            </table></div></div></section> */}
    </>
  );
};

export default withRouter(CartComponent);