import React from 'react';
import  { LoginEditForm } from './LoginForm';
import Password from 'antd/lib/input/Password';
import AuthService from '../_services/AuthService';
import { Route, Redirect, withRouter } from 'react-router';
import Home from '../home/Home';
import { RouteComponentProps } from 'react-router-dom';
// import { navigate } from 'hookrouter';

export interface ILoginContainerProps extends  RouteComponentProps<any> {
   
}

export interface ILoginContainerState {
    isBusy:boolean,
    userName:string;
    password:string;
    rememberMe:boolean;
    loginMsg:string;
}


const initialState = {
    isBusy:false,
    userName:"",
    password:"",
    rememberMe:true,
    loginMsg:"",
} as ILoginContainerState


const LoginContainer:React.FC<ILoginContainerProps>= (props) => {
    const [loginContainerState,
        setLoginContainerState] = React.useState<ILoginContainerState>(initialState);

const {userName,password,rememberMe,isBusy,loginMsg}=loginContainerState;

      const onHandleUserChange=(value:any)=>{
        setLoginContainerState((loginContainerState) => {
            return {
                ...loginContainerState,
               userName:value,
            }
        });
       } 
       
       const onHandlePasswordChange=(value:any)=>{
        setLoginContainerState((loginContainerState) => {
            return {
                ...loginContainerState,
               password:value,
            }
        });
       }

       const onHandleSubmit=async ()=>{  
        setLoginContainerState((loginContainerState) => {
            return {
                ...loginContainerState,
               isBusy:true,
            }
        });
        
        try {
            const user = await AuthService.login(userName,password,rememberMe);
            if (user) {
                console.log(1,user);
                
                props.history.push('/home');
                 window.location.reload();


            }
            else {
               
                console.log(1,"no user");
              

                setLoginContainerState((loginContainerState) => {
                    return {
                        ...loginContainerState,
                       isBusy:false,
                    }
                });
            }
        } catch (error) {
          
            setLoginContainerState((loginContainerState) => {
                return {
                    ...loginContainerState,
                   isBusy:false,
                   loginMsg:"please check your username and password",
                }
            });
        }
       }
       
    return (
        <>
            <LoginEditForm
            onHandleUserChange={onHandleUserChange}
            onHandlePasswordChange={onHandlePasswordChange}
            onHandleSubmit={onHandleSubmit}
            isBusy={isBusy}
            loginMsg={loginMsg}
            />
            
        </>
    );
};

export default withRouter (LoginContainer);