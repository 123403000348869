import { RouteComponentProps } from "react-router-dom";
import { useRef, useReducer } from "react";
import React from "react";
import { ILanguageAction, languageReducer } from "../action/LanguageAction";
import { useImmerReducer } from "use-immer";
import {  LanguageListVM } from "../api/controller";

console.log("Inside LanguageContext.tsx");
export const LanguageDispatcherContext = React.createContext<React.Dispatch<ILanguageAction> | null>(null);
export const LanguageStateContext = React.createContext<ILanguageManagementState | null>(null);

export interface ILanguageManagementProps extends RouteComponentProps<any> { }

export interface ILanguageManagementState {
    visible:boolean;
    value:number;
    languages:LanguageListVM
}

export const initialLanguageManagementState = {
    visible:false,
    value:0,
    languages:{} as LanguageListVM,
} as ILanguageManagementState;

export const LanguageContextProvider: React.FC = ({ children }) => {
    const [languageState, dispatcher] = useImmerReducer(languageReducer, initialLanguageManagementState);

    return (
        <LanguageDispatcherContext.Provider value={dispatcher}>
            <LanguageStateContext.Provider value={languageState}>
                {children}
            </LanguageStateContext.Provider>
        </LanguageDispatcherContext.Provider>
    )
}