import React from 'react';

import axios from 'axios';
import { useCartDispatcher, useCartContext, getCartItemsList } from '../action/CartAction';
import { CartDataRequestModel } from '../api';
import { useUserDispatcher, useUserContext, getUserListResult } from '../action/UserAction';
import { PackageTransactionComponentForm } from './PackageTransactionComponent';
import { environment } from '../_environments/environment';
import AuthService from '../_services/AuthService';

let token=AuthService.accessToken;
let   authorizationToken =token!=null?token.replace(/['"]+/g,''):""; 
interface PackageTransactionContainerProps {
    packagePrice:number,
    memberCount:number,
}

interface PackageTransactionContainerState{
amount:number;
currency:string;
billingType:number;
billingName:string;
billingEmail:string;
billingState:string;
billingCity:string;
billingZip:string;
billingCountry:string;
billigPhone:string;
billingAdress:string;
isSubmit:boolean;
encRequest:string;
accessCode:string;
isNext:boolean;
totalValue:number;
isCarted:boolean;
}

const initialState={
    amount:0,
    currency:"INR",
    billingType:1,
    billingName:"",
    billingEmail:'',
    billingState:'',
    billingCity:'',
    billingZip:'',
    billingCountry:'',
    billigPhone:'',
    billingAdress:'',
    isSubmit:false,
    encRequest:"",
accessCode:"",
isNext:false,
totalValue:0,
isCarted:false,
}

const PackageTransactionContainer:React.FC<PackageTransactionContainerProps>= (props) => {

    const [transactionFormState,
        setTransactionFormState]=React.useState<PackageTransactionContainerState>(initialState)
    
        const {packagePrice,memberCount}=props
       
        const {amount,currency,billingType,billingName,billingEmail,billingState ,isCarted,
            billingCity,billingZip,billingCountry,billigPhone,billingAdress,
            isSubmit,isNext,accessCode,encRequest,totalValue
        }=transactionFormState

        let total=totalValue;
let totalWithoutDiscount=totalValue
        
    

        React.useEffect(() => {
                if (isSubmit) {
                (async () => {
    
                   // var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhMTA4MmZmNC1lMzRjLTRjMTMtOWE0ZS0wMTY1YjY1NDEzZDMiLCJuYW1lIjoibmlzaGlsYTIiLCJyb2xlIjoiU2l0ZUFkbWluaXN0cmF0b3IiLCJwZXJtaXNzaW9uIjpbInVzZXJzLnZpZXciLCJ1c2Vycy5tYW5hZ2UiLCJyb2xlcy52aWV3Iiwicm9sZXMubWFuYWdlIiwicm9sZXMuYXNzaWduIiwibWVtYmVycy52aWV3IiwibWVtYmVycy5tYW5hZ2UiLCJ1c2VyLnZpZXciLCJtZW1iZXIudmlldyIsImFsbC52aWV3Il0sInRva2VuX3VzYWdlIjoiYWNjZXNzX3Rva2VuIiwianRpIjoiOWJjZDU2ODEtNWFhNi00YmRjLWJkNzQtOWM2MzE3ZjVkYjE1Iiwic2NvcGUiOlsib3BlbmlkIiwiZW1haWwiLCJwaG9uZSIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyIsInJvbGVzIl0sIm5iZiI6MTU4MDM0OTE5NSwiZXhwIjoxNjExODg1MTk1LCJpYXQiOjE1ODAzNDkxOTUsImlzcyI6Imh0dHA6Ly9hcGkudm9jYWJvb2tzLmNvbS8ifQ.hCpg81_iwI-JbwVFpVd8v-W5Qc4yU5NgUgEKmcNk2oQ"
                    var headers = {
                        Authorization: `Bearer ${authorizationToken}`,
                            "Content-Type": "application/json", 
                          };

                      const postData={amount:packagePrice,currency,billingType,billingName,billingEmail,billingState ,
                        billingCity,billingZip,billingCountry,billigPhone,billingAdress,BillingTypeReferenceId:"",
                        OrderNo:0,BillType:1,PaymentAmount:packagePrice,DiscountAmount:0,PaidAmount:packagePrice,PaymentStatus:true
                    }

                    let formData = new FormData();
                    // formData.append("");
                    const url = environment.baseUrl+'/api/billing/billing-request'
                    // "https://audiobook.borncodetechnologies.com/api/productapi/get-users";
                    axios.post(url, JSON.stringify(	postData), { headers: headers }).then(res => {
                        console.log(1576, res); 
                        if(res.data.isSuccess){ 
                            setTransactionFormState({
                                ...transactionFormState,
                                accessCode:res.data.accessCode,
                                encRequest:res.data.encRequest,
                               isNext:true
                            });
                        }   
                    })
                })();
            }
        }, [isSubmit])


     

         
                  
const onHandleSaveButtonClick=()=>{

    setTransactionFormState({
        ...transactionFormState,
       isSubmit:true
    });


}

   const onHandleChangeAmount=(value:any)=>{

    setTransactionFormState({
        ...transactionFormState,
        amount:value
    });

    console.log("amount",value)
}

const onHandleChangeCurrency=(value:any)=>{

    setTransactionFormState({
        ...transactionFormState,
        currency:value
    });

    console.log("Currency",value)
}

const onHandleChangeBillingType=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        // billingType:value
    });

    console.log("BillingType",value)
}

const onHandleChangeBillingName=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingName:value
    });

    console.log("BillingName",value)
}

const onHandleChangeBillingEmail=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingEmail:value
    });

    console.log("BillingEmai",value)
}

const onHandleChangeBillingStates=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingState:value
    });

    console.log("BillingStates",value)
}

const onHandleChangeBillingCity=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingCity:value
    });

    console.log("BillingCity",value)
}

const onHandleChangeBillingZip=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingZip:value
    });

    console.log("BillingZip",value)
}

const onHandleChangeBillingCountry=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingCountry:value
    });

    console.log("BillingCountry",value)
}

const onHandleChangeBillingPhone=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billigPhone:value
    });

    console.log("Phone",value)
}

const onHandleChangeBillingAdress=(value:any)=>{

 setTransactionFormState({
        ...transactionFormState,
        billingAdress:value
    });

    console.log("Adress",value)
}

const simulateClick=(e:any)=>{
    if(isNext){
     e.click();
    }
}


        return (
        <>
           <PackageTransactionComponentForm
            onHandleChangeAmount={onHandleChangeAmount}
           onHandleChangeCurrency={onHandleChangeCurrency}
           onHandleChangeBillingType={onHandleChangeBillingType}
           onHandleChangeBillingName={onHandleChangeBillingName}
           onHandleChangeBillingEmail={onHandleChangeBillingEmail}
           onHandleChangeBillingStates={onHandleChangeBillingStates}
           onHandleChangeBillingCity={onHandleChangeBillingCity}
           onHandleChangeBillingZip={onHandleChangeBillingZip}
           onHandleChangeBillingCountry={onHandleChangeBillingCountry}
           onHandleChangeBillingPhone={onHandleChangeBillingPhone}
           onHandleChangeBillingAdress={onHandleChangeBillingAdress}
           onHandleSaveButtonClicks={onHandleSaveButtonClick}
           total={packagePrice}
           /> 
         
           <form id="nonseamless" method="post" name="redirect" action=" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" >
        
        <input type="hidden" id="encRequest" name="encRequest" defaultValue={encRequest}/>

        <input type="hidden" name="access_code" id="Hidden1" defaultValue={accessCode} />
        <button style={{ display: 'contents'}} type="submit"   ref={simulateClick} className="btn btn-primary" onClick={()=> console.log('clicked')}></button>
      </form>
        </>
    );
};

export default PackageTransactionContainer;


// import React from 'react';

// import axios from 'axios';
// import { useCartDispatcher, useCartContext, getCartItemsList } from '../action/CartAction';
// import { CartDataRequestModel } from '../api';
// import { useUserDispatcher, useUserContext, getUserListResult } from '../action/UserAction';
// import { PackageTransactionComponentForm } from './PackageTransactionComponent';
// import { environment } from '../_environments/environment';


// interface PackageTransactionContainerProps {
//     packagePrice:number,
//     memberCount:number,
// }

// interface PackageTransactionContainerState{
// amount:number;
// currency:string;
// billingType:number;
// billingName:string;
// billingEmail:string;
// billingState:string;
// billingCity:string;
// billingZip:string;
// billingCountry:string;
// billigPhone:string;
// billingAdress:string;
// isSubmit:boolean;
// encRequest:string;
// accessCode:string;
// isNext:boolean;
// totalValue:number;
// isCarted:boolean;
// }

// const initialState={
//     amount:0,
//     currency:"INR",
//     billingType:1,
//     billingName:"",
//     billingEmail:'',
//     billingState:'',
//     billingCity:'',
//     billingZip:'',
//     billingCountry:'',
//     billigPhone:'',
//     billingAdress:'',
//     isSubmit:false,
//     encRequest:"",
// accessCode:"",
// isNext:false,
// totalValue:0,
// isCarted:false,
// }

// const PackageTransactionContainer:React.FC<PackageTransactionContainerProps>= (props) => {

//     const [transactionFormState,
//         setTransactionFormState]=React.useState<PackageTransactionContainerState>(initialState)
    
//         const {packagePrice,memberCount}=props
       
//         const {amount,currency,billingType,billingName,billingEmail,billingState ,isCarted,
//             billingCity,billingZip,billingCountry,billigPhone,billingAdress,
//             isSubmit,isNext,accessCode,encRequest,totalValue
//         }=transactionFormState

//         let total=totalValue;
// let totalWithoutDiscount=totalValue
        
    

//         React.useEffect(() => {
//                 if (isSubmit) {
//                 (async () => {
    
//                     var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhMTA4MmZmNC1lMzRjLTRjMTMtOWE0ZS0wMTY1YjY1NDEzZDMiLCJuYW1lIjoibmlzaGlsYTIiLCJyb2xlIjoiU2l0ZUFkbWluaXN0cmF0b3IiLCJwZXJtaXNzaW9uIjpbInVzZXJzLnZpZXciLCJ1c2Vycy5tYW5hZ2UiLCJyb2xlcy52aWV3Iiwicm9sZXMubWFuYWdlIiwicm9sZXMuYXNzaWduIiwibWVtYmVycy52aWV3IiwibWVtYmVycy5tYW5hZ2UiLCJ1c2VyLnZpZXciLCJtZW1iZXIudmlldyIsImFsbC52aWV3Il0sInRva2VuX3VzYWdlIjoiYWNjZXNzX3Rva2VuIiwianRpIjoiOWJjZDU2ODEtNWFhNi00YmRjLWJkNzQtOWM2MzE3ZjVkYjE1Iiwic2NvcGUiOlsib3BlbmlkIiwiZW1haWwiLCJwaG9uZSIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyIsInJvbGVzIl0sIm5iZiI6MTU4MDM0OTE5NSwiZXhwIjoxNjExODg1MTk1LCJpYXQiOjE1ODAzNDkxOTUsImlzcyI6Imh0dHA6Ly9hcGkudm9jYWJvb2tzLmNvbS8ifQ.hCpg81_iwI-JbwVFpVd8v-W5Qc4yU5NgUgEKmcNk2oQ"
//                     var headers = {
//                         Authorization: "Bearer " + token,
//                         "Content-Type": "application/json", 
//                       };

//                       const postData={amount:packagePrice,currency,billingType,billingName,billingEmail,billingState ,
//                         billingCity,billingZip,billingCountry,billigPhone,billingAdress,BillingTypeReferenceId:"",
//                         OrderNo:0,BillType:1,PaymentAmount:packagePrice,DiscountAmount:0,PaidAmount:packagePrice,PaymentStatus:true
//                     }

//                     let formData = new FormData();
//                     // formData.append("");
//                     const url = environment.baseUrl+'/api/billing/billing-request'
//                     // "https://audiobook.borncodetechnologies.com/api/productapi/get-users";
//                     axios.post(url, JSON.stringify(	postData), { headers: headers }).then(res => {
//                         console.log(1576, res); 
//                         if(res.data.isSuccess){ 
//                             setTransactionFormState({
//                                 ...transactionFormState,
//                                 accessCode:res.data.accessCode,
//                                 encRequest:res.data.encRequest,
//                                isNext:true
//                             });
//                         }   
//                     })
//                 })();
//             }
//         }, [isSubmit])


     

         
                  
// const onHandleSaveButtonClick=()=>{

//     setTransactionFormState({
//         ...transactionFormState,
//        isSubmit:true
//     });


// }

//    const onHandleChangeAmount=(value:any)=>{

//     setTransactionFormState({
//         ...transactionFormState,
//         amount:value
//     });

//     console.log("amount",value)
// }

// const onHandleChangeCurrency=(value:any)=>{

//     setTransactionFormState({
//         ...transactionFormState,
//         currency:value
//     });

//     console.log("Currency",value)
// }

// const onHandleChangeBillingType=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         // billingType:value
//     });

//     console.log("BillingType",value)
// }

// const onHandleChangeBillingName=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingName:value
//     });

//     console.log("BillingName",value)
// }

// const onHandleChangeBillingEmail=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingEmail:value
//     });

//     console.log("BillingEmai",value)
// }

// const onHandleChangeBillingStates=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingState:value
//     });

//     console.log("BillingStates",value)
// }

// const onHandleChangeBillingCity=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingCity:value
//     });

//     console.log("BillingCity",value)
// }

// const onHandleChangeBillingZip=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingZip:value
//     });

//     console.log("BillingZip",value)
// }

// const onHandleChangeBillingCountry=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingCountry:value
//     });

//     console.log("BillingCountry",value)
// }

// const onHandleChangeBillingPhone=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billigPhone:value
//     });

//     console.log("Phone",value)
// }

// const onHandleChangeBillingAdress=(value:any)=>{

//  setTransactionFormState({
//         ...transactionFormState,
//         billingAdress:value
//     });

//     console.log("Adress",value)
// }

// const simulateClick=(e:any)=>{
//     if(isNext){
//      e.click();
//     }
// }


//         return (
//         <>
//            <PackageTransactionComponentForm
//             onHandleChangeAmount={onHandleChangeAmount}
//            onHandleChangeCurrency={onHandleChangeCurrency}
//            onHandleChangeBillingType={onHandleChangeBillingType}
//            onHandleChangeBillingName={onHandleChangeBillingName}
//            onHandleChangeBillingEmail={onHandleChangeBillingEmail}
//            onHandleChangeBillingStates={onHandleChangeBillingStates}
//            onHandleChangeBillingCity={onHandleChangeBillingCity}
//            onHandleChangeBillingZip={onHandleChangeBillingZip}
//            onHandleChangeBillingCountry={onHandleChangeBillingCountry}
//            onHandleChangeBillingPhone={onHandleChangeBillingPhone}
//            onHandleChangeBillingAdress={onHandleChangeBillingAdress}
//            onHandleSaveButtonClicks={onHandleSaveButtonClick}
//            total={packagePrice}
//            /> 
         
//            <form id="nonseamless" method="post" name="redirect" action=" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" >
        
//         <input type="hidden" id="encRequest" name="encRequest" defaultValue={encRequest}/>

//         <input type="hidden" name="access_code" id="Hidden1" defaultValue={accessCode} />
//         <button style={{ display: 'contents'}} type="submit"   ref={simulateClick} className="btn btn-primary" onClick={()=> console.log('clicked')}></button>
//       </form>
//         </>
//     );
// };

// export default PackageTransactionContainer;