import React from 'react';
import BillingHistoryComponenet from './BillingHistoryComponent';
import InvoiceComponent from './InvoiceComponent';

interface BillingHistoryContainerProps{

}

interface BillingHistoryContainerState{
isVisible:boolean;
}
const initialState={
    isVisible:false,
}
const BillingHistoryContainer:React.FC<BillingHistoryContainerProps>=(props) => {

    const [billingHistoryContainerState,setBillingHistoryContainerState]=React.useState<BillingHistoryContainerState>(initialState)
   
const onVisibleClick=(value:any)=>{
    setBillingHistoryContainerState({
        ...billingHistoryContainerState,
       isVisible:value
      })
}

   const {isVisible}=billingHistoryContainerState
    return (
        <>
        {isVisible? <InvoiceComponent/>:<BillingHistoryComponenet
        onVisibleClick={onVisibleClick}
        />}
            
        </>
    );
};

export default BillingHistoryContainer;