import { Reducer } from "react"
import { IProductReviewManagementState, ProductReviewDispatcherContext, ProductReviewStateContext } from "../context/ProductReviewContext"
import React from "react";
import { ProductAPIClient, ProductReviewResultModel, ProductReviewsDataRequestModel } from "../api";


export type IProductReviewAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_HOME_PRODUCT_LIST",
    productReviewResult:ProductReviewResultModel 
}




export const productReviewReducer: Reducer<IProductReviewManagementState, IProductReviewAction> = (draft, action): IProductReviewManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.productReviewResult=action.productReviewResult;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<IProductReviewAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<IProductReviewAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useProductReviewDispatcher = (): React.Dispatch<IProductReviewAction> => {
    const productReviewDispatcher = React.useContext(ProductReviewDispatcherContext);
    if (!productReviewDispatcher) {
        throw new Error("You have to provide the ProductReview dispatcher using theProductReviewDispatcherContext.Provider in a parent component.");
    }
    return productReviewDispatcher;
}


export const useProductReviewContext = (): IProductReviewManagementState => {
    const productReviewContext = React.useContext(ProductReviewStateContext);
    if (!productReviewContext) throw new Error("You have to provide the productReview context using the ProductReviewStateContext.Provider in a parent component.");
    return productReviewContext;
}



const ProductApi = new ProductAPIClient();

export const getProductReviewList = async (dispatcher: React.Dispatch<IProductReviewAction>, query:ProductReviewsDataRequestModel ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const productReviewResult = await ProductApi.getProductReview( query)
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", productReviewResult: productReviewResult });

    }
    catch(e){

    }
}



