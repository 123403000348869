import React from 'react';
import Password from 'antd/lib/input/Password';
import AuthService from '../_services/AuthService';
import { Route, Redirect, withRouter } from 'react-router';
import Home from '../home/Home';
import { RouteComponentProps } from 'react-router-dom';
import { ForgotPasswordEditForm } from './ForgotPassword';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import { message } from 'antd';
// import { navigate } from 'hookrouter';
const key = 'updatable';

export interface IForgotPasswordContainerProps extends  RouteComponentProps<any> {
   
}

export interface IForgotPasswordContainerState {
    isBusy:boolean,
    userName:string;
    password:string;
    ConformPassword:string;
    rememberMe:boolean;
    loginMsg:string;
}


const initialState = {
    isBusy:false,
    userName:"",
    password:"",
    rememberMe:true,
    ConformPassword:"",
    loginMsg:"",
} as IForgotPasswordContainerState


const ForgotPasswordContainer:React.FC<IForgotPasswordContainerProps>= (props) => {
    const [forgotPasswordContainerState,
        setForgotPasswordContainerState] = React.useState<IForgotPasswordContainerState>(initialState);
        let token = AuthService.accessToken;
        let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
const {userName,password,ConformPassword,rememberMe,isBusy,loginMsg}=forgotPasswordContainerState;

      const onHandleUserChange=(value:any)=>{
        setForgotPasswordContainerState((forgotPasswordContainerState) => {
            return {
                ...forgotPasswordContainerState,
               userName:value,
            }
        });
       } 
       
       const onHandlePasswordChange=(value:any)=>{
        setForgotPasswordContainerState((forgotPasswordContainerState) => {
            return {
                ...forgotPasswordContainerState,
               password:value,
            }
        });
       }

       const onHandleConfirmPasswordNameChange=(value:any)=>{
        setForgotPasswordContainerState((forgotPasswordContainerState) => {
            return {
                ...forgotPasswordContainerState,
               ConformPassword:value,
            }
        });
       }

       const onHandleSubmit=async ()=>{ 
        console.log(555555555,"handle clicked") 
        setForgotPasswordContainerState((forgotPasswordContainerState) => {
            return {
                ...forgotPasswordContainerState,
               isBusy:true,
            }
        });
       
        // try {
        //     const user = await AuthService.login(userName,password,rememberMe);
        //     if (user) {
        //         console.log(1,user);
                
        //         props.history.push('/home');
        //          window.location.reload();


        //     }
        //     else {
               
        //         console.log(1,"no user");
        //         setForgotPasswordContainerState((forgotPasswordContainerState) => {
        //             return {
        //                 ...forgotPasswordContainerState,
        //                isBusy:false,
        //             }
        //         });
        //     }
        // } catch (error) {
        //     console.log('Error occurred while Forgot Password' + error);
        //     setForgotPasswordContainerState((forgotPasswordContainerState) => {
        //         return {
        //             ...forgotPasswordContainerState,
        //            isBusy:false,
        //            loginMsg:"please check your deatils",
        //         }
        //     });
        // }
       }
       
       React.useEffect(() => {
        console.log(867678678,isBusy)
        if (isBusy) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.ResetPassword

                axios.post(url, JSON.stringify({
                    Email:userName,
                    Password:password,
                    ConformPassword:ConformPassword
             }), { headers: headers }).then(res => {
                    
                    if (res.data.isSuccess) {
                        setTimeout(() => {
                            message.success({ content: res.data.message, key, duration: 2 });
                        }, 1000);

      
 setForgotPasswordContainerState({
        ...forgotPasswordContainerState,
        isBusy:false,
       
       
    });
   
    props.history.push('/login');
                
 }

 else
 {
    setTimeout(() => {
        message.error({ content: res.data.message, key, duration: 2 });
    }, 1000);


    setForgotPasswordContainerState({
...forgotPasswordContainerState,
isBusy:false,


});
 }
                })
            }
            
            
            
            
            
            
            )();
        }
    }, [isBusy])
   



    return (
        <>
            <ForgotPasswordEditForm
            onHandleUserChange={onHandleUserChange}
            onHandlePasswordChange={onHandlePasswordChange}
            onHandleConfirmPasswordNameChange={onHandleConfirmPasswordNameChange}
            onHandleSubmit={onHandleSubmit}
            isBusy={isBusy}
            loginMsg={loginMsg}
            />
            
        </>
    );
};

export default withRouter (ForgotPasswordContainer);