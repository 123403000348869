import React from 'react';
import PackagesAndOffersComponent from './PackagesAndOffersComponent';
import axios from 'axios';
import AuthService from '../_services/AuthService';
import { AppUrls } from '../_environments/environment';
import { PackageInfo } from '../_models/PackageList';
import PackageAndOffersDetails from './PackageAndOffersDetails';

interface IPackagesAndOffersContainerProps{
    onHandlePackagePriceLoad:(value:any)=>void;
    onHandlememeberCountLoad:(memberCount:any)=>void;
}

interface IPackagesAndOffersContainerState{
    packages:PackageInfo[],
    keyValue:number,
    isPackageDetailsVisible:boolean,
}

const initialState = {
    packages:{} as PackageInfo[] ,
    keyValue:100,
    isPackageDetailsVisible:false,
} as IPackagesAndOffersContainerState

const PackagesAndOffersContainer:React.FC<IPackagesAndOffersContainerProps>= (props) => {
    let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
const {onHandlePackagePriceLoad,onHandlememeberCountLoad}=props;
    const [packagesAndOffersContainerState,
        setPackagesAndOffersContainerState] = React.useState<IPackagesAndOffersContainerState>(initialState);

        const {packages,keyValue,isPackageDetailsVisible}=packagesAndOffersContainerState

        React.useEffect(() => {
            if (1) {
                (async () => {
                    let url_ = AppUrls.PackageGetAll;
                    url_ = url_.replace(/[?&]$/, "");
                    let headers = {
                                            Authorization: `Bearer ${authorizationToken}`,
                                            'Content-type': 'application/json'
                          };
    
                      const postData={  
                        Page: 1,
                        PageSize: 12,
                        SearchTerm: "",
                        SortOrder: ""
                       }
                    let formData = new FormData();
               
                    
                    axios.post(url_, JSON.stringify(postData), { headers: headers }).then(res => {
                        console.log(1576, res.data.data); 
                        if(res.data){ 
                            setPackagesAndOffersContainerState({
                                ...packagesAndOffersContainerState,
                                packages:res.data.packages,
                                
                            });
                           
                        }   
                    })
                })();
            }
        }, [])

  const  onHandleKeyChange=(value:any)=>{
      console.log(555,value);
    setPackagesAndOffersContainerState({
        ...packagesAndOffersContainerState,
        keyValue:value,
      
    });
  }

  const onHandleKeyChangeMobile=(value:any)=>{
    console.log(555,value);
  setPackagesAndOffersContainerState({
      ...packagesAndOffersContainerState,
      isPackageDetailsVisible:true,
      keyValue:value,
  });
}

const onHandleBackButtonClick=()=>{
    
    setPackagesAndOffersContainerState({
        ...packagesAndOffersContainerState,
        isPackageDetailsVisible:false
    });  
}

    return (
        <>{isPackageDetailsVisible?
        
        <PackageAndOffersDetails
        onHandleBackButtonClick={onHandleBackButtonClick}
        keyValue={keyValue}
        packages={packages}
        />
        :
        <PackagesAndOffersComponent
        {...props}
        packages={packages}
        onHandlePackagePriceLoad={onHandlePackagePriceLoad}
        onHandlememeberCountLoad={onHandlememeberCountLoad}
        keyValue={keyValue}
        onHandleKeyChange={onHandleKeyChange}
        onHandleKeyChangeMobile={onHandleKeyChangeMobile}
        />
        }
        </>
    );
};
export default PackagesAndOffersContainer;