import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Fade } from 'react-bootstrap';
import { Collapse, AutoComplete, Slider, Input, Row, Col, InputNumber, Form, Select } from 'antd';
import { ValueCaptionPair } from '../api';
import { RouteComponentProps, withRouter } from 'react-router';

//  import '../myStyle/advanceSearch.css';
// require('../myStyle/advanceSearch.css')
const { Panel } = Collapse;

interface IAdvanceSearchComponentProps extends RouteComponentProps<any> {
    categories: ValueCaptionPair[] | undefined;
    authors: any;
    narrators: any;
    publishers: any;
    translators: any;
    onHandleAuthorChange: (value: any) => void;
    onHandleNarratorChange: (value: any) => void;
    onHandlePublisherChange: (value: any) => void;
    onHandleTranslatorChange: (value: any) => void;
    OnHandleCategoyChange: (value: any) => void;
}

interface IAdvanceSearchComponentState {
    minValue: number;
    maxValue: number;
}

const initialState = {
    minValue: 0,
    maxValue: 0
}

const AdvanceSearchComponent: React.FC<IAdvanceSearchComponentProps> = (props) => {
    const { categories, authors, narrators, publishers, translators, onHandleAuthorChange,
        onHandleNarratorChange, onHandlePublisherChange, onHandleTranslatorChange, OnHandleCategoyChange } = props

    const [advanceSearchComponentState,
        setAdvanceSearchComponentrState] = React.useState<IAdvanceSearchComponentState>(initialState)

    const { maxValue, minValue } = advanceSearchComponentState

    // const onCreateSelectItems = (data: any[],valueFieldName: string ,captionFieldName: string, ) => {
    //     let items = [];
    //     if (data) {
    //         for (let i = 0; i < data.length; i++) {
    //             items.push( 
    //                 data[i][captionFieldName]
    //                 // value={data[i][valueFieldName]}
    //             )
    //         }
    //     }
    //     return items;
    // }

    const onCreateSelectItems = (data: any[], valueFieldName: string, captionFieldName: string, keyName: string) => {
        let items = [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
                items.push(
                    <Select.Option
                        key={keyName + i}
                        value={data[i][valueFieldName]}>
                        {data[i][captionFieldName]}
                    </Select.Option>
                );
            }
        }
        return items;
    }

    const handleAuthorChange = (value: any) => {
        onHandleAuthorChange(value);
        //  console.log(value)    
    }

    const handleNarratorChange = (value: any) => {
        onHandleNarratorChange(value);
    }

    const handlePublisherChange = (value: any) => {
        onHandlePublisherChange(value);
    }

    const handleTranslatorChange = (value: any) => {
        onHandleTranslatorChange(value);
    }


    const handleChangeSlider = (value: any) => {
        setAdvanceSearchComponentrState({
            ...advanceSearchComponentState,
            minValue: value,
        });
    }


    const handleChangeAfterSlider = (value: any) => {
        setAdvanceSearchComponentrState({
            ...advanceSearchComponentState,
            maxValue: value,
        });
    }

    const handleCategorySelect = (value: any) => {
        OnHandleCategoyChange(value)
    }

    const onSubmitClicked = (e: any) => {
        e.preventDefault();
        props.history.push('/search');
    }


    return (
        <>




            {/*Contact Us Start*/}
            <div className="tg-sectionspace tg-haslayout">
                <div className="container">
                    <div className="row">
                        <div className="tg-contactus">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="tg-sectionhead">
                                    <h2>Advanced Search</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="col-lg-12">
                                    <div className="tg-sectionhead">
                                        <h4>Havingc trouble finding what you're looking for?
                      Try these advanced options to fine-tune your search.</h4>
                                    </div>
                                    <form className="tg-formtheme tg-formcontactus">
                                        <fieldset>
                                            <div className="form-group">
                                                <label>Author</label>
                                                <Select
                                                    placeholder="Select"
                                                    mode="multiple"

                                                    onChange={handleAuthorChange}
                                                    showSearch={true}
                                                    optionFilterProp="children">
                                                    {
                                                        authors &&
                                                        onCreateSelectItems(authors, "value", "caption", "author")
                                                    }
                                                </Select>
                                            </div>
                                            <div className="form-group">
                                                <label>Narrator</label>

                                                <Select
                                                    placeholder="Select"
                                                    mode="multiple"
                                                    style={{}}
                                                    showSearch={true}
                                                    onChange={handleNarratorChange}
                                                    optionFilterProp="children">
                                                    {
                                                        narrators &&
                                                        onCreateSelectItems(narrators, "value", "caption", "narrators")
                                                    }
                                                </Select>
                                            </div>
                                            <div className="form-group">
                                                <label>Publisher</label>
                                                <Select
                                                    placeholder="Select"
                                                    style={{}}
                                                    showSearch={true}
                                                    onChange={handlePublisherChange}
                                                    optionFilterProp="children">
                                                    {
                                                        publishers &&
                                                        onCreateSelectItems(publishers, "value", "caption", "publisher")
                                                    }
                                                </Select>
                                            </div>
                                            <div className="form-group">
                                                <label>Translator</label>
                                                <Select
                                                    placeholder="Select"
                                                    mode="multiple"
                                                    style={{}}
                                                    onChange={handleTranslatorChange}
                                                    showSearch={true}
                                                    optionFilterProp="children">
                                                    {
                                                        translators &&
                                                        onCreateSelectItems(translators, "value", "caption", "translator")
                                                    }
                                                </Select>
                                            </div>
                                            <div className="form-group">
                                                <label>Category</label>
                              {categories!=undefined? categories.slice(0,3).map((category, index) => {
                                    return (
                                        <>
                                            <div className="form-radio-item">
                                                <input type="radio" name="category" id="arts" onClick={()=>handleCategorySelect(category.value)} />
                                                <label htmlFor="arts">{category.caption}</label>
                                                <span className="check" />
                                            </div>
                                        </>)}):null}
                                            </div>
                                            <div className="form-group tg-hastextarea">
                                                <p>
                                                    <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                        Show More
                          </button>
                                                </p>
                                                <div className="collapse" id="collapseExample">
                                                    <div className="card card-body">
                                                        
                                                    {categories!=undefined? categories.slice(3).map((category, index) => {
                                    return (
                                        <>
                                            <div className="form-radio-item">
                                                <input type="radio" name="category" id="arts" onClick={()=>handleCategorySelect(category.value)} />
                                                <label htmlFor="arts">{category.caption}</label>
                                                <span className="check" />
                                            </div>
                                        </>)}):null}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group tg-hastextarea">
                                                <label>Price</label>
                                                <input type="number" className="form-control" id="usr" />
                                            </div>
                                            <div className="form-group tg-hastextarea">
                                                <label>New Releases</label>
                                                <label className="radio-inline"><input type="radio" name="optradio" defaultChecked />Option 1</label>
                                                <label className="radio-inline"><input type="radio" name="optradio" />Option
                          2</label>
                                                <label className="radio-inline"><input type="radio" name="optradio" />Option
                          3</label>
                                            </div>
                                            <div className="form-group tg-hastextarea">
                                                <label>Length</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          1</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          2</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          3</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          4</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          5</label>
                                                <label className="checkbox-inline"><input type="checkbox" />Option
                          6</label>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" onClick={onSubmitClicked} className="tg-btn tg-active">Submit</button>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>










            {/* 
           <div className="main">
                <div className="container">
                    <div className="signup-content col-12 col-sm-12 col-md-12 col-lg-12">
                        <form method="POST" className="register-form" id="register-form">
                            <div className="row">
                                <div className="label-flex">
                                    <label htmlFor="payment"><strong>Advanced Search</strong><br />
                                        <span>Havingc trouble finding what you're looking for? Try these advanced options to
                                              fine-tune your search.</span></label>
                                </div>
                            </div>
                            <div className="form-row ">
                               
                                <label htmlFor="first_name" className="required">Author</label>

                                <Select
                                placeholder="Select"
                                mode="multiple"

                                onChange={handleAuthorChange}
                                showSearch={true}
                                optionFilterProp="children">
                                {
                                    authors &&
                                    onCreateSelectItems(authors, "value", "caption", "author")
                                }
                            </Select>
                            te
                                 
                                <div className="form-group col-md-6">
                                    <div className="form-input">
                                        <label htmlFor="last_name" className="required">Narrator</label>
                                  

<Select
                                        placeholder="Select"
                                        mode="multiple"
                                        style={{}}
                                        showSearch={true}
                                        onChange={handleNarratorChange}
                                        optionFilterProp="children">
                                        {
                                            narrators &&
                                            onCreateSelectItems(narrators, "value", "caption", "narrators")
                                        }
                                    </Select>
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <div className="form-input">
                                        <label htmlFor="company" className="required">Publisher</label>
                                       
                             
                                 <Select
                                        placeholder="Select"
                                        style={{}}
                                        showSearch={true}
                                        onChange={handlePublisherChange}
                                        optionFilterProp="children">
                                        {
                                            publishers &&
                                            onCreateSelectItems(publishers, "value", "caption", "publisher")
                                        }
                                    </Select>
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="form-input">
                                        <label htmlFor="email" className="required">Translator </label> 
                                       

<Select
                                        placeholder="Select"
                                        mode="multiple"
                                        style={{}}
                                        onChange={handleTranslatorChange}
                                        showSearch={true}
                                        optionFilterProp="children">
                                        {
                                            translators &&
                                            onCreateSelectItems(translators, "value", "caption", "translator")
                                        }
                                    </Select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="label-flex">
                                    <label htmlFor="payment"><strong>Category</strong></label>
                                </div>
                            </div>
                            <div className="border-bottom">
                                <div className="form-row ">
                                    <div className="form-group">
                                        <div className="form-radio-group">
                                        {categories!=undefined? categories.slice(0,3).map((category, index) => {
                                    return (
                                        <>
                                            <div className="form-radio-item">
                                                <input type="radio" name="category" id="arts" onClick={()=>handleCategorySelect(category.value)} />
                                                <label htmlFor="arts">{category.caption}</label>
                                                <span className="check" />
                                            </div>
                                        </>)}):null}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="faq">
                                    <div className="faqitem">
                                        <Collapse defaultActiveKey={['0']} >
                                            <Panel header="Show More" key="1">
                                                <div className="content">
                                                    <div className="form-radio-group">
                                                    {categories!=undefined? categories.slice(3).map((category, index) => {
                                    return (
                                        <>
                                            <div className="form-radio-item">
                                                <input type="radio" name="category" id="arts" onClick={()=>handleCategorySelect(category.value)} />
                                                <label htmlFor="arts">{category.caption}</label>
                                                <span className="check" />
                                            </div>
                                        </>)}):null}
                                                  
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="label-flex">
                                    <label htmlFor="payment"><strong>Price</strong></label>
                                </div>
                            </div>         
                                    <div>
            
                       
    <Row>
                    <Col span={20}> 
                    <Slider
                        min={1}
                        max={maxValue+100}
                        onChange={handleChangeAfterSlider}
                        value={typeof maxValue === 'number' ? maxValue : 0}
                        />
                        </Col>
                    
                    <Col span={4} >
                    <InputNumber
           min={0}
         style={{ marginLeft: 16 }}
         value={maxValue}
         onChange={handleChangeAfterSlider}
       />
                    
                    </Col>
                        </Row>  

                   </div>
                                          
                                            <div className="row">
                                <div className="label-flex">
                                    <label htmlFor="payment"><strong>New Releases</strong></label>
                                </div>
                            </div>
                            <div className="form-row border-bottom">
                                <div className="form-group">
                                    <div className="form-radio-group">
                                        <div className="form-radio-item">
                                            <input type="radio" name="category" id="30_days" defaultChecked />
                                            <label htmlFor="30_days">Last 30 Days</label>
                                            <span className="check" />
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="radio" name="category" id="60_days" />
                                            <label htmlFor="60_days">Last 60 Days</label>
                                            <span className="check" />
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="radio" name="category" id="90_days" />
                                            <label htmlFor="90_days">Last 90 Days</label>
                                            <span className="check" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="label-flex">
                                    <label htmlFor="payment"><strong>Length</strong></label>
                                </div>
                            </div>
                            <div className="form-row border-bottom">
                                <div className="form-group">
                                    <div className="form-radio-group">
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="1_hour" defaultChecked />
                                            <label htmlFor="1_hour">Under 1 Hour</label>
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="3_hours" />
                                            <label htmlFor="3_hours">1-3 Hours</label>
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="6_hours" />
                                            <label htmlFor="6_hours">3-6 Hours</label>
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="10_hours" defaultChecked />
                                            <label htmlFor="10_hours">6-10 Hours</label>
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="20_hours" />
                                            <label htmlFor="20_hours">10-20 Hours</label>
                                        </div>
                                        <div className="form-radio-item">
                                            <input type="checkbox" name="category" id="over_hours" />
                                            <label htmlFor="over_hours">Over 20 Hours</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-submit">
                                <input type="submit" defaultValue="Submit" onClick={onSubmitClicked} className="submit"  id="submit" name="submit" />

                            </div>

                        </form>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default withRouter(AdvanceSearchComponent);