import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Input, Radio, Checkbox, Select } from 'antd';
import { Spinner, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PackageInfo } from '../_models/PackageList';


const { Option } = Select;
interface IRegistrationForm extends FormComponentProps {
  onHandleEmailChange: (value: any) => void;
  onHandlePhoneNumberNameChange: (value: any) => void;
  onHandleConfirmPasswordNameChange: (value: any) => void;
  onHandlePasswordNameChange: (value: any) => void;
  onHandleLastNameChange: (value: any) => void;
  onHandlefirstNameChange: (value: any) => void;
  // onChangeRadio:(value:any)=>void;
  handleRegistrationButtonClicked: (value: any) => void;
  onHandleUserChange: (value: any) => void;
  onHandleGroupPackageSelect: (value: any) => void;
  onHandlePackagePriceLoad: (value: any) => void;
  onHandlememeberCountLoad: (memberCount: any) => void;
  onHandleTnCCkeck:(value:any)=>void;
  // radioValue:number;
  msg: string;
  isBusy: boolean;
  packages: PackageInfo[];
}
interface IRegistrationFormState {
  passwordValue: string;
  radioValue: string,
  packageValue: string;
  num: string;
  tnc:boolean;
}
const initialStae = {
  passwordValue: "",
  radioValue: 'IndividualUser',
  packageValue: 'none',
  num: '',
  tnc:false,
}

const RegistrationForm: React.FC<IRegistrationForm> = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [registrationFormState, setRegistrationFormState] = React.useState<IRegistrationFormState>(initialStae)

  const {
     onHandleConfirmPasswordNameChange, 
     onHandleEmailChange, 
     onHandleLastNameChange, 
     onHandlePackagePriceLoad, 
     onHandlememeberCountLoad,
    handleRegistrationButtonClicked, 
    msg, isBusy, packages, 
    onHandleUserChange, onHandleGroupPackageSelect,
    onHandlePasswordNameChange, onHandlePhoneNumberNameChange, onHandlefirstNameChange ,onHandleTnCCkeck} = props
  const { passwordValue, radioValue, packageValue, num ,tnc} = registrationFormState

  const handleEmailChange = (e: any) => {
    onHandleEmailChange(e.target.value);
  }

  const handlePhoneNumberChange = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      console.log(777777)
      setRegistrationFormState({
        ...registrationFormState,
        num: e.target.value
      })
      onHandlePhoneNumberNameChange(e.target.value);
    }

    //  onHandlePhoneNumberNameChange(e.target.value);
  }
  const handleTnCCkeck=(e:any)=>
  {
    onHandleTnCCkeck(e.target.checked);
  }

  const handleConfirmPasswordChange = (e: any) => {
    onHandleConfirmPasswordNameChange(e.target.value);
  }

  const handlePasswordChange = (e: any) => {
    onHandlePasswordNameChange(e.target.value);
  }

  const handleLastNameChange = (e: any) => {
    onHandleLastNameChange(e.target.value);
  }

  const handlefirstNameChange = (e: any) => {
    onHandlefirstNameChange(e.target.value);
  }

  const OnHandleRegistrationButtonClicked = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleRegistrationButtonClicked(e);
      }
    });
  }


  const onChangeRadio = (e: any) => {
    console.log('radio checked', e.target.value);
    onHandleUserChange(e.target.value);
    setRegistrationFormState({
      ...registrationFormState,
      radioValue: e.target.value,
      packageValue: e.target.value == 'IndividualUser' ? 'none' : ""
    });
  };

  const onHandlePackageButtonClick = (e: any) => {
    onHandleGroupPackageSelect(e.target.value);

  }

  const handlePackagePriceLoad = (value: any, memeberCount: any) => {
    onHandlePackagePriceLoad(value);
    onHandlememeberCountLoad(memeberCount);
  }

  const prefixSelector = () => {
    return (
      <Form.Item key="156" >
        {
          getFieldDecorator('firstName', {

            rules: [

            ],
          })(<Select defaultValue="+91" style={{ width: 70 }} >
            <Option value="+91">+91</Option>
            <Option value="81">+81</Option>
          </Select>)
        }
      </Form.Item>

    )
  }


  return (
    <>

      <div className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="tg-contactus">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-content">
                  <div className="title">REGISTRATION</div>

                  {/* <div className="row"> */}
                  <Radio.Group onChange={onChangeRadio} className='antRadioStyle' value={radioValue}>
                    <Radio value={'IndividualUser'}>Single User</Radio>

                    <Radio value={'GroupAdmin'}>Group Admin</Radio>
                  </Radio.Group>
                  <ButtonGroup onClick={onHandlePackageButtonClick} className="buttons" style={{ display: `${packageValue}` }}>
                    <div className="social"> <span>select your package</span></div>
                    {packages.length !== undefined ?
                      packages.map((packageData, index) => {
                        return (
                          <Button value={packageData.id} className={`tooltip-bottom facebook${index}`} data-tooltip={packageData.description} onClick={() => handlePackagePriceLoad(packageData.price, packageData.memberCount)}><i className="fa fa-gift" />{packageData.name}</Button>
                        )

                      }) : null
                    }
                    {/* <Button value={1} className="tooltip-bottom facebook" data-tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."><i className="fa fa-gift" />SILVER</Button>
                    <Button value={2} className="tooltip-bottom twitter" data-tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."><i className="fa fa-gift" />GOLD</Button>
                    <Button value={3} className="tooltip-bottom google" data-tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."><i className="fa fa-gift" />PLATINUM</Button> */}
                  </ButtonGroup>

                  <Form id="myForm" className="login-form" onSubmit={OnHandleRegistrationButtonClicked}>
                    <Form.Item key="1" >
                      {
                        getFieldDecorator('firstName', {

                          rules: [
                            { required: true, message: "please Enter First Name!" },
                            { pattern: /^[A-Za-z]+$/, message: " Alphabets character only!" }
                          ],
                        })(<Input placeholder="First Name"
                          name="firstName"
                          onChange={handlefirstNameChange}
                        />)
                      }
                    </Form.Item>
                    <Form.Item key="2" >
                      {
                        getFieldDecorator('lastName', {

                          rules: [
                            { required: true, message: "please Enter Last Name!" },
                            { pattern: /^[A-Za-z]+$/, message: " Alphabets character only!" }
                          ],
                        })(<Input placeholder="Last Name"
                          name="lastName"
                          onChange={handleLastNameChange}
                        />)
                      }
                    </Form.Item>
                    <Form.Item key="3" >
                      {
                        getFieldDecorator('email', {

                          rules: [
                            { required: true, message: "please Enter Email!" },
                            { type: 'email', message: "please Enter a Valid Email" },
                          ],
                        })(<Input placeholder="Email"
                          name="email"
                          onChange={handleEmailChange}
                        />)
                      }
                    </Form.Item>
                    <Form.Item key="4" >
                      {
                        getFieldDecorator('password', {

                          rules: [{ required: true, message: "Please Enter!" },
                          {
                            pattern: /(?=.*?[a-z])/, message: `At least one lower case English letter!`
                          },
                          { pattern: /^(?=.*?[A-Z])/, message: "At least one upper case!" },
                          { pattern: /(?=.*?[0-9])/, message: "At least one digit!" },
                          { pattern: /(?=.*?[#?!@$%^&*-])/, message: "At least one special character!" },
                          { pattern: /.{8,}/, message: "Minimum eight in length!" },
                          ]
                        })(< Input.Password placeholder="Password"
                          name="password"
                          onChange={handlePasswordChange}
                        />
                        )
                      }
                    </Form.Item>
                    <Form.Item key="5" >
                      {
                        getFieldDecorator('Confirmpassword', {

                          rules: [{
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          {

                            validator: (rule: any, value: any) => {

                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('The  passwords that you entered do not match!');
                              }
                            },

                          },

                          ],

                        })

                          (< Input.Password placeholder="Confirm Password"
                            name="confirmpassword"
                            onChange={handleConfirmPasswordChange}
                          />
                          )
                      }
                    </Form.Item>
                    <Form.Item key="6" >
                      {
                        getFieldDecorator('phoneNumber', {


                          rules: [

                            { required: true, message: "please Enter Mobile Number!" },
                            // { type:'number', message: "Only digits" },
                            { pattern: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, message: "Please enter a valid Mobile Number!" },
                          ],
                        })
                          (<Input placeholder="Mobile Number"
                            style={{ width: '100%' }}
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            // addonBefore={prefixSelector}
                          // value={98}
                          />)
                      }
                    </Form.Item>
                    <Form.Item key="2" >
                      {
                        getFieldDecorator('checkval', {

                          rules: [
                            { required: true, message: "please accept Terms and Conditions!" },
                            {

                              validator: (rule: any, value: any) => {
  
                                if (value) {
                                  return Promise.resolve();
                                }
                                else {
                                  return Promise.reject('please accept terms');
                                }
                              },
  
                            },
                            
                          ],
                        })(<Checkbox name="checkval" onChange={handleTnCCkeck}>
  <span style={{ marginLeft: '20px' }}>I have read and agree to the <a href="./TermsAndConditions">Terms of Use </a>and <a href="ServiceAndCondition">Privacy Policy</a></span>

                        </Checkbox>)
                      }
                    </Form.Item>
                  
                    {/* <Checkbox name="checkval" onChange={handleTnCCkeck}></Checkbox>
                    <span style={{ marginLeft: '20px' }}>I have read and agree to the <a href="./TermsAndConditions">Terms of Use </a>and <a href="ServiceAndCondition">Privacy Policy</a></span>
                    */}
                   
                   
                    <button> {isBusy ?
  (<Spinner animation="border" variant="warning" />) : 'Create Account'}</button>
                    <div className="already">Already have an account? <Link to="/login">Sign In</Link></div>

                    <h6 style={{ color: 'red' }}>{msg}</h6>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Form id="myForm" className="login-form"  onSubmit={OnHandleRegistrationButtonClicked   }>
                     <div className="row">  */}

      {/* <div className="form-check form-check-inline">
                          <input  onClick={handleMemberChecked} name='members' className="form-check-input" type="radio" id="inlineCheckbox1"  />
                          <label className="form-check-label" htmlFor="inlineCheckbox1">Group Member</label>
  </div>  */}

      {/* <div className="form-check form-check-inline">
<Radio.Group onChange={onChangeRadio} value={radioValue}>
        <Radio value={1}>Single User</Radio>
        <Radio value={2}>Group Admin</Radio>
        
      </Radio.Group>
                        </div>
                     
                       </div>
                        

          <Form.Item key="1" >
                        {
                          getFieldDecorator('firstName', {

                            rules: [
                              { required:true, message: "please Enter First Name!" },
                              { pattern:/^[A-Za-z]+$/, message: " Alphabets character only!" }
                        ],
                          })(<Input placeholder="First Name"
                            name="firstName"
                            onChange={handlefirstNameChange}
                          />)
                        }
                      </Form.Item>
                      <Form.Item key="2" >
                        {
                          getFieldDecorator('lastName', {

                            rules: [
                              { required:true, message: "please Enter Last Name!" },
                              { pattern:/^[A-Za-z]+$/, message: " Alphabets character only!" }
                        ],
                          })(<Input placeholder="Last Name"
                            name="lastName"
                            onChange={handleLastNameChange}
                          />)
                        }
                      </Form.Item>
                      <Form.Item key="3" >
                        {
                          getFieldDecorator('email', {

                            rules: [
                              { required:true, message: "please Enter Email!" },
                              { type:'email', message: "please Enter a Valid Email" },
                        ],
                          })(<Input placeholder="Email"
                            name="email"
                            onChange={handleEmailChange}
                          />)
                        }
                      </Form.Item>
                      <Form.Item key="4" >
                        {
                          getFieldDecorator('password', {

                            rules: [{ required:true, message: "Please Enter!" },
                              {
                              pattern: /(?=.*?[a-z])/,message: `At least one lower case English letter!`
                            },
                            { pattern: /^(?=.*?[A-Z])/, message: "At least one upper case!" },
                            { pattern: /(?=.*?[0-9])/, message: "At least one digit!" },
                            { pattern: /(?=.*?[#?!@$%^&*-])/, message: "At least one special character!" },
                            { pattern: /.{8,}/, message: "Minimum eight in length!" },
                          ]
                          })(< Input.Password placeholder="Password"
                            name="password"
                            onChange={handlePasswordChange}
                          />
                          )
                        }
                      </Form.Item>
                      <Form.Item key="5" >
                        {
                          getFieldDecorator('Confirmpassword', {

                            rules: [{
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            {
                               
                                    validator:(rule:any, value:any)=> {

                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        else{
                                        return Promise.reject('The two passwords that you entered do not match!');
                                        }
                                    },
                                
                            },

                          ],
                        
                          })
                          
                          (< Input.Password placeholder="Confirm Password"
                            name="confirmpassword"
                            onChange={handleConfirmPasswordChange}
                          />
                          )
                        }
                      </Form.Item>
                      <Form.Item key="6" >
                        {
                          getFieldDecorator('phoneNumber', {

                            rules: [
                              { required:true, message: "please Enter Mobile Number!" }, */}
      {/* { type:'number', message: "Only digits" },
                              { pattern: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/, message: "Please enter a valid Mobile Number!" },
                        ],
                          })(<Input placeholder="Mobile Number"
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                          />)
                        }
                      </Form.Item>

                      <button 
                      form="myForm" key="submit" type="submit"
                      
                      >{isBusy?  
          (<Spinner animation="border" variant="warning" />) :'Register'}</button>

<p className="message"> <Link to="/login">
                      <a href="#">back to login</a></Link>
                      </p>
                      
          <br/>
          <h6 style={{color:'red'}}>{msg}</h6>
                    </Form> */}

      {/* <input onChange={handlefirstNameChange} type="text" style={{width: '100%'}} placeholder="First Name" /> */}
      {/* <input onChange={handleLastNameChange} type="text" style={{width: '100%'}} placeholder="Last Name" /> */}
      {/* <input onChange={handleEmailChange}  type="text" style={{width: '100%'}} placeholder="Email" /> */}
      {/* <input onChange={handlePasswordChange}  type="password" style={{width: '100%'}} placeholder="Password" /> */}
      {/* <input onChange={handleConfirmPasswordChange} type="password" style={{width: '100%'}} placeholder="Conform Password" /> */}
      {/* <input  onChange={handlePhoneNumberChange} type="text" style={{width: '100%'}} placeholder="Phone Number" /> */}
      {/* <input  type="password" style={{width: '100%'}} placeholder="Code" /> */}
    </>
  );
};

export const RegistrationFormValidate = Form.create<IRegistrationForm>()(RegistrationForm);


// export default RegistrationForm;