import React from 'react';
import RatingComponenet from './RatingComponenet';
import { RouteComponentProps, withRouter } from 'react-router';
import AuthService from '../_services/AuthService';
import axios from 'axios';
import { AppUrls } from '../_environments/environment';
import {  message } from 'antd';

const key = 'updatable';
export interface IRatingContainerParams {
    id?: string;
}

interface IRatingContainerProps extends RouteComponentProps<IRatingContainerParams> {
    handleOk:()=>void;
    handleCancel:(e:any)=>void;
    visible:boolean;
    OnHandleLoadReview:()=>void;
}

interface IRatingContainerState{
    visible:boolean;
    storyRate:number;
    narratorRate:number;
    title:string;
    description:string;
    isRated:boolean;
    isStoryRate:boolean;
    isNarratorRate:boolean;
    loadReview:number;
}

const initialState={
    visible:false,
    storyRate:0,
    narratorRate:0,
    title:"",
    description:"",
    isRated:false,
    isStoryRate:false,
    isNarratorRate:false,
    loadReview:0
}

const RatingContainer:React.FC<IRatingContainerProps>=(props) => {

    const [ratingContainerState,setRatingContainerState]=React.useState(initialState)
const {description,title,storyRate,narratorRate,isRated,isStoryRate,isNarratorRate}=ratingContainerState
    
const {handleCancel,handleOk,visible,OnHandleLoadReview}=props
const { id } = props.match.params;
let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";


    React.useEffect(() => {
        if (isRated) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.AddReviews

                axios.post(url, JSON.stringify({ audioId: id,Title:title,
                Description:description,
                Active:true }), { headers: headers }).then(res => {
                    if (res.data.isSuccess) {

                        
                        OnHandleLoadReview(); 

                    }
                })
            })();

            setRatingContainerState((homeState) => {
                return {
                    ...ratingContainerState,
                    storyRate:storyRate,
                    isRated:false
                }
            });
        }
    }, [isRated])


    
    React.useEffect(() => {
        if (isStoryRate) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.RateReview

                axios.post(url, JSON.stringify({ audioId: id,RateType:1,Rate:storyRate,
                    Host:"1:1", }), { headers: headers }).then(res => {
                    if (res.data.isSuccess) {

                        
                        OnHandleLoadReview();

                    }
                    
                })

                setRatingContainerState((homeState) => {
                    return {
                        ...ratingContainerState,
                        storyRate:storyRate,
                        isStoryRate:false
                    }
                });
            })();
        }
    }, [isStoryRate])

    React.useEffect(() => {
        if (isNarratorRate) {
            (async () => {

                let headers = {
                    Authorization: `Bearer ${authorizationToken}`,
                    "Content-Type": "application/json",
                };

                let formData = new FormData();

                const url = AppUrls.RateReview

                axios.post(url, JSON.stringify({ audioId: id,RateType:2,Rate:storyRate,
                    Host:"1:1", }), { headers: headers }).then(res => {
                    if (res.data.isSuccess) {

                        OnHandleLoadReview();  
                       

                    }
                })
                setRatingContainerState((homeState) => {
                    return {
                        ...ratingContainerState,
                        storyRate:storyRate,
                        isNarratorRate:false
                    }
                });
            })();
        }
    }, [isNarratorRate])




const OnHandleRateStory=(storyRate:number)=>{
    console.log(1,storyRate)
    setRatingContainerState((homeState) => {
        return {
            ...ratingContainerState,
            storyRate:storyRate,
            isStoryRate:true
        }
    });
}

const OnHandleRateNarrator=(narratorRate:number)=>{
    console.log(2,narratorRate)
    setRatingContainerState((homeState) => {
        return {
            ...ratingContainerState,
            narratorRate:narratorRate,
            isNarratorRate:true
        }
    });
}

const OnHandleChangeTitle=(title:string)=>{
    console.log(3,title)
    setRatingContainerState((homeState) => {
        return {
            ...ratingContainerState,
            title:title
        }
    });
}

const OnHandleChangeDiscription=(description:string)=>{
    console.log(4,description)
    setRatingContainerState((homeState) => {
        return {
            ...ratingContainerState,
            description:description
        }
    });
}

const OnHandleSaveRating=()=>{
    setRatingContainerState((homeState) => {
        return {
            ...ratingContainerState,
            isRated:true,
        }
    });
    handleOk()  
}

    return (
        <>
            <RatingComponenet
            visible={visible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            OnHandleRateStory={OnHandleRateStory}
            OnHandleRateNarrator={OnHandleRateNarrator}
            OnHandleChangeTitle={OnHandleChangeTitle}
            OnHandleChangeDiscription={OnHandleChangeDiscription}
            OnHandleSaveRating={OnHandleSaveRating}
            />
        </>
    );
};

export default withRouter(RatingContainer);