//...........................import statements........................................................

import React from 'react';
import { Button, Drawer, Icon, Popover, Badge, Avatar, Divider, Select, Menu } from 'antd';
import { getSearchListResult, useSearchDispatcher, useSearchContext, setSearchtermValue } from '../../action/SearchAction';
import { ProductSearchDataRequestModel, CartDataRequestModel, WishlistSearchDataRequestModel, CartPostModel } from '../../api';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthService from '../../_services/AuthService';
import Row from 'react-bootstrap/Row';
import { Col, Dropdown } from 'react-bootstrap';
import { useLanguageContext, useLanguageDispatcher, getLanguageList } from '../../action/LanguageAction';
import { useUserDispatcher, useUserContext, getUserListResult } from '../../action/UserAction';
import { getCartItemsList, useCartDispatcher, useCartContext, deleteCartItem } from '../../action/CartAction';
import { useWishListDispatcher, useWishListContext, getWishListListResult } from '../../action/WishLIstAction';
import SmallCardWishList from './SmallCardWishList';
import SmallCardCartList from './SmallCardCartList';
import { AppUrls, environment } from '../../_environments/environment';
import axios from 'axios';
import { message } from 'antd';
import { AlexaForBusiness } from 'aws-sdk';
import { User } from '../../_models/User';
const { Option } = Select;

//....................................IHomeHeaderProps Interface................................................

interface IHomeHeaderProps extends RouteComponentProps {
  onHandleLanguageId: (languageId: string) => void;
  onHandleSearchTerm: (searchTerm: string) => void;
  isCartLoad: number;
  updated: number;
  isWishListLoad: number;
  onHandleCartLoad: () => void;
  onHandleWishListLoad: () => void;
}
//.....................................IHomeHeaderState Interface..................................................

interface IHomeHeaderState {
  visible: boolean,
  searchTerm: string,
  cartItemNo: number | undefined,
  isCarted: boolean,
  isWishList: boolean,
  isCartValueAdd: boolean,
  updater: boolean,
  wishItemNo: number | undefined,
  isWishListDelete: boolean,
  wishListId: string;
  isWishListLoder: number,
  isCartDelete: boolean,
  isupdateLastLogin:boolean,
  CartId: string,
  isCartLoder: number,
}

const initialState = {
  visible: false,
  searchTerm: "",
  cartItemNo: 0,
  isCarted: false,
  isCartValueAdd: false,
  updater: false,
  isWishList: false,
  wishItemNo: 0,
  isWishListDelete: false,
  wishListId: "",
  isWishListLoder: 0,
  isCartDelete: false,
  isupdateLastLogin:false,
  CartId: "",
  isCartLoder: 0,
} as IHomeHeaderState

//.......................................HomeHeader Method starts here.............................................

const HomeHeader: React.FC<IHomeHeaderProps> = (props: any) => {
  let currentUser = AuthService.currentUser;
  const { onHandleLanguageId, onHandleSearchTerm, isCartLoad, updated,
    isWishListLoad, onHandleWishListLoad, onHandleCartLoad } = props;
  const [
    homeHeaderState,
    setHomeHeaderState
  ] = React.useState<IHomeHeaderState>(initialState);
  
  const searchDispatcher = useSearchDispatcher();
  const searchContext = useSearchContext();
  const languagehDispatcher = useLanguageDispatcher();
  const languageContext = useLanguageContext();

  const userDispatcher = useUserDispatcher();
  const userContext = useUserContext();
  const { users } = userContext

  const { visible, cartItemNo, isCarted, isCartValueAdd, isCartLoder,
    updater, isWishListLoder, isWishList, wishItemNo, wishListId, isWishListDelete, CartId, isCartDelete,isupdateLastLogin } = homeHeaderState
  const { searchResult, searchTerm } = searchContext;
  const { languages } = languageContext;

  const cartDispatcher = useCartDispatcher();
  const cartContext = useCartContext();
  const { cartItems } = cartContext;

  const wishListDispatcher = useWishListDispatcher();
  const wishListContext = useWishListContext();
  const { wishListResult } = wishListContext;

  let token = AuthService.accessToken;
  let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";

  const key = 'updatable';

  //...........................................................................................

console.log("Inside HomeHeader.tsx->before useEffect-113 ");
React.useEffect(() => 
{
  (
    async () => 
    {
      await getLanguageList(languagehDispatcher)
    }
  )();
},[authorizationToken])
console.log("Inside HomeHeader.tsx->after useEffect-119 ");

//.............................................................................................

console.log("Inside HomeHeader.tsx->before useEffect-123 ");
  React.useEffect(() => 
  {
    (async () => 
    {
      await getUserListResult(userDispatcher)
      setHomeHeaderState({
        ...homeHeaderState,
        isCarted: true,
        isWishList: true,
      });
    })();
  }, [authorizationToken])

//..............................................................................................

console.log("Inside HomeHeader.tsx->before useEffect-139 ");
  React.useEffect(() => {
    if (isCarted == true || isCartLoad >= 1 || isCartLoder >= 1) {
      (async () => {
        await getCartItemsList(cartDispatcher, { currencyId: users.defaultCurrencyId } as CartDataRequestModel);
        setHomeHeaderState({
          ...homeHeaderState,
          isCartValueAdd: true,
          isCarted: false,
        });
      })();

    }
  }, [isCartLoder, isCarted, isCartLoad])

  //.............................................................................................

console.log("Inside HomeHeader.tsx->before useEffect-157 ");
  React.useEffect(() => {
    if (isWishList == true || isWishListLoad == true || isWishListLoder >= 1) {
      (async () => {
        await getWishListListResult(wishListDispatcher, { currencyId: "1" } as WishlistSearchDataRequestModel);
        setHomeHeaderState({
          ...homeHeaderState,
          isWishList: false,
        });
      })();
    }
  }, [isWishListLoder, isWishList, isWishListLoad])

  //............................................................................................

  React.useEffect(() => {
    (async () => {
      // await getCartItemsList(cartDispatcher,{} as CartDataRequestModel );
      setHomeHeaderState({
        ...homeHeaderState,
        cartItemNo: cartItems.searchResult?.data?.length,
      });
    })();
  }, [cartItems.searchResult?.data])

//................................................................................................

  React.useEffect(() => {
    (async () => {
      // await getCartItemsList(cartDispatcher,{} as CartDataRequestModel );
      setHomeHeaderState({
        ...homeHeaderState,
        wishItemNo: wishListResult.searchResult?.data?.length,
      });
    })();
  }, [wishListResult.searchResult?.data])

//................................................................................................

  React.useEffect(() => {
    (async () => {
      // await getCartItemsList(cartDispatcher,{} as CartDataRequestModel );
      setHomeHeaderState({
        ...homeHeaderState,
        cartItemNo: cartItems.searchResult?.data?.length,
        isCartValueAdd: false
      });
    })();
  }, [isCartLoad, isCartValueAdd])

//................................................................................................

  React.useEffect(() => {
    (async () => {

      await getSearchListResult(searchDispatcher,
        {
          SearchCriteria: { CategoryIds: [], LanguageIds: [], CurrencyId: users.defaultCurrencyId },
          Page: 1,
          PageSize: 20,
          SearchTerm: searchTerm
        } as {} as ProductSearchDataRequestModel);
    })();
  }, [searchTerm])

  //..............................................................................................
console.log("In HomeHeader.tsx Line 226");
console.log(24,isupdateLastLogin)
React.useEffect
(() => 
{
  if (isupdateLastLogin) 
  {
    (async () => 
      {
        console.log(25,"hi")     
        let headers = {
                        Authorization: `Bearer ${authorizationToken}`,
                        "Content-Type": "application/json",
                      };
        let formData = new FormData();
        const url = AppUrls.UpdateLastLoginTime
        axios.post(url, JSON.stringify({ email: users.email }), { headers: headers }).then
        (res => 
          {
            console.log(26,res)
            if (res.data.isSuccess) 
              {
                AuthService.logout();
                props.history.push('/login');
                window.location.reload();
              }
          }
        )
        }
      )();
    }
}, [isupdateLastLogin]
)

//...................................................................................................

  React.useEffect(() => {
    if (isWishListDelete) {
      (async () => {
        let headers = {
          Authorization: `Bearer ${authorizationToken}`,
          "Content-Type": "application/json",
        };

        let formData = new FormData();
        const url = AppUrls.AddToWishList
        axios.post(url, JSON.stringify({ audioId: wishListId }), { headers: headers }).then(res => {
          if (res) {

            setHomeHeaderState({
              ...homeHeaderState,
              isWishListDelete: false,
              wishListId: "",
              isWishListLoder: isWishListLoder + 1,
            })
            onHandleWishListLoad();
            setTimeout(() => {
              message.success({ content: res.data.message, key, duration: 2 });
            }, 1000);

          }
        })
      })();
    }
  }, [isWishListDelete])

  //..................................................................................................

  React.useEffect(() => {
    console.log(66666666666,"inapi",isCartDelete)
    if (isCartDelete) {
     
      (async () => {
        await deleteCartItem(cartDispatcher, { audioId: CartId } as CartPostModel);
        // onHandleCartLoad();
        // onHandleWishListLoad();
        setHomeHeaderState({
          ...homeHeaderState,
          isCartDelete: false,
          CartId: "",
          isCartLoder: isCartLoder + 1,
        })
      })();

    }
  }, [isCartDelete])

  //....................................................................................................

  const showDrawer = () => {
    setHomeHeaderState({
      ...homeHeaderState,
      visible: true,
    });
  };

  //.....................................................................................................

  const onClose = () => {
    setHomeHeaderState({
      ...homeHeaderState,
      visible: false,
    });
  };

  //.....................................................................................................

  const handleSearch = (e: any) => {
    onHandleSearch(e.target.value);

  }

  //......................................................................................................

  const onHandleSearch = (value: any) => {

    console.log("On handle search line 320 homeheader.tsx");
    onHandleSearchTerm(value);

    // props.history.push('/search');

  }

  //.....................................................................................................

  const onHandleSearchClick = (value: any) => {

    // onHandleSearchTerm(value);
    console.log("On handle search line 330 homeheader.tsx");
    alert("In home header 331"+value);

    props.history.push('/search');

  }

//........................................................................................................

  const handleLogOut = (value:any) => {
   
    setHomeHeaderState({
      ...homeHeaderState,
      isupdateLastLogin: true,
      
    });
   
    // if(users.email != null || users.email!= undefined || users.email!="")
    // {
       AuthService.logout();
   
    props.history.push('/login');
     window.location.reload();
    // }
    // AuthService.logout();
   
    // props.history.replace('/');
    // window.location.reload();


  }

  //......................................................................................................

  const handleLanguageId = (languageId: string) => {
    onHandleLanguageId(languageId);
  }

  //......................................................................................................

  const handleCartItem = () => {

  }

  //......................................................................................................

  const onhandleWishListDeleteClick = (id: any) => {
    setHomeHeaderState({
      ...homeHeaderState,
      isWishListDelete: true,
      wishListId: id,
    })
  }

//..........................................................................................................

  const onhandleCartDeleteClick = (id: any) => {
    console.log(777777777,id)
    setHomeHeaderState({
      ...homeHeaderState,
      isCartDelete: true,
      CartId: id,
    })
  }

console.log(55555,wishItemNo);

  // const menu = (
  //   <Menu>

  //     {wishListResult.searchResult.data.map((wishList, index) => {
  //       return (
  //         <>

  //           <Menu.Item style={{ listStyleType: "none" }} key={index}>
  //             <div className="tg-minicarproduct">
  //               <figure>
  //                 <img style={{ width: 70, height: 70 }} src={wishList && wishList.bookImages != undefined ? wishList.bookImages[0].filename : ""} alt="image description" />
  //               </figure>
  //               <div className="tg-minicarproductdata">
  //                 <h5><a href="javascript:void(0);">{wishList.title}</a></h5>
  //                 <h6><a href="javascript:void(0);">{wishList.currencySymbol} {wishList.realPrice}</a></h6>
  //               </div>
  //             </div>
  //           </Menu.Item>
  //         </>)
  //     })}

  //     <Menu.Item style={{ listStyleType: "none" }} key='p+1'>

  //       <Link to="/wishList">
  //         <h3 className="tg-btn tg-active" >View WishList</h3>
  //       </Link>
  //     </Menu.Item>
  //   </Menu>
  // );


  // const cart = (
  //   <Menu>
  //     {cartItems.searchResult.data.map((cartItem, index) => {

  //       return (<>
  //         <Menu.Item style={{ listStyleType: "none" }} key={'j' + index}>
  //           <div className="tg-minicarproduct">
  //             <figure>
  //               <img style={{ width: 70, height: 70 }} src={cartItem && cartItem.bookImages != undefined ? cartItem.bookImages[0].filename : ""} alt="image description" />
  //             </figure>
  //             <div className="tg-minicarproductdata">
  //               <h5><a >{cartItem.title}</a></h5>
  //               <h6><a >  {cartItem.offerPrice == 0 ? (<>{cartItem.currencySymbol}  {cartItem.realPrice}</>) : (<>
  //                 <s style={{ color: 'sandybrown' }}>{cartItem.currencySymbol}  {cartItem.realPrice}</s> &nbsp;
  //                     {cartItem.currencySymbol}  {cartItem.offerPrice}
  //               </>
  //               )}</a></h6>
  //             </div>
  //           </div>
  //         </Menu.Item>
  //       </>)
  //     })}
  //     <Menu.Item style={{ listStyleType: "none" }} key='j+r'>

  //       <div className="tg-minicartfoot">
  //         <a className="tg-btnemptycart" href="javascript:void(0);">
  //           <i className="fa fa-trash-o" />
  //           <span>Clear Your Cart</span>
  //         </a>
  //         <span className="tg-subtotal">Subtotal: <strong>35.78</strong></span>
  //         <div className="tg-btns">
  //           <Link to="/cart_Items">  <a className="tg-btn tg-active" href="javascript:void(0);">View Cart</a></Link>
  //           <a className="tg-btn" href="javascript:void(0);">Checkout</a>
  //         </div>
  //       </div>

  //     </Menu.Item>
  //   </Menu>
  // );


  // const language = (
  //   <Menu>

  //     {languages.languages?.map((language, index) => {
  //       if (language.id != undefined)
  //         return (
  //           <>
  //             <Menu.Item style={{ listStyleType: "none" }}>
  //               <a href="#">
  //                 <span>{language.title}</span>
  //               </a>
  //             </Menu.Item>
  //             {/* <Button color="secondary" style={{ border: "white" }} onClick={() => handleLanguageId(language.id ? language.id : "")}>{language.title}</Button> <br /> */}
  //           </>
  //         )
  //     })}

  //   </Menu>

  // );



  return (
    <>
{/* ...........................................Search.......................................................  */}

      <header id="tg-header" className="tg-header tg-headervtwo tg-haslayout">
        <div className="tg-middlecontainer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <strong className="tg-logo"><img src={require('../../images/logo.png')} alt="Vocabooks" /></strong>
                <div className="tg-searchbox">
                  <form className="tg-formtheme tg-formsearch">
                    <fieldset>
                      <input type="text" onChange={handleSearch} className="typeahead form-control" placeholder="Search by title, author, keyword..." />
                      <button onClick={onHandleSearchClick} className="tg-btn">Search</button>
                    </fieldset>
                    <Link to="/advanceSearch">+  Advanced Search</Link>
                  </form>

                  <div className="search-box">
                    <input type="text" placeholder="Type to search" className="search-txt" />
                    <a href="#" className="search-btn">
                      <i className="fa fa-search" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

{/* ........................................................................................................... */}



        <div className="tg-navigationarea">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tg-navigationholder">


                  <nav id="tg-nav" className="tg-nav">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#tg-navigation" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </button>
                    </div>
                    <div id="tg-navigation" className="collapse navbar-collapse tg-navigation">
                      <ul>
                        <li className="menu-item-has-children">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="/packages_offers">Packages &amp; Offers</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="/purchased_books">Purchased</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <a href='#' className="newstyle"> Books </a>
                          <ul className="sub-menu">
                            <li><Link to="/audioBooks">Audio Book</Link></li>
                            <li><Link to="/eBooks">E Books</Link></li>
                          </ul>
                        </li>
                        <li><Link to="/contact_us">Contact</Link></li>
                      </ul>
                    </div>
                  </nav>





                  <div className="tg-wishlistandcart">
                   <div className="dropdown tg-themedropdown tg-wishlistdropdown menu-item-has-children"> 

                      {
                      /* <li className="menu-item-has-children dropdown tg-themedropdown tg-wishlistdropdown menu-item-has-children" style={{ listStyle: 'none' }}>

                        <a    className="tg-btnthemedropdown"  aria-haspopup="true" aria-expanded="false">

                          <Badge count={wishItemNo}>
                            <i className="icon-heart"></i></Badge>
                        </a>

                        <ul className="sub-menu">
                          <SmallCardWishList
                            wishListResult={wishListResult}
                            onhandleWishListDeleteClick={onhandleWishListDeleteClick}
                          />
                        </ul>
                      </li> */
                      }

                       <a href="javascript:void(0);" id="tg-wishlisst" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            
                        <Badge count={wishItemNo}>
                        <i className="icon-heart"></i></Badge>
                      </a>
                      

                      <div className="dropdown-menu tg-themedropdownmenu"  aria-labelledby="tg-minicart">
                        <SmallCardWishList
                          wishListResult={wishListResult}
                          onhandleWishListDeleteClick={onhandleWishListDeleteClick}
                      />

                      </div> 

                     </div> 

                    <div className="dropdown tg-themedropdown tg-minicartdropdown"> 

                      {
                      /* <li className="menu-item-has-children dropdown tg-themedropdown tg-minicartdropdown" style={{ listStyle: 'none' }}>
                        <a  id="tg-minicart" className="tg-btnthemedropdown"  aria-haspopup="true" aria-expanded="false">
                          <Badge count={cartItemNo}>
                            <i className="icon-cart"></i>
                          </Badge>
                        </a>
                        <ul className="sub-menu">
                          <SmallCardCartList
                            cartItems={cartItems}
                            onhandleCartDeleteClick={onhandleCartDeleteClick}
                          />
                          
                        </ul>
                      </li> */
                      }

                     <a href="javascript:void(0);" id="tg-minicart" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <Badge count={cartItemNo}>
                        <i className="icon-cart">
                        </i>
                        </Badge>
                      </a>



                      <div  className="dropdown-menu  tg-themedropdownmenu" aria-labelledby="tg-minicart">
                        <SmallCardCartList
                          cartItems={cartItems}
                          onhandleCartDeleteClick={onhandleCartDeleteClick}
                        />
                      </div> 
                      </div> 


                      {/* </div> */}

                    {/* </div> */}



                    <div className="dropdown tg-themedropdown tg-currencydropdown"> 
                      <div id="tg-currenty" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                      {/* <span>Hi, {users.firstName}</span>  */}
                 
                        <Avatar  src={ 
                    environment.baseUrl 
                    +
                    `/Upload/ProfileImage/${users.profileImageURL}` 
                  }  /> 
                       </div>
                      {/* <li className="menu-item-has-children dropdown tg-themedropdown tg-currencydropdown" style={{ listStyle: 'none' }}>
                        <Avatar src={users.profileImageURL} />
                        <ul className="sub-menu" style={{ listStyle: 'none' }}>
                          <li style={{ listStyle: 'none' }}>
                            <Link to="/accountDetails">
                              <span>Account Details</span>
                            </Link>
                          </li>
                          <li style={{ listStyle: 'none' }}>
                            <Link to="/order_details">
                              <span>Order History</span>
                            </Link>
                          </li>
                          <li style={{ listStyle: 'none' }}>
                            <Link to="/request_to_user">
                              <span>Requesting User</span>
                            </Link>
                          </li>

                          <li style={{ listStyle: 'none' }}>
                            <span>
                              <a onClick={handleLogOut}>  -Log Out</a>
                            </span>
                          </li>
                        </ul>
                      </li> */}
                       <ul className="dropdown-menu tg-themedropdownmenu" aria-labelledby="tg-currenty">
                        <li>
                          <Link to="/accountDetails">
                            <span>Account Details</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/order_details">
                            <span>Order History</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/request_to_user">
                            <span>Requesting User</span>
                          </Link>
                        </li>
                       
                        <li>
                          <span>
                          <a onClick={()=>handleLogOut(users.email)}>  -Log Out</a>
                            {/* <a onClick={handleLogOut(users.email)}>  -Log Out</a> */}
                          </span>
                        </li>
                      </ul> 
                      
                     </div> 


                   <div className="dropdown tg-themedropdown tg-currencydropdown" style={{backgroundColor:"#FA3700"}}> 
                   
                       <span id="tg-currenty" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>

                        Language
              </span>
                      </span>

<ul className="dropdown-menu tg-themedropdownmenu" aria-labelledby="tg-currenty">

  {languages.languages?.map((language, index) => {
    if (language.id != undefined)
      return (
        <>
          <li>
            <span>
              <span onClick={() => handleLanguageId(language.id ? language.id : "")}>{language.title}</span>
            </span>
          </li>

        </>
      )
  })}
</ul> 
                      {/* <li className="menu-item-has-children dropdown tg-themedropdown tg-currencydropdown" style={{ listStyle: 'none' }}>
                        <a href='#' className="newstyle"> languages </a>
                        <ul className="sub-menu" style={{ listStyle: 'none' }}>
                          {languages.languages?.map((language, index) => {
                            if (language.id != undefined)
                              return (
                                <>
                                  <li style={{ listStyle: 'none' }}>
                                    <span>
                                      <span onClick={() => handleLanguageId(language.id ? language.id : "")}>{language.title}</span>
                                    </span>
                                  </li>

                                </>
                              )
                          })}
                        </ul>
                      </li> */}

                    </div> 

{/* ......................................... List and cart................................................. */}

{/* <div className="dropdown tg-themedropdown tg-wishlistdropdown menu-item-has-children"> 
  <a href="javascript:void(0);" id="tg-wishlisst" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <Badge count={wishItemNo}>
      <i className="icon-heart"></i>
    </Badge>
  </a>
  <div className="dropdown-menu tg-themedropdownmenu"  aria-labelledby="tg-minicart">
    <SmallCardWishList
       wishListResult={wishListResult}
      onhandleWishListDeleteClick={onhandleWishListDeleteClick}
    />
</div> 

</div>  */}


{/* ........................................................................................................ */}
{/*.................................... Language DropDown.......................................... */}

                    {/* <div className="dropdown tg-themedropdown tg-currencydropdown menu-item-has-children" style={{backgroundColor:"#FA3700"}}> 
                      <span id="tg-currenty" className="tg-btnthemedropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>
                          Language
                        </span>
                      </span>

                      <ul className="dropdown-menu tg-themedropdownmenu" aria-labelledby="tg-currenty">
                              {languages.languages?.map((language, index) => {
                                if (language.id != undefined)
                                    return (
                                      <>
                                      <li>
                                        <span>
                                          <span onClick={() => handleLanguageId(language.id ? language.id : "")}>{language.title}</span>
                                          </span>
                                      </li>

                                    </>
                                      )
                        }
                        )
                        }
                      </ul> 
                    </div>  */}
{/* ....................................................................................................... */}

                  </div>
                </div></div></div></div></div>

      </header>

    </>
  );
};

export default withRouter(HomeHeader);