import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import ReactWOW from 'react-wow';
import ScrollAnimation from 'react-animate-on-scroll';
// import { Tabs, Empty } from 'antd';
import Header from '../components/shared/Header';
import Footer from '../components/shared/Footer';
import FrontPageBanner from '../components/FrontPageBanner';
import FrontPageTabViewComponenet from '../components/FrontpageTabViewComponent';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Registration from '../registration/Registration';
import LoginContainer from '../login/LoginContainer';
import TermsAndConditions from '../general/TermsAndConditions';
import ServiceAndCondition from '../general/ServiceAndCondition';
import AuthService from '../_services/AuthService';
import axios from 'axios';
import { AppUrls } from '../_environments/environment';
import { ProductShortInfo, GetHomeProductsPostModel } from '../api';
import HomeBookPageCategoryComponent from '../components/shared/homeBooks/HomeBookPageCategoryComponent';
import BookDetailsContainer from '../bookdetails/BookDetailsContainer';
import { BookDetailsContextProvider } from '../context/BookDetailsContext';
import { ProductReviewContextProvider } from '../context/ProductReviewContext';
import { CartContextProvider } from '../context/CartContext';
import { useLanguageDispatcher, useLanguageContext, getLanguageList } from '../action/LanguageAction';
import { AboutUsContextProvider } from '../context/AboutUsContext';
import AboutUsContainer from '../aboutUs/AboutUsContainer';

import { WishListContextProvider } from '../context/WishListContext';
// import { url } from 'inspector';
import { useSpring, useTransition, animated } from 'react-spring'
import { Spring } from 'react-spring/renderprops';
import TransactionStatus from '../transaction/TransactionStatus';
import ForgotPassword from '../forgotPassword/ForgotPasswordContainer';
import PackageTransactionContainer from '../packageTransaction/PackageTransactionContainer';
import PackagesAndOffersContainer from '../packages_offers/PackagesAndOffersContainer';
import ContactUsContainer from '../contactUs/ContactUsContainer';
import { Navbar, Nav, NavDropdown, Tabs, Tab, } from 'react-bootstrap';
import FrontPageTabView from '../components/shared/frontPageBooks/FrontPageTabView';
import { useHomeDispatcher, useHomeContext, getHomeListProduct } from '../action/HomeAction';
require('../css/assets/css/style.css')
// var ReactCSSTransitionGroup = require('react-addons-css-transition-group');
// require('../css/style.css')
let token = AuthService.accessToken;
let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";
// require('./css/animate.css')
// const { WOW } = require('wowjs');

console.log("Inside FrontPage");
// require('../css/assets/vendor/aos/aos.css')
// require('../css/assets/vendor/icofont/icofont.min.css')
// require('../css/assets/vendor/bootstrap/css/bootstrap.min.css')

// const { TabPane } = Tabs;
console.log("Inside Front Page");

interface IFrontPageProps {
  authorizationToken: any;
}

interface IFrontPageState {
  mostratedAudios: ProductShortInfo[],
  newReleasedAudios: ProductShortInfo[],
  languageId: any;
  tabKey: string;
  packagePrice: number,
  memberCount: number,
}

const initialState = {
  mostratedAudios: [],
  newReleasedAudios: [],
  languageId: "",
  tabKey: '1',
  packagePrice: 0,
  memberCount: 0,
} as IFrontPageState



const FrontPage: React.FC<IFrontPageProps> = (props) => {
console.log("Inside FrontPage.tsx and this is value of props"+props);
  const [
    frontPageState,
    setFrontPageState
  ] = React.useState<IFrontPageState>(initialState);

  const { authorizationToken } = props;
  const { mostratedAudios, newReleasedAudios, languageId, tabKey, packagePrice, memberCount } = frontPageState

  const languagehDispatcher = useLanguageDispatcher();
  const languageContext = useLanguageContext();
  const { languages } = languageContext;

  const homeDispatcher = useHomeDispatcher();
  const homeContext = useHomeContext();
  const { value, visible, homeProducts } = homeContext;
  console.log(235,homeProducts)
  console.log(234, languages && languages.languages && languages.languages[0] != undefined ? languages.languages[0].id : "")

  React.useEffect(() => {
    (async () => {
      await getLanguageList(languagehDispatcher)

    })();
  }, [])

  React.useEffect(() => {
    (async () => {
      let m = languages.languages?.map((lan, index) => {
        return lan.id;
      })

      setFrontPageState({
        ...frontPageState,
        languageId: m ? m[0] : null

      });

    })();
  }, [languages])

  React.useEffect(() => {
    (async () => {
        await getHomeListProduct(homeDispatcher, { languageId: languageId, currencyId: "1" } as GetHomeProductsPostModel);
    })();
}, [languageId])

  React.useEffect(() => {
    if (languageId && tabKey === '1')
      (async () => {

        let header = {
          "Content-Type": "application/json",
        };

        const url = AppUrls.GetMostRateUrl

        axios.post(url, JSON.stringify({
          // languageId: languages && languages.languages && languages.languages[0] != undefined ? languages.languages[0].id : "",
          languageId: languageId,
          currencyId: "1"
        }
        ), { headers: header })
          .then(res => {
            setFrontPageState({
              ...frontPageState,
              mostratedAudios: res.data.productShortInfos
            });
          })

      })();
  }, [languageId, tabKey])


  React.useEffect(() => {
    if (languageId && tabKey === '2')
      (async () => {

        let header = {
          "Content-Type": "application/json",
        };

        const url = AppUrls.GetNewReleasedAudios

        axios.post(url, JSON.stringify({
          // languageId: languages && languages.languages && languages.languages[0] != undefined ? languages.languages[0].id : "",
          languageId: languageId,
          currencyId: "1"
        }
        ), { headers: header })
          .then(res => {
            setFrontPageState({
              ...frontPageState,
              newReleasedAudios: res.data.productShortInfos
            });
          })

      })();
  }, [languageId, tabKey])

  const onHandleLanguageId = (languageId: string) => {
    setFrontPageState((frontPageState) => {
      return {
        ...frontPageState,
        languageId: languageId
      }
    });
  }

 

  const onHandleCartLoad = () => {

  }

  const onHandleWishListLoad = () => {

  }

  const onHandlePackagePriceLoad = (value: any) => {
    setFrontPageState({
      ...frontPageState,
      packagePrice: value,
    });

  }

  const onHandlememeberCountLoad = (memberCount: any) => {
    setFrontPageState({
      ...frontPageState,
      memberCount: memberCount,
    });
    
  }

  const onTabkeyChange = (key: any) => {
    setFrontPageState({
      ...frontPageState,
      tabKey: key
    });
  }

  var imgUrl = require('../css/assets/img/banner-bg.jpg')
  const propss = useSpring({ opacity: 1, from: { opacity: 0 } })

console.log("Front Page Line 228");

  return (

    <>
      <Route exact path='/login'>
        <LoginContainer {...props} />
      </Route>

      <Route path='/register'>
        <Registration {...props}
          onHandlePackagePriceLoad={onHandlePackagePriceLoad}
          onHandlememeberCountLoad={onHandlememeberCountLoad}
        />
      </Route>

      <Route exact path='/forgot_password'>
        <ForgotPassword />
      </Route>

      <Route path='/service-and-condition'>
        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>
            <nav className="nav-menu  d-lg-block">
              <ul>
                <li className="get-started"><Link to='/login'> Log In / Sign up</Link> </li>
                {/* <li className="get-started"><Link to='/register'>Sign In</Link> </li> */}
              </ul>
            </nav>
          </div>
        </header>
        <ServiceAndCondition />
        <footer id="footer" >

          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <span>Copyright © 2020, All Right Reserved Vocabooks</span>
                </div>
                {/* End Col */}
                <div className="col-md-6">
                  <div className="copyright-menu">
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li>
                        <Link to='/terms-and-condition'>Terms</Link>
                      </li>
                      <li>
                        <Link to='/service-and-condition'>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to='/about_us'></Link>

                      </li>
                    </ul>
                  </div>
                </div>
                {/* End col */}
              </div>
              {/* End Row */}
            </div>
            {/* End Copyright Container */}
          </div>
          {/* End Copyright */}
          {/* Back to top */}
          <div id="back-to-top" className="back-to-top">
            <button className="btn btn-dark" title="Back to Top" style={{ display: 'block' }}>
              <i className="fa fa-angle-up" />
            </button>
          </div>
          {/* <div className="container footer-bottom clearfix">
    <div className="copyright">
      © Copyright <strong><span>Vocabooks</span></strong>. All Rights Reserved
  </div>
    <div className="credits">
      Designed by <a href="#">Vocabooks</a>
    </div>
  </div> */}
        </footer>
      </Route>

      <Route exact path="/packages_offers">
        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>

            <Navbar bg="light" expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/packages_offers'>Packages_offers</Link> </li>
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/contact_us'>Contact US</Link> </li>
                  <li className="menu-item-has-children get-links" style={{ listStyle: 'none' }}>
                    <a href='#' className="get-links"> languages </a>
                    <ul className="sub-menu" style={{ listStyle: 'none', marginTop: -1 }}>
                      {languages.languages?.map((language, index) => {
                        if (language.id != undefined)
                          return (
                            <>
                              <li style={{ listStyle: 'none' }}>
                                <span>
                                  <span onClick={() => onHandleLanguageId(language.id ? language.id : "")}>
                                    <a> {language.title}</a>
                                  </span>
                                </span>
                              </li>

                            </>
                          )
                      })}
                    </ul>
                  </li>
                  <li className="get-started" style={{ listStyle: 'none' }}><Link to='/login'> Log In / Sign up</Link> </li>
                </Nav>

              </Navbar.Collapse>
            </Navbar>
          </div>
        </header>
        < PackagesAndOffersContainer
          onHandlePackagePriceLoad={onHandlePackagePriceLoad}
          onHandlememeberCountLoad={onHandlememeberCountLoad}
        />

      </Route>

      <Route path='/terms-and-condition'>
        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>
            <nav className="nav-menu  d-lg-block">
              <ul>
                <li className="get-started"><Link to='/login'> Log In / Sign up</Link> </li>
                {/* <li className="get-started"><Link to='/register'>Sign In</Link> </li> */}
              </ul>
            </nav>
          </div>
        </header>
        <TermsAndConditions />
        <footer id="footer" >

          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <span>Copyright © 2020, All Right Reserved Vocabooks</span>
                </div>
                {/* End Col */}
                <div className="col-md-6">
                  <div className="copyright-menu">
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li>
                        <Link to='/terms-and-condition'>Terms</Link>
                      </li>
                      <li>
                        <Link to='/service-and-condition'>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to='/about_us'></Link>

                      </li>
                    </ul>
                  </div>
                </div>
                {/* End col */}
              </div>
              {/* End Row */}
            </div>
            {/* End Copyright Container */}
          </div>
          {/* End Copyright */}
          {/* Back to top */}
          <div id="back-to-top" className="back-to-top">
            <button className="btn btn-dark" title="Back to Top" style={{ display: 'block' }}>
              <i className="fa fa-angle-up" />
            </button>
          </div>
          {/* <div className="container footer-bottom clearfix">
            <div className="copyright">
              © Copyright <strong><span>Vocabooks</span></strong>. All Rights Reserved
          </div>
            <div className="credits">
              Designed by <a href="#">Vocabooks</a>
            </div>
          </div> */}
        </footer>
      </Route>
      <Route exact path="/about_us">
        <AboutUsContextProvider>
          <AboutUsContainer />
        </AboutUsContextProvider>
      </Route>

      <Route exact path='/package_transaction'>
        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>
            <nav className="nav-menu  d-lg-block">
              <ul>
                <li className="get-started"><Link to='/login'> Log In / Sign up</Link> </li>
                {/* <li className="get-started"><Link to='/register'>Sign In</Link> </li> */}
              </ul>
            </nav>
          </div>
        </header>

        <PackageTransactionContainer
          packagePrice={packagePrice}
          memberCount={memberCount}
        />
      </Route>
      <Route exact path="/contact_us">
        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>

            <Navbar bg="light" expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/packages_offers'>Packages_offers</Link> </li>
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/contact_us'>Contact US</Link> </li>
                  <li className="menu-item-has-children get-links" style={{ listStyle: 'none' }}>
                    <a href='#' className="get-links"> languages </a>
                    <ul className="sub-menu" style={{ listStyle: 'none', marginTop: -1 }}>
                      {languages.languages?.map((language, index) => {
                        if (language.id != undefined)
                          return (
                            <>
                              <li style={{ listStyle: 'none' }}>
                                <span>
                                  <span onClick={() => onHandleLanguageId(language.id ? language.id : "")}>
                                    <a> {language.title}</a>
                                  </span>
                                </span>
                              </li>

                            </>
                          )
                      })}
                    </ul>
                  </li>
                  <li className="get-started" style={{ listStyle: 'none' }}><Link to='/login'> Log In / Sign up</Link> </li>
                </Nav>

              </Navbar.Collapse>
            </Navbar>
          </div>
        </header>
        <AboutUsContextProvider>
          <ContactUsContainer />
        </AboutUsContextProvider>

      </Route>
      <Route exact path="/transaction/status/:id">
        <TransactionStatus {...props} />
      </Route>














      <Route exact path="/">






        <header id="header">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <a href="#"><img alt="Logo" src={require('../css/assets/img/logo.png')} /></a>
            </div>



            {/* <nav className="nav-menu  d-lg-block">
              <ul>
              <li className="get-links"><Link to='/packages_offers'>Packages_offers</Link> </li>
              <li className="get-links"><Link to='/contact_us'>Contact US</Link> </li>
              <li className="menu-item-has-children get-links" style={{ listStyle: 'none' }}>
                        <a href='#' className="get-links"> languages </a>
                        <ul className="sub-menu" style={{ listStyle: 'none' }}>
                          {languages.languages?.map((language, index) => {
                            if (language.id != undefined)
                              return (
                                <>
                                  <li style={{ listStyle: 'none' }}>
                                    <span>
                                       <span onClick={() => onHandleLanguageId(language.id ? language.id : "")}> 
                                       <a> {language.title}</a>
                                         </span> 
                                    </span>
                                  </li>

                                </>
                              )
                          })}
                        </ul>
                      </li>
                <li className="get-started"><Link to='/login'> Log In / Sign up</Link> </li>
                
              </ul>
            </nav> */}


            <Navbar bg="light" expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/packages_offers'>Packages_offers</Link> </li>
                  <li className="get-links" style={{ listStyle: 'none' }}><Link to='/contact_us'>Contact US</Link> </li>
                  <li className="menu-item-has-children get-links" style={{ listStyle: 'none' }}>
                    <a href='#' className="get-links"> languages </a>
                    <ul className="sub-menu" style={{ listStyle: 'none', marginTop: -1 }}>
                      {languages.languages?.map((language, index) => {
                        if (language.id != undefined)
                          return (
                            <>
                              <li style={{ listStyle: 'none' }}>
                                <span>
                                  <span onClick={() => onHandleLanguageId(language.id ? language.id : "")}>
                                    <a> {language.title}</a>
                                  </span>
                                </span>
                              </li>

                            </>
                          )
                      })}
                    </ul>
                  </li>
                  <li className="get-started" style={{ listStyle: 'none' }}><Link to='/login'> Log In / Sign up</Link> </li>
                </Nav>

              </Navbar.Collapse>
            </Navbar>





          </div>
        </header>




        <section id="hero" style={{ backgroundImage: "url(" + imgUrl + ")", height: 480 }}>

          <div className="container">
            <div className="row d-flex align-items-center">
              <div className=" col-lg-6 py-5 py-lg-0 order-2 order-lg-1" data-aos="">

                <Spring
                  from={{ opacity: 0, marginLeft: -500 }}
                  to={{ opacity: 1, marginLeft: 0, marginTop: 160 }}
                >
                  {props => (<div style={props}>
                    <h1>VOCABOOKS</h1>
                    <h2>Entertainment and Enlightenment <br />combine to form Vocabooks...</h2>
                  </div>
                  )}
                </Spring>

              </div>


              <div className="col-lg-6 order-1 order-lg-2 hero-img" >
                <ScrollAnimation animateIn="fadeInRight">

                </ScrollAnimation>
              </div>



            </div>
          </div>
        </section>

        <main id="main">


          <section id="about" className="about section-bg">
            <div className="container">




              <CartContextProvider>
                <WishListContextProvider>
                  <FrontPageTabView
                    mostratedAudios={mostratedAudios}
                    newReleasedAudios={newReleasedAudios}
                    homeProducts={homeProducts.bookCategory}
                    onHandleCartLoad={onHandleCartLoad}
                    onHandleWishListLoad={onHandleWishListLoad}
                    onTabkeyChange={onTabkeyChange}

                  />
                </WishListContextProvider>
              </CartContextProvider>

              {/* <Tabs defaultActiveKey="1" onChange={onTabkeyChange}>

                <TabPane tab="Most Rated" key="1">

                  <div className="resp-tabs-container">
                    <div>
                      <div className="row" style={{ marginLeft: 1 }}>
                        {

                          mostratedAudios != null ? (mostratedAudios.slice(2).map((mostratedAudio, index) => {
                            return (
                              <>
                                <CartContextProvider>
                                  <WishListContextProvider>
                                    <FrontPageTabViewComponenet
                                      key={`product_${mostratedAudio.audioId}`}
                                      book={mostratedAudio}
                                      onHandleCartLoad={onHandleCartLoad}
                                      onHandleWishListLoad={onHandleWishListLoad}
                                    />
                                  </WishListContextProvider>
                                </CartContextProvider>
                              </>
                            )
                          })) : <h1>hellooooooo</h1>
                        }
                        {mostratedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

                          <h4>Loding...</h4> </div> : null}


                      </div>
                    </div>

                  </div>

                </TabPane>

                <TabPane style={{fontSize:20,fontFamily:'inherit'}} tab="New Released" key="2">

                  <div className="resp-tabs-container">
                    <div>
                      <div className="row" style={{ marginLeft: 1 }}>

                        {

                          newReleasedAudios != null ? (newReleasedAudios.slice(2).map((newReleasedAudio, index) => {
                            return (
                              <>
                                <CartContextProvider>
                                  <WishListContextProvider>
                                    <FrontPageTabViewComponenet
                                      key={`product_${newReleasedAudio.audioId}`}
                                      book={newReleasedAudio}
                                      onHandleCartLoad={onHandleCartLoad}
                                      onHandleWishListLoad={onHandleWishListLoad}
                                    />
                                  </WishListContextProvider>
                                </CartContextProvider>
                              </>
                            )
                          })) : null
                        }

                        {newReleasedAudios.length === 0 ? <div style={{ margin: '0px auto' }}>

                          <h4>Loding...</h4> </div> : null}
                      </div>
                    </div>
                  </div>

                </TabPane>
               

              </Tabs> */}
            </div>
          </section>



          <section id="about" className="about section-bg">
            <div className="container">
              <div className="row">
                <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" />
                <div className="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                  <div className="content d-flex flex-column justify-content-center">
                    <animated.div style={propss} >
                      <h3  >WHAT IS VOCA BOOKS</h3></animated.div>
                    <p data-aos="fade-in" />
                    <div className="row">
                      <div className="col-md-6 icon-box" >
                        <ScrollAnimation animateIn="fadeInUp">
                          <i className="icofont-smart-phone" />
                          <h4><a href="#">Listen anywhere, anytime</a></h4>
                          <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing!</p>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-6 icon-box" data-aos-delay={100}>
                        <ScrollAnimation animateIn="fadeInUp">
                          <i className="icofont-record" />
                          <h4><a href="#">Listen anywhere, anytime</a></h4>
                          <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing!</p>
                        </ScrollAnimation>
                      </div>
                      <div className="col-md-6 icon-box" data-aos-delay={200}>
                        <ScrollAnimation animateIn="fadeInUp">
                          <i className="icofont-book" />
                          <h4><a href="#">Listen anywhere, anytime</a></h4>
                          <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing!</p>
                        </ScrollAnimation>
                      </div>
                    </div>
                    <div className="row">
                      <nav className="nav-menu d-none d-lg-block" style={{ marginTop: '25px' }}>
                        <ScrollAnimation animateIn="fadeInUp">
                          <ul>
                            <li className="get-started"><a href="#about">Free Trial</a></li>
                          </ul>
                        </ScrollAnimation>
                      </nav>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <div>
            <section id="features" className="features section-bg">
              <div className="container">
                <div className="row content">
                  <div className="col-md-5 order-1 order-md-2" >
                    <ScrollAnimation animateIn="fadeInLeft">
                      <img src={require('../css/assets/img/MEMBER.png')} className="img-fluid" alt="" />
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-7 pt-5 order-2 order-md-1" >
                    <ScrollAnimation animateIn="fadeInRight">
                      <h3>What do you get as a member?</h3>
                      <p className="font-italic">
                      </p>
                      <ul>
                        <li><i className="icofont-check" /> 1 Credit a Month<br /><span>Use on any Voca Books regardless of the price.</span></li>
                        <li><i className="icofont-check" /> You own your Voca Books<br /><span>Use on any Voca Books regardless of the price.</span></li>
                        <li><i className="icofont-check" /> Easy exchanges<br /><span>Use on any Voca Books regardless of the price.</span></li>
                        <li><i className="icofont-check" /> Exclusive member-only savings<br /><span>Use on any Voca Books regardless of the price.</span></li>
                      </ul>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </section>

            <footer id="footer" style={{ backgroundColor: '#213b52' }}>
              <div className="footer-top">
                <div className="container" >
                  <ScrollAnimation animateIn="fadeInUp">
                    <div className="row  justify-content-center">
                      <div className="col-lg-6">
                        <h3 style={{ textTransform: 'uppercase' }}>LISTENING IS EASY</h3>
                        <p>Download the Voca Books app and listen to your books wherever you are. <br />Even if you switch devices, you'll never lose your place.</p>
                      </div>
                    </div>
                    <div className="row footer-newsletter justify-content-center">
                      <div className="col-lg-6" >
                        <ScrollAnimation animateIn="fadeInRight">
                          <i className="icofont-brand-android-robot" />
                        </ScrollAnimation>
                      </div>
                      <div className="col-lg-6" >
                        <ScrollAnimation animateIn="fadeInLeft">
                          <i className="icofont-brand-apple" />
                        </ScrollAnimation>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </footer>

            <section id="services" className="services section-bg">
              <div className="container">
                <div className="row">

                  <div className="col-md-6 d-flex align-items-stretch" >
                    <ScrollAnimation animateIn="fadeInRight">
                      <div className="card">
                        <div className="card-img">
                          <img src={require('../css/assets/img/services-1.jpg')} alt="..." />
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-6 d-flex align-items-stretch" >
                    <ScrollAnimation animateIn="fadeInLeft">
                      <div className="card">
                        <div className="card-img">
                          <img src={require('../css/assets/img/services-2.jpg')} alt="..." />
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </main>

        <footer id="footer" >
          <Footer />

          <div id="back-to-top" className="back-to-top">
            <button className="btn btn-dark" title="Back to Top" style={{ display: 'block' }}>
              <i className="fa fa-angle-up" />
            </button>
          </div>

        </footer>
        <a href="#" className="back-to-top"><i className="icofont-simple-up" /></a>


      </Route>

      {/* <Header/>
<Route exact path='/login'>
<LoginContainer {...props}/>
</Route>

<Route path='/register'>
<Registration {...props}/>
</Route>

<Route path='/terms-and-condition'>
<TermsAndConditions/>
</Route>




<Route extact path="/book_details/:id">
                < BookDetailsContextProvider>
                    <ProductReviewContextProvider>
                    <CartContextProvider>
                    <WishListContextProvider>
                        < BookDetailsContainer 
                        {...props}
                        onHandleCartLoad={onHandleCartLoad}
                        />
                        </WishListContextProvider>
                        </CartContextProvider>
                    </ProductReviewContextProvider>
                </BookDetailsContextProvider>

            </Route>



        <Route exact path="/">
            <FrontPageBanner/>
            <h3 style={{padding:10,paddingLeft:110,paddingRight:110,marginTop:30}}>Entertainment and Enlightenment combine to form Vocabooks</h3>
<p style={{padding:1,paddingLeft:110,paddingRight:110,lineHeight:2}}>Vocabooks brings you the magic of words in many languages, especially in Malayalam.
 Bringing alive a whole new world of entertainment and enlightenment while you work, walk, jog, cook or commute. 
We will unveil before you all genres of fiction and non-fiction in compatibly apt voices. Vocabooks listening experiences for you will be 
truly novel and invigorating. Capturing your imagination, kindling your zest for life, and making you a more charming, wiser person.
</p><br/><hr/>


<section>
                <div className="try_audio">
                    <div className=" container">
                        <h1 >Try an Voca Books, on us</h1>
                        <h2 className="wow fadeInRight" data-wow-delay=".4s">Here are some of our favourites to get you started. Listen to a sample by clicking on a play button.</h2>
                        
                        <div className="row">

                            <div className="col-sm-12 wow fadeInRight" data-wow-delay=".6s">
                                <div id="horizontalTab">

                                   
                                    <Tabs defaultActiveKey="1" onChange={onTabkeyChange}>

                                        <TabPane tab="Most Rated" key="1">
                                        
                                            <div className="resp-tabs-container">
                                                <div>
                                                    <div className="row" style={{ marginLeft:1}}>
                                                   {

mostratedAudios!=null ?(mostratedAudios.map((mostratedAudio, index) => {
    return (
        <>
            <FrontPageBookComponenet
                key={`product_${mostratedAudio.audioId}`}
                book={mostratedAudio}
               
            />
        </>
    )
})):<h1>hellooooooo</h1>
} 
{mostratedAudios.length===0?<div style={{margin:'0px auto'}}>
  
  <h4>Loding...</h4> </div>:null}
                               
                                            
                                                    </div>
                                                </div>

                                            </div>
                                           
                                        </TabPane>

                                        <TabPane tab="New Released" key="2">
                                        
                                        <div className="resp-tabs-container">
                                        <div>
                                            <div className="row" style={{ marginLeft:1}}>
                                           
                                            {

newReleasedAudios!=null ?(newReleasedAudios.map((newReleasedAudio, index) => {
    return (
        <>
            <FrontPageBookComponenet
                key={`product_${newReleasedAudio.audioId}`}
                book={newReleasedAudio}
               
            />
        </>
    )
})):null
} 
 
{newReleasedAudios.length===0?<div style={{margin:'0px auto'}}> 

 <h4>Loding...</h4> </div>:null}
                                            </div>
                                        </div>
                                        </div>
                                       
                                        </TabPane>
                                        <TabPane tab="Great First Listens" key="3">
                                        <Link to="/login">
                                        <div className="resp-tabs-container">
                                        <div>
                                            <div className="row">     
                                               
                                            </div>
                                        </div>

                                        </div>
                                        </Link>
                                        </TabPane>

                                    </Tabs>
                                    
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <section className="audiobook">
                <div className=" container">
                    <div className="row">
                        
                            <div className="col-sm-12 "> <h1>What Is Voca Books</h1></div>
                        
                            <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                                <div className="audiobook_sec">
                                    <div className="audio_icon">
                                      
                                    </div>
                                    <h2>Listen anywhere, anytime</h2>
                                    <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing! </p>
                                </div>
                            </div>
                        
                            <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                                <div className="audiobook_sec">
                                    <div className="audio_icon">
                                    
                                      </div>
                                    <h2>Listen anywhere, anytime</h2>
                                    <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing! </p>
                                </div>
                            </div>
                       
                            <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                                <div className="audiobook_sec">
                                    <div className="audio_icon">
                                    
                                      </div>
                                    <h2>Listen anywhere, anytime</h2>
                                    <p>Listen to your favourite books wherever you are with the free Audible app - in a car, on a run or even when ironing! </p>
                                </div>
                            </div>
                      
                        <div className="clearfix" />
                        <div className="freetrial"><a href="#">
                          
                           &nbsp; Free Trial</a></div>
                    </div>
                </div>
            </section>


            <section className="add_bg">
                <div className=" container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="add_img ">
                         
                              </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="add_content">Try an Voca Books, on us</div>
                            <div className="add_content1">Try an Voca Books, on us</div>
                            <div className="add_content2">Discover Collection &nbsp;
                           
                             </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="member_fee"> 
        <div className=" container"> 
          <div className="row">
            <div className="partner-table"> 
            
              <div className="col-sm-6 partner wow fadeInRight" data-wow-delay=".4s">
                <div className="sections1_b">
                  <h1>What do you get as a member?</h1>
                  <ul>
                    <li>1 credit a month
                      <span>Use on any Voca Books regardless of the price.</span> 
                    </li>
                    <li>You own your Voca Books
                      <span>Use on any Voca Books regardless of the price.</span> 
                    </li>
                    <li>Easy exchanges
                      <span>Use on any Voca Books regardless of the price.</span> 
                    </li>
                    <li>Exclusive member-only savings
                      <span>Use on any Voca Books regardless of the price.</span> 
                    </li>
                  </ul>
                  <div className="freetrial_n"><a href="#">
                   
                     &nbsp; Free Trial</a></div>
                </div>
              </div>
              <div className="col-sm-6 partner wow fadeInLeft" data-wow-delay=".4s">
               
                </div>
           
            </div>  
            <div className=" clearfix" />
          </div>
        </div>
      </section>



      <section className="how_to_start"> 
          <div className=" container"> 
            <h1>  How to start?</h1>
            <div className="row">
              <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                <div>
              
                  </div>   
                <div className="how_to_start_con"><span>1.</span> Sign Up</div>
              </div>
              <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                <div>
                
                  </div>   
                <div className="how_to_start_con"><span>2.</span> Login</div>                       
              </div>  
              <div className="col-sm-4 wow fadeInRight" data-wow-delay=".4s">
                <div>
                 
                  </div>   
                <div className="how_to_start_con"><span>3.</span> Download</div>  
              </div>            
              <div className=" clearfix" />
            </div>
          </div>
        </section>


        <section className="add_banners"> 
          <div className=" container"> 
            <div className="row">
              <div className="col-sm-6 wow fadeInRight" data-wow-delay=".4s">
                <div>
               
                  </div>    
              </div>
              <div className="col-sm-6 wow fadeInRight" data-wow-delay=".4s">
                <div>
                
                  </div>                        
              </div> 
              <div className=" clearfix" />
            </div>
          </div>
        </section>


        <section className="listening"> 
        <div className=" container"> 
          <div className="row">
            <div className="partner-table"> 
              <div className="col-sm-6 partner wow fadeInRight" data-wow-delay=".4s">
                <div className="sections1_b">
                  <h1>Listening is easy</h1>
                  <p>Download the Voca Books app and listen to your books wherever you are.
                    Even if you switch devices, you'll never lose your place. </p>
                  <div className=" text-center">
                    <a href="#">
                    
                      </a> &nbsp; &nbsp; 
                    <a href="#">
                      
                       </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 partner wow fadeInLeft" data-wow-delay=".4s"> </div>
            </div>  
            <div className=" clearfix" />
          </div>
        </div>
      </section>

     </ Route>
     

            

            
<Footer/>  */}


    </>

  )


}

export default FrontPage;