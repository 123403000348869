import React from 'react';
import AdvanceSearchComponent from './AdvanceSearchComponent';
import { useSearchDispatcher, useSearchContext, getSelectBoxdata } from '../action/SearchAction';

interface IAdvanceSearchContainerProps{
    onHandleAuthorChange:(value:any)=>void;
    onHandleNarratorChange:(value:any)=>void;
    onHandlePublisherChange:(value:any)=>void;
    onHandleTranslatorChange:(value:any)=>void;
    OnHandleCategoyChange:(value:any)=>void;
}

interface IAdvanceSearchContainerState{

}

const initialState={

}

const AdvanceSearchContainer:React.FC<IAdvanceSearchContainerProps>=(props)=> {
const [advanceSearchContainerState,
    setAdvanceSearchContainerState]=React.useState<IAdvanceSearchContainerState>(initialState)

const {onHandleTranslatorChange,onHandlePublisherChange,onHandleNarratorChange,onHandleAuthorChange,
    OnHandleCategoyChange}=props

    const searchDispatcher = useSearchDispatcher();
    const searchContext = useSearchContext();
    const { searchResult,searchTerm,selectBoxData } = searchContext;
    // const dataSource = {'Burns Bay Road', 'Downing Street', 'Wall Street'];
    React.useEffect(() => {
        (async () => {
            await getSelectBoxdata(searchDispatcher)
           
        })();
    }, [])


console.log(45,selectBoxData);




    return (
        <>
         <AdvanceSearchComponent 
         {...props}
         categories={selectBoxData.categories}
         authors={selectBoxData.authors}
         narrators={selectBoxData.narrators}
         publishers={selectBoxData.publishers}
         translators={selectBoxData.translators}
         onHandleAuthorChange={onHandleAuthorChange}
         onHandleNarratorChange={onHandleNarratorChange}
         onHandlePublisherChange={onHandlePublisherChange}
         onHandleTranslatorChange={onHandleTranslatorChange}
         OnHandleCategoyChange={OnHandleCategoyChange}
         />  
        </>
    );
};

export default AdvanceSearchContainer;