
import * as React from 'react';
import { Form, Icon, Input, Checkbox, Row, Col, Button, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
// import '../myStyle/Login.css';
import { Spinner } from 'react-bootstrap';
import { Link, Route } from 'react-router-dom';
import { url } from 'inspector';
import { NavigateToItem } from 'typescript';
import { useHistory } from 'react-router';

export interface IForgotPasswordFormProps extends FormComponentProps {
  onHandleUserChange: (Value: any) => void;
  onHandlePasswordChange: (value: any) => void;
  onHandleConfirmPasswordNameChange : (value:any)=> void;
  onHandleSubmit: () => void;
  isBusy: boolean;
  loginMsg: string;
}


const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = (props) => {

  const { getFieldDecorator,getFieldValue } = props.form;
  const {onHandleConfirmPasswordNameChange, onHandleUserChange, onHandlePasswordChange, onHandleSubmit, isBusy, loginMsg } = props;

  const handleChangeUser = (e: any) => {
    console.log("priti1"+e.target.value)
    onHandleUserChange(e.target.value);
  }
  const handleConfirmPasswordChange = (e: any) => {
    console.log("priti3"+e.target.value)
    onHandleConfirmPasswordNameChange(e.target.value);
  }
  const handleChangePassword = (e: any) => {
    console.log("priti2"+e.target.value)
    onHandlePasswordChange(e.target.value);
  }
  const history = useHistory();

  
  const handleSubmit = (e: any) => {
    console.log("handle clicked first",e.target.value)
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      
      if (!err) {
       
    onHandleSubmit(  
   );
   
      }})
   
    //console.log("submit clicked")
  
  }
  console.log(isBusy)

 
  var imgUrl = require('../images/login-bg.jpg')






  return (
    <>
         <div className="tg-sectionspace tg-haslayout">
    <div className="container">
     <div className="row">
       <div className="tg-contactus">
         <div className="login-page">
            <div className="form">
             <div className="title">Forgot Password</div><br/>




             
            <Form className="login-form" onSubmit={handleSubmit}>

<Form.Item key="1" >
{
getFieldDecorator('Title', {

 rules: [
   { required:true, message: "please Enter Email!" },
   { type:'email', message: "please Enter a Valid Email" },
],
})(<Input placeholder="Email"
 name="email"
 onChange={handleChangeUser}
/>)
}
</Form.Item>
<Form.Item key="2" >
{
getFieldDecorator('password', {

 rules: [
   {
 required:true,
 message: "please Enter Password!"
   }
]
})(< Input.Password placeholder="Password"
 name="password"
 onChange={handleChangePassword}
/>
)
}
</Form.Item>
<Form.Item key="3" >
                      {
                        getFieldDecorator('Confirmpassword', {

                          rules: [{
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          {

                            validator: (rule: any, value: any) => {

                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('The  passwords that you entered do not match!');
                              }
                            },

                          },

                          ],

                        })

                          (< Input.Password placeholder="Confirm Password"
                            name="confirmpassword"
                            onChange={handleConfirmPasswordChange}
                          />
                          )
                      }
                    </Form.Item>
                    <button type="submit"> {isBusy ?
  (<Spinner animation="border" variant="warning" />) : 'Reset'}</button>
</Form>

              <Link to='/login' style={{color:'green',fontSize:'small'}} >Back to Login?</Link>
            </div>
          </div>
       </div>
     </div>
   </div>
  </div>
   </>
);


//   return (
//     <>
//     <div className="tg-sectionspace tg-haslayout" style={{ backgroundImage: "url(" + imgUrl + ")" }}>
//         <div className="container">
//           <div className="row">
//             <div className="tg-contactus">
//               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                 <div className="login-page" style={{margin: '10% auto'}}>
//                   <div className="form">
//                     <label className="title">Forgot Password</label>
//                     <Form className="login-form" onSubmit={handleSubmit}>

// <Form.Item key="1" >
//   {
//     getFieldDecorator('Title', {

//       rules: [
//         { required:true, message: "please Enter Email!" },
//         { type:'email', message: "please Enter a Valid Email" },
//   ],
//     })(<Input placeholder="Email"
//       name="email"
//       onChange={handleChangeUser}
//     />)
//   }
// </Form.Item>
// <Form.Item key="1" >
//   {
//     getFieldDecorator('password', {

//       rules: [
//         {
//       required:true,
//       message: "please Enter Password!"
//         }
//     ]
//     })(< Input.Password placeholder="Password"
//       name="password"
//       onChange={handleChangePassword}
//     />
//     )
//   }
// </Form.Item>




// <button type="submit"> {isBusy ?
//   (<Spinner animation="border" variant="warning" />) : 'Login'}</button>
// <p className="message">Not registered? <Link to="/register">
//   <a href="#">Create an account</a></Link>

// </p>


// </Form>
// <Checkbox style={{color:'white'}} defaultChecked={true} >Remember me</Checkbox>
// <Link to='/forgot_password' style={{color:'green',fontSize:'small'}} >ForgotPassword?</Link>
// <h6 style={{ color: 'red' }}>{loginMsg}</h6>
                   
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

      
//     </>
//   )
}
//  export default LoginForm
export const ForgotPasswordEditForm = Form.create<IForgotPasswordFormProps>()(ForgotPasswordForm);

  


