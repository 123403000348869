import React from 'react';
import { ProductSearchResult } from '../../api';
import { Link } from 'react-router-dom';
import { Popconfirm, Button, Tooltip } from 'antd';

interface ISmallCardWishListProps{
    wishListResult:ProductSearchResult
    onhandleWishListDeleteClick:(id:any)=>void;
}

interface ISmallCardWishListState{

}

const SmallCardWishList:React.FC<ISmallCardWishListProps>=(props) => {
    const {wishListResult,onhandleWishListDeleteClick}=props;

    if (!wishListResult || !wishListResult.searchResult || !wishListResult.searchResult.data || wishListResult.searchResult.data.length <= 0) {
        return (
          <>
    
          <div className='tg-minicartbody'>
            <p>
            Wish List is empty
            </p>
            
          </div>
    
          </>
        );
      }

      const onDeleteButtonClick=(id:any)=>{
        onhandleWishListDeleteClick(id);
        console.log(4424)
      }


    return (
        <>
        <div className="tg-minicartbody">
            {wishListResult.searchResult.data.map((wishList, index) => {
                                return (
                                  <li style={{ listStyle: 'none' }}>
                                    <div className="tg-minicarproduct">
                                      <figure>
                                        <img style={{ width: 70, height: 70 }} src={wishList && wishList.bookImages != undefined ? wishList.bookImages[0].filename : ""} alt="image description" />
                                      </figure>
                                      <div className="tg-minicarproductdata">
                                        <h5><span >{wishList.title}</span></h5>
                                        <h6><span >{wishList.currencySymbol} {wishList.realPrice}</span></h6>
                                        {/* <Popconfirm
            title="Are you sure you want to delete?" */}
            {/* onConfirm={onDeleteButtonClick} */}
            {/* > */}
            <Tooltip title="Delete">
                <Button
                    shape="circle"
                     style={{ float: 'right',marginTop:-30 }}
                    icon="delete"
                    size="small"
                    onClick={()=>onDeleteButtonClick(wishList.audioId)}
                />
            </Tooltip>
        {/* </Popconfirm> */}
                                      </div>
                                      
                                    </div>

                                  </li>)
                              })} 

<div className="tg-minicartfoot">
                                
                                
                                <div className="tg-btns">
                                <Link to='/wishList'>    <span style={{marginLeft:5}} className="tg-btn tg-active" >View WishList</span></Link>
                                 
                                </div>
                              </div>
                              </div>
        </>
    );
};

export default SmallCardWishList;