import axios from 'axios';
import * as qs from 'querystring';
import ConfigurationService from './ConfigurationService';

class EndpointFactory {
    private readonly _loginUrl: string = '/connect/token';

    private get loginUrl() { return ConfigurationService.tokenUrl + this._loginUrl; }

    public async getLoginEndpoint<T>(userName: string, password: string) {
        const header = {
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'access-control-allow-origin': 'https://ai.borncodetechnologies.com/api/connect/token',
            crossdomain: true,
            'Access-Control-Allow-Origin': '*',
        };
        const params = {
            'username': userName,
            'password': password,
            'grant_type': 'password',
            'scope':' openid email phone profile offline_access roles',
            'resource': window.location.origin,
            'login_hint': 'Administrator',
            // 'client_secret': 'AudioBook@2019',
            // 'client_id': 'audio_book_spa'
        };
        return axios.post<T>(this.loginUrl, qs.stringify(params), { headers: header });
    }

    public async loadData<T>(url: string, data: string) {
        const header = {
            'Content-Type': "application/json",
            'Authorization':"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlNjI0NWZlYi1mNzNlLTRiODMtYmQ4NC1mZDExOTE2NmM5OTciLCJuYW1lIjoibmlzaGlsYTIiLCJyb2xlIjoiU2l0ZUFkbWluaXN0cmF0b3IiLCJwZXJtaXNzaW9uIjpbInVzZXJzLnZpZXciLCJ1c2Vycy5tYW5hZ2UiLCJyb2xlcy52aWV3Iiwicm9sZXMubWFuYWdlIiwicm9sZXMuYXNzaWduIiwibWVtYmVycy52aWV3IiwibWVtYmVycy5tYW5hZ2UiLCJ1c2VyLnZpZXciLCJtZW1iZXIudmlldyIsImFsbC52aWV3Il0sInRva2VuX3VzYWdlIjoiYWNjZXNzX3Rva2VuIiwianRpIjoiMTNkNTAyNzEtNjY2NC00OTg3LWFjNjktNmYyYWNhNTAxZmE4Iiwic2NvcGUiOlsib3BlbmlkIiwiZW1haWwiLCJwaG9uZSIsInByb2ZpbGUiLCJvZmZsaW5lX2FjY2VzcyIsInJvbGVzIl0sIm5iZiI6MTU2NzU4NjcwNCwiZXhwIjoxNTk5MTIyNzA0LCJpYXQiOjE1Njc1ODY3MDQsImlzcyI6Imh0dHA6Ly9haS5ib3JuY29kZXRlY2hub2xvZ2llcy5jb20vIn0.bxLAso6RwqkRTVGVB90w2ly_JMMW1ybZDuuX5o8Gikc"
        };
        const response = await axios.post(url, data, { headers: header });
        return response.data.data as T;
    }
}

export default new EndpointFactory();