import React from 'react';
import AccountDetailsComponent from './AccountDetailsComponent';
import { AppUrls } from '../_environments/environment';
import axios from 'axios';
import AuthService from '../_services/AuthService';
import { message, Button, Icon } from 'antd';
import AWS from "aws-sdk";
import Upload,{ UploadProps } from 'antd/lib/upload';
import { useUserDispatcher, useUserContext, getUserListResult } from '../action/UserAction';
import { withRouter, RouteComponentProps } from 'react-router';
import { saveProfileImage } from '../api/controller';
const key = 'updatable';
interface IAccountDetailsContainerProps extends  RouteComponentProps<any>  {
    handleUpdated:()=>void
}
 let file1:File ;
interface IAccountDetailsContainerState {
    FirstName:string;
    MiddleName:string;
    LastName:string;
    ProfileImageURL:string;
    PhoneNumber:string,
    imgKey:string,
    bucket: string,
    progress:string,
    displayValue:number
    visibleData:boolean,
    cnfrmPwd:string,
    currentPswd:string,
    newPwsd:string,
    cnfrmPwdValidate:boolean,
    cnfmPwdClrChecker:boolean,
    LastLoginTime:string,
    isPswdSubmit:boolean,
    filename:string,
    isUploadpic:boolean,
   file:File,
}

const initialState = {
    FirstName:"",
    MiddleName:"",
    LastName:'',
    ProfileImageURL:'',
    PhoneNumber:'',
    imgKey: "",
    bucket: "",
    progress: "",
    displayValue: 0,
    visibleData:false,
    cnfrmPwd:"",
    currentPswd:"",
    newPwsd:"",
    cnfmPwdClrChecker:false,
    filename:"",
    isUploadpic :false,
    LastLoginTime:"",
cnfrmPwdValidate:false,
isPswdSubmit:false,

} as IAccountDetailsContainerState

const AccountDetailsContainer:React.FC<IAccountDetailsContainerProps>= (props) => {
const {handleUpdated}=props
    const [accountDetailsContainerState,
        setAccountDetailsContainerState] = React.useState<IAccountDetailsContainerState>(initialState);

const {FirstName,LastName,MiddleName,PhoneNumber,visibleData,ProfileImageURL,cnfmPwdClrChecker,isPswdSubmit,isUploadpic,
    bucket,displayValue,imgKey,progress,cnfrmPwd,cnfrmPwdValidate,currentPswd,newPwsd,file}=accountDetailsContainerState

        let token = AuthService.accessToken;
    let authorizationToken = token != null ? token.replace(/['"]+/g, '') : "";


    const userDispatcher = useUserDispatcher();
    const userContext = useUserContext();
    const { users } = userContext



    
    React.useEffect(() => {
        (async () => {
            await getUserListResult(userDispatcher)
           
        })();
    }, [authorizationToken,visibleData])

     
    React.useEffect(() => {
      
        (async () => {
           
            setAccountDetailsContainerState({
                ...accountDetailsContainerState,
                FirstName:users.firstName!=undefined?users.firstName:"",
                LastName:users.lastName!=undefined?users.lastName:"",
                PhoneNumber:users.phoneNumber!=undefined?users.phoneNumber:"",
                
                ProfileImageURL:users.profileImageURL!=undefined?users.profileImageURL:"",
         
            }); 
           
            console.log(100002,users.profileImageURL) })();
    }, [users])

        React.useEffect(() => {
            if (visibleData) {
                (async () => {
    
                    let headers = {
                        Authorization: `Bearer ${authorizationToken}`,
                        "Content-Type": "application/json",
                    };
    
                    let formData = new FormData();
    
                    const url = AppUrls.SetUserProfiles
    
                    axios.post(url, JSON.stringify({FirstName:FirstName,
                    MiddleName:"",
                    LastName:LastName,
                    ProfileImageURL:ProfileImageURL,
                    PhoneNumber:PhoneNumber }), { headers: headers }).then(res => {
                        
                        if (res) {
                          // alert()
                            // message.loading({ content: 'Loading...', key });
                            setTimeout(() => {
                                message.success({ content: res.data.message, key, duration: 2 });
                            }, 1000);

     setAccountDetailsContainerState({
            ...accountDetailsContainerState,
            visibleData:false
        });
        handleUpdated();
                        }
                    })
                })();
            }
        }, [visibleData])




        React.useEffect(() => {
            if (isPswdSubmit) {
                (async () => {
    
                    let headers = {
                        Authorization: `Bearer ${authorizationToken}`,
                        "Content-Type": "application/json",
                    };
    
                    let formData = new FormData();
    
                    const url = AppUrls.ChangePassword
    
                    axios.post(url, JSON.stringify({
                        CurrentPassword:currentPswd,
                        NewPassword:newPwsd
                 }), { headers: headers }).then(res => {
                        
                        if (res.data.isSuccess) {
                            setTimeout(() => {
                                message.success({ content: res.data.message, key, duration: 2 });
                            }, 1000);

          
     setAccountDetailsContainerState({
            ...accountDetailsContainerState,
            isPswdSubmit:false,
           
           
        });
       
        props.history.push('/home');
                    
     }

     else
     {
        setTimeout(() => {
            message.error({ content: res.data.message, key, duration: 2 });
        }, 1000);


setAccountDetailsContainerState({
...accountDetailsContainerState,
isPswdSubmit:false,


});
     }
                    })
                }
                
                
                
                
                
                
                )();
            }
        }, [isPswdSubmit])


        React.useEffect(() => {
            console.log(87878787,file1)
            if (isUploadpic) {
                console.log(66666,file1);
                (async () => {
    
                    let headers = {
                        Authorization: `Bearer ${authorizationToken}`,
                        "Content-Type": "multipart/form-data",
                    };


    
                    let formData = new FormData();
                    formData.append("profileImageURL",file1.name)
                    formData.append("profileImage",file1)
                    const url = AppUrls.UploadProfileImage
                   
                    axios.post(url, 
                        formData
                        
                        
                        , { headers: headers }).then(res => {
                            console.log(66336,res);
                        if (res) {
                            
                            setAccountDetailsContainerState({
                                ...accountDetailsContainerState,
                                isUploadpic:false
                            }); 
                            window.location.reload();
                
       
        //    props.history.push('/home');            
     }
                    })
                })();
            }
        }, [isUploadpic])


const onHandleChangeFirstName=(value:any)=>{
    setAccountDetailsContainerState({
            ...accountDetailsContainerState,
            FirstName:value
        });
}

const onHandleChangeMiddleName=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        
    });
}




const onHandleChangeLastName=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        LastName:value
    });
}

const onHandleChangePhoneNumber=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        PhoneNumber:value
    });
}

const onHandleChangeProfileImage=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        ProfileImageURL:value,
        
    });
}

const onHandleSaveButtonClick=()=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        visibleData:true
    }); 
}

const onHandlePhotoUpload = (e:any)=>
{

   file1 = e.target.files[0];
    console.log(5555555,e.target.files[0])
   
        console.log(7777777777,       file1
            )


            setAccountDetailsContainerState({
                ...accountDetailsContainerState,
                isUploadpic:true
            }); 




}
// const onHandlePhotoUpload=(profilepic:any)=>{
  
// const formData=new FormData();
// console.log(443,profilepic[0]);
// console.log(444,profilepic[0].name);
// formData.append("profileImage",profilepic[0]);
// formData.append("fileName",profilepic[0].name);
// formData.append("email",JSON.stringify(users.email));
// for (var key of (formData as any).entries()) {
//     console.log(788,key[0] + ", " + key[1]);
//   }

//   setAccountDetailsContainerState({
//     ...accountDetailsContainerState,
//     isUploadpic:true
// }); 

// // saveProfileImage(formData).then((res: any) => {
// //   console.log(res);

// //   if (res.data.isSuccess) {
  
    
// //   console.log(100,"success")
// //     // props.setIsOpen3(false);

    
// //   } 
// // });

// }

const onHandleCurrentPasswordChange=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        currentPswd:value,
    });
}

const onHandleNewPasswordChange=(value:any)=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        newPwsd:value
    });
}

const onHandleConfirmPasswordChange=(value:any)=>{
     

    if(newPwsd!==value){
        setAccountDetailsContainerState({
            ...accountDetailsContainerState,
            cnfrmPwdValidate:true,
            cnfmPwdClrChecker:true,
            cnfrmPwd:value,
        });   
    }
    else{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        cnfrmPwd:value,
        cnfrmPwdValidate:false,
        cnfmPwdClrChecker:true
    });
}
}

const onHandlePasswordSave=()=>{
    setAccountDetailsContainerState({
        ...accountDetailsContainerState,
        isPswdSubmit:true
    });
} 




    return (
        <>
        {/*                <h4>Upload Book Images</h4>
                  <div>
                        <Upload {...uploadProps}>
                             <Button>
                                 <Icon type="upload" /> Click to Upload
                      </Button>
                         </Upload>
                         <br />
                     </div>
                   <h1 style={{ color: "green" }}>{progress}</h1> */}
                    
            <AccountDetailsComponent 
            onHandleChangeFirstName={onHandleChangeFirstName}
            onHandleChangeMiddleName={onHandleChangeMiddleName}
            onHandleChangeLastName={onHandleChangeLastName}
            onHandleChangeProfileImage  ={onHandleChangeProfileImage}
            onHandleChangePhoneNumber ={onHandleChangePhoneNumber} 
            onHandleCurrentPasswordChange={onHandleCurrentPasswordChange}
            onHandlePhotoUpload={onHandlePhotoUpload}
            onHandleNewPasswordChange={onHandleNewPasswordChange}
            onHandleConfirmPasswordChange={onHandleConfirmPasswordChange}
            onHandlePasswordSave={onHandlePasswordSave}
            users={users}
            onHandleSaveButtonClick={onHandleSaveButtonClick}
            visibleData={visibleData}
            cnfrmPwdValidate={cnfrmPwdValidate}
            cnfmPwdClrChecker={cnfmPwdClrChecker}
        
                 />

        </>
    );
};

export default withRouter(AccountDetailsContainer);

function apiClient(arg0: string, data: any, arg2: string, arg3: { headers: { "Content-Type": string; }; }) {
    throw new Error('Function not implemented.');
}
