import { Reducer } from "react"
import { ILanguageManagementState, LanguageDispatcherContext, LanguageStateContext } from "../context/LanguageContext"
import React from "react";
import { ProductAPIClient, LanguageClient, LanguageListVM } from "../api";

console.log("Inside LanguageAction");

export type ILanguageAction = {
    type: "SET_VALUE"
} | {
    type: "SET_VALUETwo"
}|{
    type:"SET_HOME_PRODUCT_LIST",
    languages:LanguageListVM 
}




export const languageReducer: Reducer<ILanguageManagementState, ILanguageAction> = (draft, action): ILanguageManagementState => {
    switch (action.type) {
        case "SET_VALUE":
            draft.visible = true;
            draft.value = 2;
            return draft;
        case "SET_VALUETwo":
            draft.visible = false;
            draft.value = 3;
            return draft;
            case "SET_HOME_PRODUCT_LIST":
                draft.languages=action.languages;
                return draft;
    }
}



export const visibleDataOne = async (dispatcher: React.Dispatch<ILanguageAction>, query: boolean) => {
    dispatcher({ type: "SET_VALUE" });
}

export const visibleDataTwo = async (dispatcher: React.Dispatch<ILanguageAction>) => {

    dispatcher({ type: "SET_VALUETwo" });
    console.log(23, 45, "worked")
}



export const useLanguageDispatcher = (): React.Dispatch<ILanguageAction> => {
    const languageDispatcher = React.useContext(LanguageDispatcherContext);
    if (!languageDispatcher) {
        throw new Error("You have to provide the Language dispatcher using theLanguageDispatcherContext.Provider in a parent component.");
    }
    return languageDispatcher;
}


export const useLanguageContext = (): ILanguageManagementState => {
    const languageContext = React.useContext(LanguageStateContext);
    if (!languageContext) throw new Error("You have to provide the language context using the LanguageStateContext.Provider in a parent component.");
    return languageContext;
}



const languageApi = new LanguageClient();

export const getLanguageList = async (dispatcher: React.Dispatch<ILanguageAction> ) => {
    // dispatcher({ type: "SET_HOME_PRODUCT_LIST" });
    try{

        const languages = await languageApi.getActiveLanguages( )
       dispatcher({ type: "SET_HOME_PRODUCT_LIST", languages: languages });

    }
    catch(e){

    }
}



