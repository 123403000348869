export const environment = {
  production: false,
  // baseUrl: "http://audiobookapi.clubactive.in/",
  // tokenUrl: "http://audiobookapi.clubactive.in/api",

 baseUrl: "https://api.vocabooks.com",
tokenUrl: "https://api.vocabooks.com/api",
//baseUrl: "http://localhost:8001",
//Change this to the address of your backend API if different from frontend address
//tokenUrl: "http://localhost:8001/api",
   
 
  // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
  loginUrl: '/login'
};

export const AppUrls = {
  NarratorGetAllUrl: "/api/narrator/gaaa",
  LanguageGetAllUrl: environment.baseUrl + "/api/language/gaaa",
  LanguageCreateOrUpdateUrl: environment.baseUrl + "/api/language/coea",
  LanguageDeleteUrl: environment.baseUrl + "/api/language/da",
  LanguageGetUrl: environment.baseUrl + "/api/language/ga",
  AuthorGetAllUrl: environment.baseUrl + "/api/author/gaaa",
  AuthorCreateOrUpdateUrl: environment.baseUrl + "/api/author/coea",
  AuthorDeleteUrl: environment.baseUrl + "/api/author/da",
  AuthorGetUrl: environment.baseUrl + "/api/author/ga",
  AddToCart:environment.baseUrl+"/api/cart/add-to-cart",
  GetCartItems:environment.baseUrl+"/api/Cart/get-cart-items",
  GetWishListItems:environment.baseUrl+"api/productapi/get-wish-list",
  AddToWishList:environment.baseUrl+"/api/productwishlist/coea",
  AddReviews:environment.baseUrl+"/api/productreview/coea",
  RateReview:environment.baseUrl+"/api/productapi/rate-audio",
  GetUserDetails:environment.baseUrl+"/api/account/get-basic-details",
  UploadProfileImage:environment.baseUrl+"/api/account/uploadProfileImage",
  UpdateLastLoginTime:environment.baseUrl+"/api/account/updateLastLoginTime",
  RegisterUrl:environment.baseUrl+"/api/account/register-me",
  GetMostRateUrl:environment.baseUrl+"/api/productserviceapi/get-most-rated",
  GetFrontPageproductDetails:environment.baseUrl+"/api/productserviceapi/get-front-product-detail",
  GetNewReleasedAudios:environment.baseUrl+"/api/productserviceapi/get-new-released",
  SetUserProfiles:environment.baseUrl+'/api/account/profile-edit',
  ChangePassword:environment.baseUrl+"/api/account/change-password",
  ResetPassword:environment.baseUrl+"/api/account/reset-password",
  PackageGetAll:environment.baseUrl+"/api/group-package/gaaa",
  PurchaseGetAll:environment.baseUrl+"/api/Purchase/purchased-histories",
  PurchasreSave:environment.baseUrl+"/api/Purchase/coea"
}

